import { useState } from "react";
import { IonAlert, IonButton, IonCard, IonCardContent, IonCardTitle, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, IonSelectOption, IonText, useIonViewDidEnter } from "@ionic/react";
import { refresh } from "ionicons/icons";

import api from "../../../services/api"

import { useLoading } from "../../../hooks/loading"
import { useToast } from "../../../hooks/toast"
import { useValidation } from "../../../hooks/validation"

import Main from "../../../components/Main";
import Card from "../../../components/Card";
import { IonSelect } from "../../../components/Select/styles";

const sessionName = process.env.REACT_APP_SESSION_NAME

interface Carro {
    id: number
    placa: string
}

interface ProdutoMovimento {
    id: number;
    lote: string;
    carro: string;
    destino: string;
    status: "AP" | "RE";
    retirado_pelo_usuario: "S" | "N";
}

const MotoristaProdutoMovimento = () => {
    const { showLoading, hideLoading, showSaveMessage, hideSaveMessage } =
        useLoading();
    const { successToast } = useToast();
    const { getValidationErrors } = useValidation();

    const [carros, setCarros] = useState<Carro[]>([])
    const [placaDoCarro, setPlacaDoCarro] = useState(0)

    const [produtosMovimentos, setProdutosMovimentos] = useState<ProdutoMovimento[]>([]);

    const [idCancelar, setIdCancelar] = useState(0);
    const [showCancelar, setShowCancelar] = useState(false);

    const getCarros = async () => {
        await api.get("motorista/carro").then(response => {
            const { data } = response;
            if (data)
                setCarros(data);

            changePlacaDoCarro(
                parseInt(localStorage.getItem(`@${sessionName}:carroPlaca`) || "0")
            );
        })
    }

    const changePlacaDoCarro = (newPlacaDoCarro: number) => {
        localStorage.setItem(`@${sessionName}:carroPlaca`, String(newPlacaDoCarro));
        setPlacaDoCarro(newPlacaDoCarro);
        getData({ id_carro: newPlacaDoCarro });
    }

    const getData = async (params = {}) => {
        showLoading();
        await api.get("motorista/produto", { params }).then(response => {
            const { data } = response;
            if (!!data)
                setProdutosMovimentos(data)
        })
        hideLoading();
    }

    useIonViewDidEnter(async () => {
        await getCarros();
    }, [])

    const RefreshIcon = () => (
        <IonItem lines="none">
            <IonIcon
                icon={refresh}
                size="large"
                color="light"
                onClick={async () => {
                    await getCarros();
                }}
            />
        </IonItem>
    )

    const confirmar = async (id: number) => {
        showSaveMessage();
        await api
            .put(`motorista/produto/${id}`)
            .then(() => {
                successToast()
                getData();
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
        hideSaveMessage();
    }

    const cancelar = async () => {
        showSaveMessage();
        await api
            .delete(`motorista/produto/${idCancelar}`)
            .then(() => {
                successToast()
                getData();
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
        hideSaveMessage();
    }

    return <Main title="Produtos" end={<RefreshIcon />}>
        <IonGrid>
            <IonRow>
                <IonCol size="12">
                    <Card>
                        <IonCardContent>
                            <IonLabel position="stacked">Placa do Carro</IonLabel>
                            <IonSelect
                                value={placaDoCarro}
                                onIonChange={event => changePlacaDoCarro(parseInt(event.detail.value))}
                                cancelText="Cancelar"
                                okText="Ok"
                                interface="action-sheet"
                            >
                                <IonSelectOption value={0}>TODOS</IonSelectOption>
                                {carros.map(carro => (
                                    <IonSelectOption key={carro.id} value={carro.id}>
                                        {carro.placa}
                                    </IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonCardContent>
                    </Card>
                </IonCol>
            </IonRow>
            <IonRow>
                {produtosMovimentos.map(produtoMovimento => (
                    <IonCol size="12" key={produtoMovimento.id}>
                        <IonCard>
                            <IonCardContent>
                                <IonCardTitle>LOTE: {produtoMovimento.lote}</IonCardTitle>
                                <br />
                                <IonLabel>CARRO: </IonLabel>
                                <IonText>{produtoMovimento.carro}</IonText>
                                <br />
                                <IonLabel>DESTINO: </IonLabel>
                                <IonText>{produtoMovimento.destino}</IonText>
                                <IonRow className="ion-margin-top">
                                    <IonCol size="12">
                                        <IonButton
                                            type="button"
                                            routerLink={`/motorista/produto/${produtoMovimento.id}/${produtoMovimento.lote}`}
                                            color="secondary"
                                            expand="block"
                                        >
                                            Produtos
                                        </IonButton>
                                    </IonCol>
                                    {produtoMovimento.status === "RE" &&
                                        produtoMovimento.retirado_pelo_usuario === "S" && (
                                            <IonCol size="12">
                                                <IonButton
                                                    type="button"
                                                    onClick={() => {
                                                        setIdCancelar(produtoMovimento.id);
                                                        setShowCancelar(true);
                                                    }}
                                                    color="danger"
                                                    expand="block"
                                                >
                                                    Cancelar Retirada
                                                </IonButton>
                                            </IonCol>
                                        )}
                                    {(produtoMovimento.status === "AP" || (produtoMovimento.status === "RE" &&
                                        produtoMovimento.retirado_pelo_usuario === "S")) && (
                                            <IonCol size="12">
                                                <IonButton
                                                    type="button"
                                                    onClick={() => confirmar(produtoMovimento.id)}
                                                    color={produtoMovimento.status === "AP" ? "medium" : "dark"}
                                                    expand="block"
                                                >
                                                    {produtoMovimento.status === "AP" ? "Confirmar Retirada" : "Confirmar Entrega"}
                                                </IonButton>
                                            </IonCol>
                                        )}
                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                ))}
            </IonRow>
        </IonGrid>
        <IonAlert
            isOpen={showCancelar}
            onDidDismiss={() => {
                setIdCancelar(0)
                setShowCancelar(false)
            }}
            message="<p><strong>Deseja realmente cancelar a retirada desse lote?</strong></p>"
            buttons={[
                {
                    text: "Não",
                    role: "cancel"
                },
                {
                    text: "Sim",
                    handler: () => cancelar()
                }
            ]}
        />
    </Main >
}

export default MotoristaProdutoMovimento;
