import { useRef } from "react";
import {
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton
} from "@ionic/react";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import api from "../../../services/api";

import { useLoading } from "../../../hooks/loading";
import { useToast } from "../../../hooks/toast";
import { useValidation } from "../../../hooks/validation";

import Main from "../../../components/Main";
import Card from "../../../components/Card";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import ReactSelect from "../../../components/ReactSelect";

interface FormValues {
    data: string
    id_hospital: number
    proc: "Cateter" | "Cateter Tenckhoff"
    id_paciente: number
}

const MedicoCateterAvulso = () => {
    const { showSaveMessage, hideSaveMessage } = useLoading()
    const { successToast } = useToast();
    const { getValidationErrors } = useValidation();

    const formRef = useRef<FormHandles>(null);

    const handleSubmit: SubmitHandler = async (values: FormValues) => {
        showSaveMessage()
        await api
            .post("medico/cateter-avulso", values)
            .then(response => {
                if (!!response.data) {
                    successToast();
                    formRef?.current?.reset();
                }
            })
            .catch(error => {
                getValidationErrors(error.response);
            })
        hideSaveMessage()
    }

    return (
        <Main title="Cateter (Avulso)">
            <Card>
                <IonCardContent>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <IonGrid>
                            <IonRow className="ion-align-items-end">
                                <Input
                                    label="Data"
                                    name="data"
                                    type="date"
                                    required
                                />
                                <ReactSelect
                                    label="Hospital"
                                    name="id_hospital"
                                    url="/medico/select/hospital"
                                    params={{ status: "A" }}
                                    required
                                    simple
                                />
                                <Select
                                    label="Procedimento"
                                    name="proc"
                                    values={[
                                        { value: "Cateter", label: "CATETER NORMAL" },
                                        { value: "Cateter Tenckhoff", label: "CATETER TENCKHOFF" }
                                    ]}
                                    required
                                />
                                <ReactSelect
                                    label="Paciente"
                                    name="id_paciente"
                                    url="/medico/select/paciente"
                                    params={{
                                        obito: "N",
                                        data_nascimento: "S",
                                        matricula: "S"
                                    }}
                                    required
                                    simple
                                />
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="6">
                                    <IonButton type="submit" color="primary" expand="block">
                                        Salvar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </Form>
                </IonCardContent>
            </Card>
        </Main>
    )
}

export default MedicoCateterAvulso;