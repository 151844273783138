import styled from "styled-components";
import { IonTextarea } from '@ionic/react'

interface Props {
    readonly?: boolean
}

export default styled(IonTextarea)`
    background-color:  ${(props: Props) => props.readonly ? "hsl(0, 0%, 95%) !important" : "#fff"};
    background-image: none;
    border: ${(props: Props) => props.readonly ? "2px solid rgba(176, 176, 176, .5)" : "2px solid #b0b0b0"};
    border-radius: 4px;
    margin-top: 5px;
    min-height: 42px;
    padding: 0px 6px !important;
    color: #000;
    opacity: 1;

    .native-textarea sc-ion-textarea-md {
        background-color:  ${(props: Props) => props.readonly ? "hsl(0, 0%, 95%) !important" : "#fff"};
        opacity: 1;
    }
`;
