import styled from "styled-components";
import { IonSelect as Select, IonSelectOption as Option } from "@ionic/react";

interface Props {
  disabled?: boolean
}

export const IonSelect = styled(Select)`
  background-color: ${(props: Props) => props.disabled ? "hsl(0, 0%, 95%) !important" : "#ffffff"};
  background-image: none;
  border: 2px solid #b0b0b0;
  border-radius: 4px;
  margin-top: 5px;
  height: 42px;
  padding: 0px 6px !important;
  color: #000;
  opacity: 1;
`

export const IonSelectOption = styled(Option)`
  color:  ${(props: Props) => props.disabled ? "hsl(0, 0%, 50%) !important" : "transparent"};
  display:  ${(props: Props) => props.disabled ? "none !important" : "block"};
`