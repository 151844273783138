import { useRef, useState } from "react";
import {
    IonButton,
    IonCardContent,
    IonCol,
    IonGrid,
    IonRow
} from "@ionic/react";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { useLoading } from "../../hooks/loading";
import { useToast } from "../../hooks/toast";
import { useValidation } from "../../hooks/validation";

import api from "../../services/api";

import Main from "../../components/Main";
import Card from "../../components/Card";
import Input from "../../components/Input";
import Select from "../../components/Select";
import ReactInputMask from "../../components/ReactInputMask";
import ReactSelect, { Option } from "../../components/ReactSelect";
import Textarea from "../../components/Textarea";
import FilePicker from "../../components/FilePicker";

const initialData = {
    anonimo: "N",
    houve_acao_imediata: "N",
}

interface AorCodesOption extends Option {
    damage_level?: string;
    never_events?: string;
    aor_master_escalation?: string;
    aor_master_category?: string;
    intl_escalation?: string;
}

const Notificacao: React.FC = () => {
    const formRef = useRef<FormHandles>(null)
    const { showLoading, hideLoading, showSaveMessage, hideSaveMessage } = useLoading();
    const { successToast } = useToast()
    const { getValidationErrors } = useValidation()

    const [local, setLocal] = useState("")
    const [houveAcaoImediata, setHouveAcaoImediata] = useState("N")
    const [aorCategory, setAorCategory] = useState("")

    const handleChangeAorCode = (newValue: AorCodesOption) => {
        showLoading();
        if (!!newValue) {
            const {
                damage_level,
                never_events,
                aor_master_escalation,
                aor_master_category,
                intl_escalation,
            } = newValue;

            formRef.current?.setFieldValue("damage_level", damage_level)
            formRef.current?.setFieldValue("never_events", never_events === "Y" ? "SIM" : "NÃO")
            formRef.current?.setFieldValue("aor_master_escalation", aor_master_escalation)
            formRef.current?.setFieldValue("aor_master_category", aor_master_category)
            formRef.current?.setFieldValue("intl_escalation", intl_escalation === "Y" ? "SIM" : "NÃO")
        }
        hideLoading();
    }

    const handleSubmit: SubmitHandler = async (values) => {
        showSaveMessage();

        let formData = new FormData();
        for (const key in values) {
            if (!!values[key]) {
                formData.append(key, values[key])
            }
        }

        await api
            .post("notificar", formData, {
                headers: { "Content-Type": "multipart/form-data" }
            })
            .then(_response => {
                successToast()

                formRef?.current?.reset(initialData)
                setLocal("")
                setHouveAcaoImediata("N")
                setAorCategory("")
            })
            .catch(error => {
                getValidationErrors(error.response)
            })

        hideSaveMessage();
    }

    return (
        <Main title="Notificação">
            <Card>
                <IonCardContent>
                    <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
                        <IonGrid>
                            <IonRow className="ion-align-items-end">
                                <Select
                                    label="Gostaria de se identificar?"
                                    name="anonimo"
                                    size="5"
                                    values={[
                                        { value: "S", label: "SIM" },
                                        { value: "N", label: "NÃO" },
                                    ]}
                                    required
                                />
                                <ReactInputMask
                                    label="Data e Hora da Ocorrência"
                                    name="data"
                                    mask="99/99/9999 99:99"
                                    size="7"
                                    required
                                />
                                <Select
                                    label="Local de Ocorrência"
                                    name="local"
                                    size="12"
                                    onChange={(value: string) => setLocal(value)}
                                    values={[
                                        { value: "HS", label: "HOSPITAL" },
                                        { value: "HC", label: "HOME CARE" },
                                        { value: "RT", label: "TRAJETO" },
                                        { value: "BS", label: "BASE" },
                                        { value: "OT", label: "OUTROS" },
                                    ]}
                                    required
                                />
                                {local === "HS" && (
                                    <ReactSelect
                                        label="Hospital"
                                        name="id_hospital"
                                        url="/select/hospital"
                                        params={{ status: "A" }}
                                        required
                                        simple
                                    />
                                )}
                                <Textarea
                                    label="Descrição da Ocorrência"
                                    name="descricao"
                                    rows={5}
                                    required
                                />
                                <Select
                                    label="Houve Ação Imediata?"
                                    name="houve_acao_imediata"
                                    onChange={(value: string) => setHouveAcaoImediata(value)}
                                    required
                                    values={[
                                        { value: "N", label: "NÃO" },
                                        { value: "S", label: "SIM" },

                                    ]}
                                />
                                {houveAcaoImediata === "S" && (
                                    <Textarea
                                        label="Ação Imediata"
                                        name="acao_imediata"
                                        rows={5}
                                        required={houveAcaoImediata === "S"}
                                    />
                                )}
                                <Select
                                    label="Categoria da Ocorrência"
                                    name="aor_category"
                                    onChange={(value: string) => setAorCategory(value)}
                                    values={[
                                        { value: "A", label: "A - PACIENTE" },
                                        { value: "B", label: "B - COLEGAS / VISITANTES" },
                                        { value: "C", label: "C - OPERAÇÕES / ADMINISTRATIVO" },
                                        { value: "D", label: "D - MÁQUINAS / EQUIPAMENTOS" },
                                    ]}
                                    required
                                />
                                <ReactSelect
                                    label="Natureza da Ocorrência"
                                    url="/select/aor-code"
                                    params={{ category: aorCategory }}
                                    name="aor_code_id"
                                    onChange={(newValue: Option) => handleChangeAorCode(newValue)}
                                    disabled={aorCategory === ""}
                                    required
                                    simple
                                />
                                <Input
                                    size="6"
                                    label="Nível de Dano"
                                    name="damage_level"
                                    type="text"
                                    disabled={true}
                                    required
                                />
                                <Input
                                    size="6"
                                    label="Never Events"
                                    name="never_events"
                                    type="text"
                                    disabled={true}
                                    required
                                />
                                <Input
                                    size="6"
                                    label="Escala de Responsabilidades"
                                    name="aor_master_escalation"
                                    type="text"
                                    disabled={true}
                                    required
                                />
                                <Input
                                    size="6"
                                    label="Escala de Categoria"
                                    name="aor_master_category"
                                    type="text"
                                    disabled={true}
                                    required
                                />
                                <Input
                                    size="6"
                                    label="Escala INTL"
                                    name="intl_escalation"
                                    type="text"
                                    disabled={true}
                                    required
                                />
                                <FilePicker
                                    label="Anexo"
                                    name="anexo"
                                    accept="image/png,image/jpeg,application/pdf"
                                />
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="12">
                                    <IonButton type="submit" color="primary" expand="block">
                                        Salvar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </Form>
                </IonCardContent>
            </Card>
        </Main>
    )
}

export default Notificacao
