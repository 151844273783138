import { useCallback, useEffect, useRef, useState } from "react";
import { IonCol } from "@ionic/react";
import { useField } from "@unform/core";
import { ActionMeta } from "react-select";

import api from "../../services/api"

import Label from "../Label";

import Styled from "./styles";

export interface Option {
    value: string | number
    label: string
    disabled?: boolean
}

interface Props {
    size?: string
    label?: string
    name: string
    values?: Option[]
    url?: string
    params?: any
    onChange?(newValue: Option | Option[], actionMeta: ActionMeta<unknown>): void
    required?: boolean
    disabled?: boolean
    hidden?: boolean
    simple?: boolean
    clearable?: boolean
    multiple?: boolean
}

const ReactSelect = ({
    size,
    label,
    name,
    values,
    url,
    params,
    onChange,
    required,
    disabled,
    hidden,
    simple,
    clearable,
    multiple,
}: Props) => {
    const ref = useRef(null);
    const { fieldName, defaultValue, registerField } = useField(name);
    const [options, setOptions] = useState<Option[]>([]);

    useEffect(() => {
        if (!!values) setOptions(values);
    }, [values]);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: ref.current,
            getValue: (ref) => {
                const values = ref.getValue();
                if (values.length > 0) {
                    if (multiple) {
                        return values.map((value: Option) => {
                            if (simple) {
                                return value.value;
                            } else {
                                return value;
                            }
                        });
                    } else {
                        if (simple) {
                            return values[0].value;
                        } else {
                            return values[0];
                        }
                    }
                }
            },
            setValue: (ref, value) => {
                if (!!value) {
                    if (!!url) {
                        ref.setValue(value);
                    } else {
                        ref.setValue(values?.find(element => element.value === value))
                    }
                }
            },
            clearValue: (ref, value) => {
                if (!!value) {
                    ref.setValue(value);
                } else {
                    ref.clearValue();
                }
            },
        });
    }, [defaultValue, fieldName, multiple, options, registerField, simple, url, values]);

    const onInputChange = useCallback((search: string, params: any) => {
        url && api
            .get(url, {
                params: { ...params, search },
            })
            .then((resp) => {
                setOptions(resp.data);
            });
    }, [url]);

    return (
        <IonCol size={size || "12"} style={!hidden ? {} : { display: "none" }} >
            {!!label && !hidden && <Label required={required}>{label}</Label>}
            <Styled
                ref={ref}
                name={name}
                id={name}
                instanceId={name}
                defaultValue={defaultValue}
                classNamePrefix="react-select"
                placeholder=""
                noOptionsMessage={() => "Nenhuma opção"}
                isOptionDisabled={(option) => {
                    const optionTypeAs = option as Option;
                    return !!optionTypeAs?.disabled;
                }}
                options={options}
                onFocus={() => {
                    !!url && onInputChange("", params);
                }}
                onInputChange={(search) => {
                    !!url && onInputChange(search, params)
                }}
                onChange={(newValue, actionMeta) => {
                    !!onChange && onChange(newValue as Option, actionMeta);
                }}
                isDisabled={disabled}
                isClearable={clearable}
                isMulti={multiple}
                hidden={hidden}
            />
        </IonCol>
    );
};

export default ReactSelect;
