import { useRef, useState } from "react";
import {
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonCardHeader,
    IonCardTitle,
    IonLabel,
    IonText,
    useIonViewDidEnter,
    useIonViewWillLeave,
    IonCard
} from "@ionic/react"
import { caretBack, caretForward, readerOutline } from "ionicons/icons"
import { format, parseISO, subDays, addDays } from "date-fns";

import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { useLoading } from "../../../hooks/loading";
import { useToast } from "../../../hooks/toast";
import { useValidation } from "../../../hooks/validation";
import { useAuth } from "../../../hooks/auth";

import api from "../../../services/api";

import Main from "../../../components/Main";
import Card from "../../../components/Card";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import ReactSelect, { Option } from "../../../components/ReactSelect";

import PassagemPlantao from "./PassagemPlantao";

interface FormValues {
    id_hospital: Option;
    todos_medicos: "N" | "S";
    situacao: string;
    data: string;
}

interface Procedimento {
    id: number;
    proc: string;
    relatorio_medico: "N" | "S";
    data: string;
    hospital: string;
    paciente: string;
    localizacao: string;
    leito: string;
    tecnico: string;
    id_medico: number;
    medico: string;
    situacao: string;
    prescricao: "N" | "S";
    anotacao: "N" | "S";
    evolucao: "N" | "S";
    horas_prescritas: string;
    horas_realizadas: string;
    terminou_antes: string;
    inicio: string;
    fim: string;
    passagem_plantao: string;
    destino: string;
    periodicidade_prevista: string;
}

const sessionName = process.env.REACT_APP_SESSION_NAME;

const initialData = JSON.parse(`${localStorage.getItem(`@${sessionName}:MedicoProcedimentos`)}`)

const MedicoProcedimentos = () => {
    const { showSaveMessage, showLoading, hideSaveMessage, hideLoading } = useLoading();
    const { successToast } = useToast();
    const { getValidationErrors } = useValidation();
    const { usuario } = useAuth();

    const formRef = useRef<FormHandles>(null);
    const [procedimentos, setProcedimentos] = useState<Procedimento[]>([]);

    const handleSubmit: SubmitHandler = async (values: FormValues) => {
        showLoading();

        if (!values?.todos_medicos) {
            values.todos_medicos = "N";
            formRef?.current?.setFieldValue("todos_medicos", "N");
        }

        localStorage.setItem(
            `@${sessionName}:MedicoProcedimentos`,
            JSON.stringify(values)
        )
        await api
            .get("medico/procedimentos", {
                params: {
                    id_hospital: values.id_hospital?.value,
                    todos_medicos: values.todos_medicos,
                    situacao: values.situacao,
                    data: values.data
                }
            })
            .then(response => {
                if (!!response.data) setProcedimentos(response.data);
            })
            .catch(error => {
                getValidationErrors(error.response);
            })
        hideLoading();
    }

    const procedimentoSuspender = async (id_atendimento: number) => {
        showSaveMessage();
        await api
            .put(`medico/procedimento-suspender/${id_atendimento}`)
            .then(_response => {
                successToast();
                formRef?.current?.submitForm();
            })
            .catch(error => {
                getValidationErrors(error.response);
            })
        hideSaveMessage();
    }

    const procedimentoAssumir = async (id_atendimento: number) => {
        showSaveMessage();
        await api
            .put(`medico/procedimento-assumir/${id_atendimento}`)
            .then(_response => {
                successToast();
                formRef?.current?.submitForm();
            })
            .catch(error => {
                getValidationErrors(error.response);
            })
        hideSaveMessage();
    }


    useIonViewDidEnter(() => {
        setProcedimentos([])
    }, [])

    useIonViewWillLeave(() => {
        setProcedimentos([])
    }, [])

    const previousDate = () => {
        const data = formRef.current?.getFieldValue("data");
        if (data) {
            let yesterday = subDays(parseISO(data), 1);
            formRef?.current?.setFieldValue("data", format(yesterday, "yyyy-MM-dd"));
            formRef?.current?.submitForm();
        }
    }

    const nextDate = () => {
        const data = formRef.current?.getFieldValue("data");
        if (data) {
            let tomorrow = addDays(parseISO(data), 1);
            formRef?.current?.setFieldValue("data", format(tomorrow, "yyyy-MM-dd"));
            formRef?.current?.submitForm();
        }
    }

    return (
        <Main title="Procedimentos">
            <Card>
                <IonCardContent>
                    <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
                        <IonGrid>
                            <IonRow className="ion-align-items-end">
                                <ReactSelect
                                    label="Hospital"
                                    name="id_hospital"
                                    url="/medico/select/hospital"
                                    params={{ status: "A" }}
                                    required
                                />
                                <Select
                                    label="Todos os Médicos?"
                                    name="todos_medicos"
                                    values={[
                                        { value: "N", label: "NÃO" },
                                        { value: "S", label: "SIM" }
                                    ]}
                                />
                                <Select
                                    label="Situação"
                                    name="situacao"
                                    values={[
                                        { value: "TODAS", label: "TODAS" },
                                        { value: "PENDENTE", label: "PENDENTE" },
                                        { value: "AGENDADO", label: "AGENDADO" },
                                        { value: "ANDAMENTO", label: "ANDAMENTO" },
                                        { value: "REALIZADO", label: "REALIZADO" },
                                        { value: "CANCELADO", label: "CANCELADO" },
                                        { value: "SUSPENSO", label: "SUSPENSO" },
                                    ]}
                                    required
                                />
                                <Input
                                    label="Data"
                                    name="data"
                                    type="date"
                                    required
                                />

                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="3">
                                    <IonButton
                                        type="button"
                                        onClick={previousDate}
                                        color="primary"
                                        expand="block"
                                        disabled={!!formRef?.current?.getFieldValue("data") ? false : true}
                                    >
                                        <IonIcon size="small" icon={caretBack} />
                                    </IonButton>
                                </IonCol>
                                <IonCol size="6">
                                    <IonButton type="submit" color="primary" expand="block">
                                        Consultar
                                    </IonButton>
                                </IonCol>
                                <IonCol size="3">
                                    <IonButton
                                        type="button"
                                        onClick={nextDate}
                                        color="primary"
                                        expand="block"
                                        disabled={!!formRef?.current?.getFieldValue("data") ? false : true}
                                    >
                                        <IonIcon size="small" icon={caretForward} />
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </Form>
                </IonCardContent>
            </Card>
            {procedimentos.map(procedimento => {
                return (
                    <IonCard
                        key={procedimento.id}
                        className={
                            ['VISITA HOSPITALAR', 'PARECER'].includes(procedimento.proc)
                                ? 'card-visita'
                                : `card-${procedimento.situacao?.toLowerCase()}`
                        }
                    >
                        <IonCardHeader>
                            <IonCardTitle>
                                <IonRow>
                                    {['VISITA HOSPITALAR', 'PARECER'].includes(procedimento.proc) ? (
                                        <IonCol size="12">{procedimento.proc}</IonCol>
                                    ) : (
                                        <IonCol size="10">Situação: {procedimento.situacao}</IonCol>
                                    )}
                                    {procedimento.relatorio_medico === "S" && (
                                        <IonCol
                                            size="2"
                                            style={{
                                                textAlign: 'right',
                                                fontWeight: 'bold',
                                                color: '#000000'
                                            }}
                                        >
                                            <IonIcon icon={readerOutline} />
                                        </IonCol>
                                    )}
                                </IonRow>
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonRow>
                                <IonCol size="12">
                                    <IonLabel>Data: </IonLabel>
                                    <IonText>{procedimento.data}</IonText>
                                </IonCol>
                                {!['VISITA HOSPITALAR', 'PARECER'].includes(procedimento.proc) && (
                                    <IonCol size="12">
                                        <IonLabel>Procedimento: </IonLabel>
                                        <IonText>{procedimento.proc}</IonText>
                                    </IonCol>
                                )}
                                <IonCol size="12">
                                    <IonLabel>Hospital: </IonLabel>
                                    <IonText>{procedimento.hospital}</IonText>
                                </IonCol>
                                <IonCol size="12">
                                    <IonLabel>Paciente: </IonLabel>
                                    <IonText>{procedimento.paciente}</IonText>
                                </IonCol>
                                <IonCol size="12">
                                    <IonLabel>Localização: </IonLabel>
                                    <IonText>{procedimento.localizacao}</IonText>
                                </IonCol>
                                <IonCol size="12">
                                    <IonLabel>Leito: </IonLabel>
                                    <IonText>{procedimento.leito}</IonText>
                                </IonCol>
                                <IonCol size="12">
                                    <IonLabel>Técnico: </IonLabel>
                                    <IonText>{procedimento.tecnico}</IonText>
                                </IonCol>
                                <IonCol size="12">
                                    <IonLabel>Médico: </IonLabel>
                                    <IonText>{procedimento.medico}</IonText>
                                </IonCol>
                                {!['VISITA HOSPITALAR', 'PARECER'].includes(procedimento.proc) && (
                                    <>
                                        <IonCol size="12">
                                            <IonLabel>Horas Prescritas: </IonLabel>
                                            <IonText>{procedimento.horas_prescritas}</IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonLabel>Horas Realizadas: </IonLabel>
                                            <IonText>{procedimento.horas_realizadas}</IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonLabel>A diálise terminou antes do tempo? </IonLabel>
                                            <IonText>{procedimento.terminou_antes}</IonText>
                                        </IonCol>
                                    </>
                                )}
                                {!['VISITA HOSPITALAR', 'PARECER'].includes(procedimento.proc) && (procedimento.situacao === "ANDAMENTO" ||
                                    procedimento.situacao === "REALIZADO") &&
                                    <>
                                        <IonCol size="12">
                                            <IonLabel>Hora de Início: </IonLabel>
                                            <IonText>{procedimento.inicio}</IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonLabel>Hora de Fim: </IonLabel>
                                            <IonText>{procedimento.fim}</IonText>
                                        </IonCol>
                                    </>
                                }
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="6">
                                    {procedimento.situacao !== 'REALIZADO' &&
                                        procedimento.id_medico === usuario.id_usuario ?
                                        <IonButton
                                            type="button"
                                            routerLink={`/medico/prescricao?id_atendimento=${procedimento.id}&repeat=N`}
                                            color={`${procedimento.prescricao === "N" ? "danger" : "primary"
                                                }`}
                                            expand="block"
                                        >
                                            Preencher
                                        </IonButton>
                                        :
                                        <IonButton
                                            type="button"
                                            routerLink={`/prescricao?id_atendimento=${procedimento.id}`}
                                            color="primary"
                                            expand="block"
                                        >
                                            Prescrição
                                        </IonButton>
                                    }
                                </IonCol>
                                <IonCol size="6">
                                    {procedimento.situacao !== 'SUSPENSO' &&
                                        procedimento.situacao !== 'CANCELADO' &&
                                        procedimento.situacao !== 'REALIZADO' &&
                                        <IonButton
                                            type="button"
                                            onClick={() => procedimentoSuspender(procedimento.id)}
                                            color="danger"
                                            expand="block"
                                        >
                                            Suspender
                                        </IonButton>
                                    }
                                </IonCol>
                                <IonCol size="6">
                                    {procedimento.prescricao === "S" &&
                                        <IonButton
                                            type="button"
                                            routerLink={`/medico/prescricao?id_atendimento=${procedimento.id}&repetir=S`}
                                            color="secondary"
                                            expand="block"
                                        >
                                            Repetir
                                        </IonButton>
                                    }
                                </IonCol>
                                <IonCol size="6">
                                    {procedimento.situacao !== 'REALIZADO' &&
                                        procedimento.id_medico !== usuario.id_usuario &&
                                        <IonButton
                                            type="button"
                                            onClick={() => procedimentoAssumir(procedimento.id)}
                                            color="warning"
                                            expand="block"
                                        >
                                            Assumir
                                        </IonButton>
                                    }
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                {(procedimento.situacao === 'REALIZADO' ||
                                    procedimento.situacao === 'ANDAMENTO')
                                    &&
                                    (
                                        <>
                                            {procedimento.anotacao === "S" &&
                                                <IonCol size="6">
                                                    <IonButton
                                                        type="button"
                                                        routerLink={`/anotacao/${procedimento.id}/${procedimento.proc}`}
                                                        color="primary"
                                                        expand="block"
                                                    >
                                                        Anotações
                                                    </IonButton>
                                                </IonCol>
                                            }
                                            {procedimento.evolucao === "S" &&
                                                <IonCol size="6">
                                                    <IonButton
                                                        type="button"
                                                        routerLink={`/evolucao/${procedimento.id}`}
                                                        color="medium"
                                                        expand="block"
                                                    >
                                                        Evolução
                                                    </IonButton>
                                                </IonCol>
                                            }
                                        </>
                                    )
                                }
                                <IonCol size="12">
                                    <PassagemPlantao
                                        id_usuario={usuario.id_usuario}
                                        id_medico={procedimento.id_medico}
                                        id_atendimento={procedimento.id}
                                        situacao={procedimento.situacao}

                                        passagem_plantao={procedimento.passagem_plantao}
                                        destino={procedimento.destino}
                                        periodicidade_prevista={procedimento.periodicidade_prevista}
                                    />
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                )
            })}
        </Main >
    )
}

export default MedicoProcedimentos;