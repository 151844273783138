import { useEffect, useRef } from "react";
import {
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    isPlatform
} from "@ionic/react";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import api from "../../services/api";

import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import { useValidation } from "../../hooks/validation";

import Card from "../../components/Card";
import Input from "../../components/Input";

interface FormValues {
    senha: string
    confirmar_senha: string
}

const Senha = () => {
    const { alterarSenha } = useAuth();
    const { addToast } = useToast();
    const { getValidationErrors } = useValidation();

    const formRef = useRef<FormHandles>(null);

    useEffect(() => {
        const platform = isPlatform('mobileweb') || isPlatform('desktop')
        if (!platform) {
            ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
        }
    }, [])

    const handleSubmit: SubmitHandler = async (values: FormValues) => {
        await api
            .put("senha", values)
            .then(response => {
                if (!!response.data) {
                    alterarSenha(response.data.senha_temp);
                    addToast({ message: 'Senha alterada.', color: 'success' });
                    formRef?.current?.reset();
                }
            })
            .catch(error => {
                getValidationErrors(error.response);
            })
    }

    return (
        <Card>
            <IonCardContent>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <IonGrid>
                        <IonRow className="ion-align-items-end">
                            <Input
                                label="Senha"
                                name="senha"
                                type="password"
                                autoFocus
                                autoComplete="off"
                                autoCapitalize="off"
                                autoCorrect="off"
                            />
                            <Input
                                label="Confirmar Senha"
                                name="confirmar_senha"
                                type="password"
                                autoFocus
                                autoComplete="off"
                                autoCapitalize="off"
                                autoCorrect="off"
                            />
                        </IonRow>
                        <IonRow className="ion-margin-top">
                            <IonCol size="7">
                                <IonButton type="submit" color="primary" expand="block">
                                    Alterar Senha
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </Form>
            </IonCardContent>
        </Card>
    )
}

export default Senha;
