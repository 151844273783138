import { useEffect, useRef, useState } from "react";
import {
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonText,
    IonLabel
} from "@ionic/react";

import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import api from "../../../services/api";

import { useLoading } from "../../../hooks/loading";
import { useValidation } from "../../../hooks/validation";

import Main from "../../../components/Main";
import Card from "../../../components/Card";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import ReactSelect from "../../../components/ReactSelect";

import Formulario from "./Formulario";

export interface Atendimento {
    id: number;
    data: string;
    proc: string;
    situacao: string;
    hospital: string;
    paciente: string;
    localizacao: string;
    leito: string;

    urgente: "S" | "N";

    id_tecnico1: number;
    nome_tecnico1: string;
    inicio_tecnico1: string;

    id_tecnico2: number;
    nome_tecnico2: string;
    inicio_tecnico2: string;

    id_tecnico3: number;
    nome_tecnico3: string;
    inicio_tecnico3: string;

    id_tecnico4: number;
    nome_tecnico4: string;
    inicio_tecnico4: string;

    id_tecnico5: number;
    nome_tecnico5: string;
    inicio_tecnico5: string;

    id_tecnico6: number;
    nome_tecnico6: string;
    inicio_tecnico6: string;

    id_tecnico7: number;
    nome_tecnico7: string;
    inicio_tecnico7: string;

    id_tecnico8: number;
    nome_tecnico8: string;
    inicio_tecnico8: string;
}

export interface Data {
    realizado: number;
    alocado: number;
    alocar: number;
    atendimentos: Atendimento[];
}

const sessionName = process.env.REACT_APP_SESSION_NAME;

const EnfermagemAtendimento = () => {
    const { showLoading, hideLoading } = useLoading();
    const { getValidationErrors } = useValidation();

    const consultRef = useRef<FormHandles>(null);
    const [lastUpdate, setLastUpdate] = useState<Date | null>(null);
    const [data, setData] = useState<Data | null>(null);

    const consultValues = () => {
        return JSON.parse(
            `${localStorage.getItem(
                `@${sessionName}:EnfermagemAtendimento`
            ) || "{}"
            }`
        )
    }

    const handleConsult: SubmitHandler = async (values) => {
        localStorage.setItem(
            `@${sessionName}:EnfermagemAtendimento`,
            JSON.stringify(values)
        )

        showLoading();
        await api
            .get("enfermagem/atendimento", {
                params: {
                    id_hospital: values?.id_hospital?.value,
                    id_paciente: values?.id_paciente?.value,
                    leito: values?.leito,
                    tipo: values?.tipo,
                    de_data: values?.de_data,
                    ate_data: values?.ate_data,
                }
            })
            .then(response => {
                setData(response.data)
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
        hideLoading();
    }

    useEffect(() => {
        if (!!lastUpdate) {
            consultRef?.current?.submitForm();
        }
    }, [lastUpdate])

    return (
        <Main title="Atendimentos">
            <Card>
                <IonCardContent>
                    <Form
                        ref={consultRef}
                        initialData={consultValues()}
                        onSubmit={handleConsult}
                    >
                        <IonGrid>
                            <IonRow className="ion-align-items-end">
                                <ReactSelect
                                    label="Hospital"
                                    name="id_hospital"
                                    url="/enfermagem/select/hospital"
                                    params={{ status: "A" }}
                                    required
                                    clearable
                                />
                                <ReactSelect
                                    label="Paciente"
                                    name="id_paciente"
                                    url="/enfermagem/select/paciente"
                                    params={{ status: "A" }}
                                    clearable
                                />
                                <Input
                                    label="Leito"
                                    name="leito"
                                    type="text"
                                />
                                <Select
                                    label="Tipo"
                                    name="tipo"
                                    values={[
                                        { value: "TODOS", label: "TODOS" },
                                        { value: "REALIZADO", label: "REALIZADO" },
                                        { value: "ALOCADO", label: "ALOCADO" },
                                        { value: "ALOCAR", label: "ALOCAR" }
                                    ]}
                                />
                                <Input
                                    label="De"
                                    name="de_data"
                                    type="date"
                                />
                                <Input
                                    label="Até"
                                    name="ate_data"
                                    type="date"
                                />
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="12">
                                    <IonButton type="submit" color="primary" expand="block">
                                        Consultar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </Form>
                </IonCardContent>
            </Card>
            {data && (
                <>
                    <IonCard>
                        <IonCardHeader>
                            <IonRow>
                                <IonCol size="4">
                                    <IonText>Realizado: {data?.realizado}</IonText>
                                </IonCol>
                                <IonCol size="4">
                                    <IonText>Alocado: {data?.alocado}</IonText>
                                </IonCol>
                                <IonCol size="4">
                                    <IonText>Alocar: {data?.alocar}</IonText>
                                </IonCol>
                            </IonRow>
                        </IonCardHeader>
                    </IonCard>
                    {data.atendimentos.map(atendimento => {
                        return (
                            <IonCard key={atendimento.id}
                                className={`${!atendimento.id_tecnico1 ? 'card-danger' : `card-${atendimento.situacao.toLowerCase()}`
                                    } ${atendimento.urgente === "S" && (atendimento.situacao === 'PENDENTE' || atendimento.situacao === 'AGENDADO')
                                        ? 'urgente'
                                        : ''
                                    }`}
                            >
                                <IonCardHeader>
                                    <IonCardTitle>DATA: {atendimento.data}</IonCardTitle>
                                    <IonCardTitle>FOLHA: {atendimento.id}</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonLabel>PROCEDIMENTO: </IonLabel>
                                            <IonText>{atendimento.proc}</IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonLabel>SITUAÇÃO: </IonLabel>
                                            <IonText>{atendimento.situacao}</IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonLabel>HOSPITAL: </IonLabel>
                                            <IonText>{atendimento.hospital}</IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonLabel>PACIENTE: </IonLabel>
                                            <IonText>{atendimento.paciente}</IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <Formulario
                                                id_atendimento={atendimento.id}
                                                atendimento={atendimento}
                                                setLastUpdate={setLastUpdate}
                                                setData={setData}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-margin-top">
                                        <IonCol size="6">
                                            <IonButton
                                                type="button"
                                                routerLink={`/anotacao/${atendimento.id}/${atendimento.proc}`}
                                                color="secondary"
                                                expand="block"
                                            >
                                                Anotações
                                            </IonButton>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonButton
                                                type="button"
                                                routerLink={`/evolucao/${atendimento.id}`}
                                                color="medium"
                                                expand="block"
                                            >
                                                Evolução
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        )
                    })}
                </>
            )}
        </Main>
    )
}

export default EnfermagemAtendimento;