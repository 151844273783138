import { useEffect, useRef } from "react";
import { IonCol } from "@ionic/react";
import { useField } from "@unform/core";

import Label from "../Label";

import IonTextarea from "./styles";

interface Props {
    size?: string
    label?: string
    name: string
    rows?: number
    required?: boolean
    disabled?: boolean
    hidden?: boolean
}

const Input = ({
    size,
    label,
    name,
    rows,
    required,
    disabled,
    hidden,
}: Props) => {
    const inputRef = useRef<HTMLIonTextareaElement>(null);
    const { fieldName, defaultValue, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: (ref) => {
                if (!!ref.value)
                    return ref.value;
            },
            setValue: (ref, value) => {
                if (!!value)
                    ref.value = value;
            },
            clearValue: (ref, value) => {
                if (!!value)
                    ref.value = value;
                else
                    ref.value = "";
            },
        });
    }, [fieldName, registerField]);

    return (
        <IonCol size={size || "12"} style={!hidden ? {} : { display: "none" }} >
            {!!label && !hidden && <Label required={required}>{label}</Label>}
            <IonTextarea
                ref={inputRef}
                name={name}
                defaultValue={defaultValue}
                rows={rows}
                readonly={disabled ? true : false}
                hidden={hidden}
            />
        </IonCol>
    );
};

export default Input;
