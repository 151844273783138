import { useRef, useState } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonRow,
    IonSelectOption,
    IonText
} from "@ionic/react";

import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import api from "../../../services/api";

import { useAuth } from "../../../hooks/auth";
import { useLoading } from "../../../hooks/loading";
import { useValidation } from "../../../hooks/validation";

import Main from "../../../components/Main";
import Input from "../../../components/Input";
import Select from "../../../components/Select";

const sessionName = process.env.REACT_APP_SESSION_NAME

export interface Pontuacao {
    assists: {
        id: number
        technician: string
        patient: string
        agreement: string
        procedure: string
        score: number
        hospital: string
        date: string
        start: string
        end: string
        time: string
    }[]
    assists_count: number
    score: number
    time: string
}

const TecnicoPontuacao = () => {
    const { usuario } = useAuth();
    const { showLoading, hideLoading } = useLoading();
    const { getValidationErrors } = useValidation();

    const consultRef = useRef<FormHandles>(null);
    const [pontuacao, setPontuacao] = useState<Pontuacao>({} as Pontuacao);

    const handleConsult: SubmitHandler = async (values) => {
        localStorage.setItem(
            `@${sessionName}:TecnicoPontuacao`,
            JSON.stringify(values)
        )

        showLoading();
        await api
            .get(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/pontuacao`, {
                params: {
                    ...values
                }
            })
            .then(response => {
                setPontuacao(response.data)
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
        hideLoading();
    }

    const consultValues = () => {
        return JSON.parse(
            `${localStorage.getItem(
                `@${sessionName}:TecnicoPontuacao`
            ) || "{}"
            }`
        )
    }

    return (
        <Main title="Pontuação">
            <IonCard>
                <IonCardContent>
                    <Form
                        ref={consultRef}
                        initialData={consultValues()}
                        onSubmit={handleConsult}
                    >
                        <IonGrid>
                            <IonRow className="ion-align-items-end">
                                <Input
                                    label="Mês"
                                    name="month"
                                    type="month"
                                    required
                                />
                                <Select
                                    label="Tipo"
                                    name="type"
                                    required
                                >
                                    <IonSelectOption value="ALL">Todos</IonSelectOption>
                                    <IonSelectOption value="PA">Particular</IonSelectOption>
                                    <IonSelectOption value="PU">Público</IonSelectOption>
                                </Select>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="12">
                                    <IonButton type="submit" color="primary" expand="block">
                                        Consultar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </Form>


                </IonCardContent>
            </IonCard>
            {!!pontuacao.assists && (
                <>
                    <IonCard>
                        <IonCardContent>
                            <IonList>
                                <IonListHeader mode="ios">Total</IonListHeader>
                                <IonItem>
                                    <IonLabel>Pontos: </IonLabel>
                                    <IonText>{pontuacao.score}</IonText>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Procedimentos: </IonLabel>
                                    <IonText>{pontuacao.assists_count}</IonText>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Horas: </IonLabel>
                                    <IonText>{pontuacao.time}</IonText>
                                </IonItem>
                            </IonList>
                        </IonCardContent>
                    </IonCard>
                    {pontuacao.assists.map((assist, index) => (
                        <IonCard key={index}>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol size="12">
                                        <IonLabel>Técnico: </IonLabel>
                                        <IonText>{assist.technician}</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Paciente: </IonLabel>
                                        <IonText>{assist.patient}</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Convênio: </IonLabel>
                                        <IonText>{assist.agreement}</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Procedimento: </IonLabel>
                                        <IonText>{assist.procedure}</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Pontos: </IonLabel>
                                        <IonText>{assist.score}</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Hospital: </IonLabel>
                                        <IonText>{assist.hospital}</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Data: </IonLabel>
                                        <IonText>{assist.date}</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Hora de Inicio: </IonLabel>
                                        <IonText>{assist.start}</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Hora de Fim: </IonLabel>
                                        <IonText>{assist.end}</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Total de Horas: </IonLabel>
                                        <IonText>{assist.time}</IonText>
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                    ))}
                </>
            )}
        </Main>
    )
}

export default TecnicoPontuacao;
