import { createRoot } from "react-dom/client";

import AppProvider from "./hooks";
import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <AppProvider>
    <App />
  </AppProvider>
);

