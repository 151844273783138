import { useEffect, useRef, useState } from "react";
import { IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonRow, IonSelectOption, useIonViewDidEnter } from "@ionic/react";
import { useHistory, useParams } from "react-router";
import { SubmitHandler, FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import api from '../../../services/api'

import { useLoading } from '../../../hooks/loading'
import { useToast } from '../../../hooks/toast'
import { useValidation } from '../../../hooks/validation'

import Main from "../../../components/Main";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import ReactSelect from "../../../components/ReactSelect";
import Textarea from "../../../components/Textarea";
import FilePicker from "../../../components/FilePicker";

interface ParamTypes {
    id: string;
    serial: string;
}

const MotoristaMaquinaChecklist = () => {
    const { showLoading, hideLoading, showSaveMessage, hideSaveMessage } = useLoading();
    const { successToast } = useToast();
    const { getValidationErrors } = useValidation();
    const history = useHistory();

    const { id, serial } = useParams<ParamTypes>();

    const formRef = useRef<FormHandles>(null)
    const [data, setData] = useState([]);
    const [tipo, setTipo] = useState("");
    const [transito, setTransito] = useState("N");
    const [danosExternos, setDanosExternos] = useState("N");

    const [scroll, setScroll] = useState(new Date());

    useEffect(() => {
        formRef?.current?.setData(data);
    }, [data])

    const getData = async () => {
        showLoading();
        await api.get(`motorista/maquina/${id}`).then(response => {
            const { data } = response;
            if (data) {
                setTipo(data.tipo);
                setTransito(data.transito);
                setDanosExternos(data.danos_externos);
                setData(data);
            }
        })
        hideLoading();
    }

    useIonViewDidEnter(async () => {
        await reset();
        await getData();
    }, [id])

    const submit: SubmitHandler = async data => {
        showSaveMessage()

        let formData = new FormData()

        for (const key in data) {
            if (!!data[key]) {
                formData.append(key, data[key])
            }
        }

        await api
            .post(`motorista/maquina/${id}`, formData, {
                headers: { 'content-type': 'multipart/form-data' }
            })
            .then(() => {
                successToast()
                reset()
                history.push("/motorista/maquina-movimento");
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
        hideSaveMessage()
    }

    const reset = async () => {
        formRef?.current?.reset()
        setScroll(new Date())
    }

    return (
        <Main title={`Equipamento Checklist (${serial})`} scroll={scroll} back>
            <IonCard>
                <IonCardContent>
                    <IonGrid>
                        <Form ref={formRef} initialData={{ serial }} onSubmit={submit}>
                            <IonRow className="ion-align-items-end">
                                <ReactSelect
                                    label="Origem"
                                    name="id_origem"
                                    url="/motorista/localizacao"
                                    simple
                                    required
                                />
                                <ReactSelect
                                    label="Destino"
                                    name="id_destino"
                                    url="/motorista/localizacao"
                                    simple
                                    required
                                />
                                <Input
                                    label="Serial"
                                    name="serial"
                                    type="text"
                                    disabled
                                />
                                <Select
                                    size="12"
                                    label="Tipo do Equipamento"
                                    name="tipo"
                                    disabled
                                >
                                    <IonSelectOption value="C">CAPD</IonSelectOption>
                                    <IonSelectOption value="CR">CRRT</IonSelectOption>
                                    <IonSelectOption value="H">HEMODIÁLISE</IonSelectOption>
                                    <IonSelectOption value="HDF">HDF</IonSelectOption>
                                    <IonSelectOption value="T">TRAT. DE ÁGUA</IonSelectOption>
                                </Select>
                                <Select
                                    size="12"
                                    label="Motivo da Retirada"
                                    name="motivo_retirada"
                                    required
                                >
                                    <IonSelectOption value="P">PLANEJADA</IonSelectOption>
                                    <IonSelectOption value="C">CORRETIVA</IonSelectOption>
                                    <IonSelectOption value="D">DEMANDA</IonSelectOption>
                                    <IonSelectOption value="T">
                                        TRANFERENCIA ENTRE UNIDADES/HOSPITAIS
                                    </IonSelectOption>
                                </Select>
                                {["CR", "H", "HDF"].includes(tipo) && (
                                    <>
                                        <Select
                                            size="6"
                                            label="Mangueira de Água"
                                            name="mangueira_agua"
                                        >
                                            <IonSelectOption value="N">Não</IonSelectOption>
                                            <IonSelectOption value="S">Sim</IonSelectOption>
                                        </Select>
                                        <Select
                                            size="6"
                                            label="Mangueira de Esgoto"
                                            name="mangueira_esgoto"
                                        >
                                            <IonSelectOption value="N">Não</IonSelectOption>
                                            <IonSelectOption value="S">Sim</IonSelectOption>
                                        </Select>
                                        <Select
                                            size="6"
                                            label="Suporte de Soro/Medicação"
                                            name="suporte_soro"
                                        >
                                            <IonSelectOption value="N">Não</IonSelectOption>
                                            <IonSelectOption value="S">Sim</IonSelectOption>
                                        </Select>
                                        <Select
                                            size="6"
                                            label="Extenção de Energia"
                                            name="extencao_energia"
                                        >
                                            <IonSelectOption value="N">Não</IonSelectOption>
                                            <IonSelectOption value="S">Sim</IonSelectOption>
                                        </Select>
                                        <Select
                                            size="6"
                                            label="Manivela da Bomda de Sangue"
                                            name="manivela_bomba"
                                        >
                                            <IonSelectOption value="N">Não</IonSelectOption>
                                            <IonSelectOption value="S">Sim</IonSelectOption>
                                        </Select>
                                        <Select
                                            size="6"
                                            label="Suporte da Bolsa da Balança"
                                            name="suporte_bolsa"
                                        >
                                            <IonSelectOption value="N">Não</IonSelectOption>
                                            <IonSelectOption value="S">Sim</IonSelectOption>
                                        </Select>
                                        <Select
                                            size="6"
                                            label="Suporte do Capilar"
                                            name="suporte_capilar"
                                        >
                                            <IonSelectOption value="N">Não</IonSelectOption>
                                            <IonSelectOption value="S">Sim</IonSelectOption>
                                        </Select>
                                        <Select
                                            size="6"
                                            label="Braçadeira"
                                            name="bracadeira"
                                        >
                                            <IonSelectOption value="N">Não</IonSelectOption>
                                            <IonSelectOption value="S">Sim</IonSelectOption>
                                        </Select>
                                    </>
                                )}
                                {tipo === "T" && (
                                    <>
                                        <Select
                                            size="6"
                                            label="Mangueira Torneira/Osmose"
                                            name="mangueira_osmose"
                                        >
                                            <IonSelectOption value="N">Não</IonSelectOption>
                                            <IonSelectOption value="S">Sim</IonSelectOption>
                                        </Select>
                                        <Select
                                            size="6"
                                            label="Mangueira Osmose/Máquina"
                                            name="mangueira_maquina"
                                        >
                                            <IonSelectOption value="N">Não</IonSelectOption>
                                            <IonSelectOption value="S">Sim</IonSelectOption>
                                        </Select>
                                        <Select
                                            size="6"
                                            label="Mangueira do Dreno"
                                            name="mangueira_dreno"
                                        >
                                            <IonSelectOption value="N">Não</IonSelectOption>
                                            <IonSelectOption value="S">Sim</IonSelectOption>
                                        </Select>
                                    </>
                                )}
                                <Select
                                    size="6"
                                    label="Conector de Engate Rápido"
                                    name="conector_engate"
                                >
                                    <IonSelectOption value="N">Não</IonSelectOption>
                                    <IonSelectOption value="S">Sim</IonSelectOption>
                                </Select>
                                <Select
                                    size="6"
                                    label="Ficha de Controle de Desinfecção"
                                    name="controle_desinfecao"
                                >
                                    <IonSelectOption value="N">Não</IonSelectOption>
                                    <IonSelectOption value="S">Sim</IonSelectOption>
                                </Select>
                                <Select
                                    size="6"
                                    label="Equipamento Limpo"
                                    name="equipamento_limpo"
                                >
                                    <IonSelectOption value="N">Não</IonSelectOption>
                                    <IonSelectOption value="S">Sim</IonSelectOption>
                                </Select>
                                <Select
                                    size="6"
                                    label="Danos Externos Aparente"
                                    name="danos_externos"
                                    onChange={(value: string) => setDanosExternos(value)}
                                >
                                    <IonSelectOption value="N">Não</IonSelectOption>
                                    <IonSelectOption value="S">Sim</IonSelectOption>
                                </Select>
                                {danosExternos === "S" && (
                                    <Textarea
                                        name="danos"
                                        label="Quais Danos?"
                                        required
                                        rows={2}
                                    />
                                )}
                                <Textarea
                                    name="obs"
                                    label="Observação"
                                    rows={2}
                                />
                                <ReactSelect
                                    label="Nome do Responsável Recebedor"
                                    name="recebido_por"
                                    url="/motorista/responsavel"
                                    simple
                                    required={transito === "S"}
                                />
                                <Input
                                    label="Data"
                                    name="data"
                                    type="date"
                                    required
                                />
                                <Input
                                    label="Hora"
                                    name="hora"
                                    type="time"
                                    required
                                />
                                <FilePicker
                                    label="Foto"
                                    name="foto"
                                    required
                                />
                                <IonCol size="6" className="ion-margin-top">
                                    <IonButton type="submit" color="primary" expand="block">
                                        Salvar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </Form>
                    </IonGrid>
                </IonCardContent>
            </IonCard>
        </Main >
    )
}

export default MotoristaMaquinaChecklist;