import { useState } from "react";
import {
    IonAlert,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonRow,
    IonSelectOption,
    IonText,
    useIonViewDidEnter
} from "@ionic/react";
import { refresh } from "ionicons/icons";
import { format } from "date-fns";

import { useAuth } from "../../../hooks/auth";
import { useToast } from "../../../hooks/toast";
import { useLoading } from "../../../hooks/loading";
import { useValidation } from "../../../hooks/validation";
import { useNotifications } from "../../../hooks/notifications";

import api from "../../../services/api";

import Main from "../../../components/Main";
import { IonInput } from "../../../components/Input/styles";
import { IonSelect } from "../../../components/Select/styles";
import { Option } from "../../../components/ReactSelect";

import SearchMachineByNumber from "../../../legacy/components/MachineByNumber";
import { getType } from "../../../legacy/shared/Procedures"

interface Row {
    id: number
    patient_name: string
    hospital_name: string
    location: string
    bed: string
    date: string
    estimated_start: string
    start: string
    doctor_name: string
    technician1_id: number
    technician2_id: number
    technician3_id: number
    technician4_id: number
    technician5_id: number
    technician6_id: number
    technician7_id: number
    technician8_id: number
    technician1_name: string
    technician2_name: string
    technician3_name: string
    technician4_name: string
    technician5_name: string
    technician6_name: string
    technician7_name: string
    technician8_name: string
    tec1start: string
    tec2start: string
    tec3start: string
    tec4start: string
    tec5start: string
    tec6start: string
    tec7start: string
    tec8start: string
    tec1end: string
    tec2end: string
    tec3end: string
    tec4end: string
    tec5end: string
    tec6end: string
    tec7end: string
    tec8end: string
    proc: string
    precaution: "N" | "C" | "R"
    tec1open: 0 | 1
    tec2open: 0 | 1
    tec3open: 0 | 1
    tec4open: 0 | 1
    tec5open: 0 | 1
    tec6open: 0 | 1
    tec7open: 0 | 1
    tec1close: 0 | 1
    tec2close: 0 | 1
    tec3close: 0 | 1
    tec4close: 0 | 1
    tec5close: 0 | 1
    tec6close: 0 | 1
    tec7close: 0 | 1
    note?: string
}

interface AtendimentoProps {
    row: Row;
    getList(): void;
}

const Atendimento = ({
    row,
    getList,
}: AtendimentoProps) => {
    const { usuario } = useAuth();
    const { successToast } = useToast();
    const { getValidationErrors } = useValidation();
    const { getContadorAtendimentos } = useNotifications();

    const [tec1start, setTec1Start] = useState(row.tec1start || "00:00:00")
    const [machine, setMachine] = useState<Option>({} as Option)
    const [reverseOsmosis, setReverseOsmosis] = useState<Option>(
        {} as Option
    )
    const [homeChoice, setHomeChoice] = useState<Option>({} as Option)
    const [precaution, setPrecaution] = useState(row.precaution || null)
    const [showAlert1, setShowAlert1] = useState(false)

    const handleTech1Submit = async () => {
        await api
            .put(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/agendados/${row.id}`, {
                technician1_id: row.technician1_id,
                tec1start: tec1start,
                machine: machine.value,
                reverse_osmosis: reverseOsmosis.value,
                home_choice: homeChoice.value,
                precaution: precaution
            })
            .then(_response => {
                successToast()
                getList()
                getContadorAtendimentos()
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
    }

    const handleTech2Submit = async () => {
        await api
            .put(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/agendados/${row.id}`, {
                technician2_id: row.technician2_id,
                tec2start: row.tec2start
            })
            .then(_response => {
                successToast()
                getList()
                getContadorAtendimentos()
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
    }

    const handleTech3Submit = async () => {
        await api
            .put(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/agendados/${row.id}`, {
                technician3_id: row.technician3_id,
                tec3start: row.tec3start
            })
            .then(_response => {
                successToast()
                getList()
                getContadorAtendimentos()
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
    }

    const handleTech4Submit = async () => {
        await api
            .put(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/agendados/${row.id}`, {
                technician4_id: row.technician4_id,
                tec4start: row.tec4start
            })
            .then(_response => {
                successToast()
                getList()
                getContadorAtendimentos()
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
    }

    const handleTech5Submit = async () => {
        await api
            .put(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/agendados/${row.id}`, {
                technician5_id: row.technician5_id,
                tec5start: row.tec5start
            })
            .then(_response => {
                successToast()
                getList()
                getContadorAtendimentos()
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
    }

    const handleTech6Submit = async () => {
        await api
            .put(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/agendados/${row.id}`, {
                technician6_id: row.technician6_id,
                tec6start: row.tec6start
            })
            .then(_response => {
                successToast()
                getList()
                getContadorAtendimentos()
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
    }

    const handleTech7Submit = async () => {
        await api
            .put(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/agendados/${row.id}`, {
                technician7_id: row.technician7_id,
                tec7start: row.tec7start
            })
            .then(_response => {
                successToast()
                getList()
                getContadorAtendimentos()
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
    }

    const handleTech8Submit = async () => {
        await api
            .put(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/agendados/${row.id}`, {
                technician8_id: row.technician8_id,
                tec8start: row.tec8start
            })
            .then(_response => {
                successToast()
                getList()
                getContadorAtendimentos()
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
    }

    return (
        <IonCard className="card-agendado">
            <IonCardHeader>
                <IonCardTitle>
                    PACIENTE: <strong>{row.patient_name}</strong>
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonRow className="ion-align-items-end">
                    <IonCol size="12" className="ion-no-padding">
                        <IonLabel>HOSPITAL: </IonLabel>
                        <IonText>{row.hospital_name}</IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                        <IonLabel>LOCALIZAÇÃO: </IonLabel>
                        <IonText>{row.location}</IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                        <IonLabel>LEITO: </IonLabel>
                        <IonText>{row.bed}</IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                        <IonLabel>DATA PRESCRITA: </IonLabel>
                        <IonText>
                            {!!row.date &&
                                format(new Date(row.date + "T00:00:00"), "dd/MM/yyyy") + " "}
                        </IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                        <IonLabel>HORÁRIO ESTIMADO: </IonLabel>
                        <IonText>
                            <IonText>
                                {row.estimated_start}
                                <strong>
                                    {row.technician2_id === usuario.id_usuario && !!row.tec2start
                                        ? `(${row.tec2start.substr(0, 5)})`
                                        : row.technician3_id === usuario.id_usuario &&
                                            !!row.tec3start
                                            ? `(${row.tec3start.substr(0, 5)})`
                                            : row.technician4_id === usuario.id_usuario &&
                                                !!row.tec4start
                                                ? `(${row.tec4start.substr(0, 5)})`
                                                : row.technician5_id === usuario.id_usuario &&
                                                    !!row.tec5start
                                                    ? `(${row.tec5start.substr(0, 5)})`
                                                    : row.technician6_id === usuario.id_usuario &&
                                                        !!row.tec6start
                                                        ? `(${row.tec6start.substr(0, 5)})`
                                                        : row.technician7_id === usuario.id_usuario &&
                                                            !!row.tec7start
                                                            ? `(${row.tec7start.substr(0, 5)})`
                                                            : row.technician8_id === usuario.id_usuario &&
                                                                !!row.tec8start
                                                                ? `(${row.tec8start.substr(0, 5)})`
                                                                : ""}
                                </strong>
                            </IonText>
                        </IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                        <IonLabel>MÉDICO: </IonLabel>
                        <IonText>{row.doctor_name}</IonText>
                    </IonCol>
                    {!!row.note && (
                        <IonCol size="12" className="ion-no-padding">
                            <IonLabel>OBSERVAÇÃO: </IonLabel>
                            <IonText>{row.note}</IonText>
                        </IonCol>
                    )}

                    <IonCol size="12">
                        <IonRow className="ion-align-items-end ion-margin-top">
                            <IonCol size="6">
                                <IonButton
                                    type="button"
                                    routerLink={`/prescricao?id_atendimento=${row.id}`}
                                    color="primary"
                                    expand="block"
                                >
                                    Prescrição
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonCol>

                    {row.technician1_id === usuario.id_usuario &&
                        row.tec1open === 0 &&
                        row.tec1close === 0 && (
                            <IonCol size="12" className="ion-margin-top">
                                <IonItemDivider>
                                    <IonLabel
                                        color="dark"
                                        style={{
                                            whiteSpace: "pre-line",
                                            paddingRight: 5
                                        }}
                                    >
                                        TÉCNICO 1
                                    </IonLabel>
                                </IonItemDivider>
                                <IonRow className="ion-align-items-end ion-margin-top">
                                    <IonCol size="12">
                                        <IonLabel>Nome: </IonLabel>
                                        <IonText>{row.technician1_name}</IonText>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonLabel position="stacked">Hora de Início</IonLabel>
                                        <IonInput
                                            type="time"
                                            value={tec1start}
                                            onIonChange={event =>
                                                setTec1Start(event.detail.value || "")
                                            }
                                        />
                                    </IonCol>
                                    {getType(row.proc) === "B" ? (
                                        <SearchMachineByNumber
                                            size="6"
                                            label="Home Choice"
                                            value={homeChoice}
                                            onChange={(value: Option) => setHomeChoice(value)}
                                            id={row.id}
                                            type="C"
                                        />
                                    ) : (
                                        <>
                                            <SearchMachineByNumber
                                                size="6"
                                                label="Máquina"
                                                value={machine}
                                                onChange={(value: Option) => setMachine(value)}
                                                id={row.id}
                                                type="H"
                                            />
                                            {(row.proc !== "CVVHD" && row.proc !== "CVVHDF") &&
                                                <SearchMachineByNumber
                                                    size="6"
                                                    label="Osmose"
                                                    value={reverseOsmosis}
                                                    onChange={(value: Option) => setReverseOsmosis(value)}
                                                    id={row.id}
                                                    type="T"
                                                />}

                                        </>
                                    )}
                                    <IonCol size="6">
                                        <IonLabel position="stacked">Precaução</IonLabel>
                                        <IonSelect
                                            value={precaution}
                                            onIonChange={e => setPrecaution(e.detail.value)}
                                            interface="action-sheet"
                                            okText="Ok"
                                            cancelText="Cancelar"
                                        >
                                            <IonSelectOption value="N">Não</IonSelectOption>
                                            <IonSelectOption value="C">Contato</IonSelectOption>
                                            <IonSelectOption value="R">Respiratória</IonSelectOption>
                                        </IonSelect>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Hora de Término: </IonLabel>
                                        <IonText>
                                            {!!row.tec1end && row.tec1end.substr(0, 5)}
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonButton
                                            type="button"
                                            color="primary"
                                            expand="block"
                                            onClick={() => { setShowAlert1(true) }}
                                        >
                                            Iniciar
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        )}

                    {row.technician2_id === usuario.id_usuario &&
                        row.tec2open === 0 &&
                        row.tec2close === 0 && (
                            <IonCol size="12" className="ion-margin-top">
                                <IonItemDivider>
                                    <IonLabel
                                        color="dark"
                                        style={{
                                            whiteSpace: "pre-line",
                                            paddingRight: 5
                                        }}
                                    >
                                        TÉCNICO 2
                                    </IonLabel>
                                </IonItemDivider>
                                <IonRow className="ion-align-items-end ion-margin-top">
                                    <IonCol size="12">
                                        <IonLabel>Nome: </IonLabel>
                                        <IonText>{row.technician2_name}</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Hora de Início: </IonLabel>
                                        <IonText>
                                            {!!row.tec2start && row.tec2start.substr(0, 5)}
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Hora de Término: </IonLabel>
                                        <IonText>
                                            {!!row.tec2end && row.tec2end.substr(0, 5)}
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonButton
                                            type="button"
                                            color="primary"
                                            expand="block"
                                            disabled={row.tec1close === 0}
                                            onClick={() => handleTech2Submit()}
                                        >
                                            Iniciar
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        )}

                    {row.technician3_id === usuario.id_usuario &&
                        row.tec3open === 0 &&
                        row.tec3close === 0 && (
                            <IonCol size="12" className="ion-margin-top">
                                <IonItemDivider>
                                    <IonLabel
                                        color="dark"
                                        style={{
                                            whiteSpace: "pre-line",
                                            paddingRight: 5
                                        }}
                                    >
                                        TÉCNICO 3
                                    </IonLabel>
                                </IonItemDivider>
                                <IonRow className="ion-align-items-end ion-margin-top">
                                    <IonCol size="12">
                                        <IonLabel>Nome: </IonLabel>
                                        <IonText>{row.technician3_name}</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Hora de Início: </IonLabel>
                                        <IonText>
                                            {!!row.tec3start && row.tec3start.substr(0, 5)}
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Hora de Término: </IonLabel>
                                        <IonText>
                                            {!!row.tec3end && row.tec3end.substr(0, 5)}
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonButton
                                            type="button"
                                            color="primary"
                                            expand="block"
                                            disabled={row.tec2close === 0}
                                            onClick={() => handleTech3Submit()}
                                        >
                                            Iniciar
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        )}

                    {row.technician4_id === usuario.id_usuario &&
                        row.tec4open === 0 &&
                        row.tec4close === 0 && (
                            <IonCol size="12" className="ion-margin-top">
                                <IonItemDivider>
                                    <IonLabel
                                        color="dark"
                                        style={{
                                            whiteSpace: "pre-line",
                                            paddingRight: 5
                                        }}
                                    >
                                        TÉCNICO 4
                                    </IonLabel>
                                </IonItemDivider>
                                <IonRow className="ion-align-items-end ion-margin-top">
                                    <IonCol size="12">
                                        <IonLabel>Nome: </IonLabel>
                                        <IonText>{row.technician4_name}</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Hora de Início: </IonLabel>
                                        <IonText>
                                            {!!row.tec4start && row.tec4start.substr(0, 5)}
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Hora de Término: </IonLabel>
                                        <IonText>
                                            {!!row.tec4end && row.tec4end.substr(0, 5)}
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonButton
                                            type="button"
                                            color="primary"
                                            expand="block"
                                            disabled={row.tec3close === 0}
                                            onClick={() => handleTech4Submit()}
                                        >
                                            Iniciar
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        )}

                    {row.technician5_id === usuario.id_usuario &&
                        row.tec5open === 0 &&
                        row.tec5close === 0 && (
                            <IonCol size="12" className="ion-margin-top">
                                <IonItemDivider>
                                    <IonLabel
                                        color="dark"
                                        style={{
                                            whiteSpace: "pre-line",
                                            paddingRight: 5
                                        }}
                                    >
                                        TÉCNICO 5
                                    </IonLabel>
                                </IonItemDivider>
                                <IonRow className="ion-align-items-end ion-margin-top">
                                    <IonCol size="12">
                                        <IonLabel>Nome: </IonLabel>
                                        <IonText>{row.technician5_name}</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Hora de Início: </IonLabel>
                                        <IonText>
                                            {!!row.tec5start && row.tec5start.substr(0, 5)}
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Hora de Término: </IonLabel>
                                        <IonText>
                                            {!!row.tec5end && row.tec5end.substr(0, 5)}
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonButton
                                            type="button"
                                            color="primary"
                                            expand="block"
                                            disabled={row.tec4close === 0}
                                            onClick={() => handleTech5Submit()}
                                        >
                                            Iniciar
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        )}

                    {row.technician6_id === usuario.id_usuario &&
                        row.tec6open === 0 &&
                        row.tec6close === 0 && (
                            <IonCol size="12" className="ion-margin-top">
                                <IonItemDivider>
                                    <IonLabel
                                        color="dark"
                                        style={{
                                            whiteSpace: "pre-line",
                                            paddingRight: 5
                                        }}
                                    >
                                        TÉCNICO 6
                                    </IonLabel>
                                </IonItemDivider>
                                <IonRow className="ion-align-items-end ion-margin-top">
                                    <IonCol size="12">
                                        <IonLabel>Nome: </IonLabel>
                                        <IonText>{row.technician6_name}</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Hora de Início: </IonLabel>
                                        <IonText>
                                            {!!row.tec6start && row.tec6start.substr(0, 5)}
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Hora de Término: </IonLabel>
                                        <IonText>
                                            {!!row.tec6end && row.tec6end.substr(0, 5)}
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonButton
                                            type="button"
                                            color="primary"
                                            expand="block"
                                            disabled={row.tec5close === 0}
                                            onClick={() => handleTech6Submit()}
                                        >
                                            Iniciar
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        )}

                    {row.technician7_id === usuario.id_usuario &&
                        row.tec7open === 0 &&
                        row.tec7close === 0 && (
                            <IonCol size="12" className="ion-margin-top">
                                <IonItemDivider>
                                    <IonLabel
                                        color="dark"
                                        style={{
                                            whiteSpace: "pre-line",
                                            paddingRight: 5
                                        }}
                                    >
                                        TÉCNICO 7
                                    </IonLabel>
                                </IonItemDivider>
                                <IonRow className="ion-align-items-end ion-margin-top">
                                    <IonCol size="12">
                                        <IonLabel>Nome: </IonLabel>
                                        <IonText>{row.technician7_name}</IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Hora de Início: </IonLabel>
                                        <IonText>
                                            {!!row.tec7start && row.tec7start.substr(0, 5)}
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonLabel>Hora de Término: </IonLabel>
                                        <IonText>
                                            {!!row.tec7end && row.tec7end.substr(0, 5)}
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonButton
                                            type="button"
                                            color="primary"
                                            expand="block"
                                            disabled={row.tec6close === 0}
                                            onClick={() => handleTech7Submit()}
                                        >
                                            Iniciar
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        )}

                    {row.technician8_id === usuario.id_usuario && (
                        <IonCol size="12" className="ion-margin-top">
                            <IonItemDivider>
                                <IonLabel
                                    color="dark"
                                    style={{
                                        whiteSpace: "pre-line",
                                        paddingRight: 5
                                    }}
                                >
                                    TÉCNICO 8
                                </IonLabel>
                            </IonItemDivider>
                            <IonRow className="ion-align-items-end ion-margin-top">
                                <IonCol size="12">
                                    <IonLabel>Nome: </IonLabel>
                                    <IonText>{row.technician8_name}</IonText>
                                </IonCol>
                                <IonCol size="12">
                                    <IonLabel>Hora de Início: </IonLabel>
                                    <IonText>
                                        {!!row.tec8start && row.tec8start.substr(0, 5)}
                                    </IonText>
                                </IonCol>
                                <IonCol size="12">
                                    <IonLabel>Hora de Término: </IonLabel>
                                    <IonText>
                                        {!!row.tec8end && row.tec8end.substr(0, 5)}
                                    </IonText>
                                </IonCol>
                                <IonCol size="12">
                                    <IonButton
                                        type="button"
                                        color="primary"
                                        expand="block"
                                        disabled={row.tec7close === 0}
                                        onClick={() => handleTech8Submit()}
                                    >
                                        Iniciar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    )}
                </IonRow>
            </IonCardContent>
            <IonAlert
                isOpen={showAlert1}
                onDidDismiss={() => setShowAlert1(false)}
                message={
                    "Confirmo que realizei a conferência dos insumos e equipamentos e estes se encontram em perfeita ordem para início e conclusão deste procedimento dialítico"
                }
                buttons={[
                    "Não",
                    {
                        text: "Sim",
                        handler: () => handleTech1Submit()
                    }
                ]}
            />
        </IonCard>
    )
}

const TecnicoAgendados = () => {
    const { usuario } = useAuth();
    const { getContadorAtendimentos } = useNotifications();
    const { showLoading, hideLoading } = useLoading();

    const [rows, setRows] = useState<Row[]>([])

    const getList = async () => {
        showLoading()
        await api.get(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/agendados`).then(response => {
            if (!!response.data) setRows(response.data)
        })
        getContadorAtendimentos()
        hideLoading()
    }

    const RefreshIcon = () => (
        <IonItem lines="none">
            <IonIcon
                icon={refresh}
                size="large"
                color="light"
                onClick={() => getList()}
            />
        </IonItem>
    )

    useIonViewDidEnter(() => {
        getList()
    })

    return (
        <Main title="Agendados" end={<RefreshIcon />}>
            {rows.map(row => (
                <Atendimento
                    key={row.id}
                    row={row}
                    getList={getList}
                />
            ))}
        </Main>
    )
}

export default TecnicoAgendados
