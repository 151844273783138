import { useEffect, useRef, useState } from "react"
import { IonButton, IonCol, IonGrid, IonRow } from "@ionic/react"

import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { useLoading } from "../../../../hooks/loading";
import { useToast } from "../../../../hooks/toast";
import { useValidation } from "../../../../hooks/validation";

import api from "../../../../services/api";

import Select from "../../../../components/Select";
import Textarea from "../../../../components/Textarea";

interface Props {
    id_usuario: number;
    id_medico: number;
    id_atendimento: number;
    situacao: string;

    passagem_plantao: string;
    destino: string;
    periodicidade_prevista: string;
}

interface FormValues {
    passagem_plantao: string;
    destino: string;
    periodicidade_prevista: string;
}

const MedicoPassagemPlantao = ({
    id_usuario,
    id_medico,
    id_atendimento,
    situacao,
    passagem_plantao,
    destino,
    periodicidade_prevista
}: Props) => {
    const { showSaveMessage, hideSaveMessage } = useLoading();
    const { successToast } = useToast();
    const { getValidationErrors } = useValidation();

    const formRef = useRef<FormHandles>(null);
    const [edit, setEdit] = useState(false)

    const handleSubmit: SubmitHandler = async (values: FormValues) => {
        showSaveMessage()
        await api
            .put(`medico/procedimentos/${id_atendimento}`, values)
            .then(response => {
                if (!!response.data) {
                    successToast();
                    setEdit(false)
                }
            })
            .catch(error => {
                getValidationErrors(error.response);
            })
        hideSaveMessage()
    }

    useEffect(() => {
        formRef?.current?.setData({
            passagem_plantao,
            destino,
            periodicidade_prevista
        })
    }, [destino, passagem_plantao, periodicidade_prevista])

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <IonGrid>
                <IonRow className="ion-align-items-end">
                    <Textarea
                        size="12"
                        label="Passagem de Plantão"
                        name="passagem_plantao"
                        rows={3}
                        disabled={!edit || id_usuario !== id_medico || !["PENDENTE", "AGENDADO", "ANDAMENTO"].includes(situacao)}
                    />
                    <Select
                        size="12"
                        label="Destino"
                        name="destino"
                        values={[
                            { value: "Em Tratamento", label: "EM TRATAMENTO" },
                            { value: "Alta Nefro", label: "ALTA NEFRO" },
                            { value: "Alta Hospitalar", label: "ALTA HOSPITALAR" },
                            { value: "Alta Encaminhado para o Programa", label: "ALTA ENCAMINHADO PARA O PROGRAMA" },
                            { value: "Transferência", label: "TRANSFERÊNCIA" },
                            { value: "Óbito", label: "ÓBITO" },
                        ]}
                        disabled={!edit}
                    />
                    <Select
                        size="12"
                        label="Periodicidade Prevista"
                        name="periodicidade_prevista"
                        values={[
                            { value: "Dias Alternados", label: "DIAS ALTERNADOS" },
                            { value: "Diário", label: "DIÁRIO" },
                            { value: "Paliativo", label: "PALIATIVO" },
                        ]}
                        disabled={!edit}
                    />
                </IonRow>
                <IonRow className="ion-margin-top">
                    {edit ?
                        <>
                            <IonCol size="6">
                                <IonButton type="submit" color="primary" expand="block">
                                    Salvar
                                </IonButton>
                            </IonCol>
                            <IonCol size="6">
                                <IonButton type="button" color="dark" expand="block"
                                    onClick={() => setEdit(false)}
                                >
                                    Cancelar
                                </IonButton>
                            </IonCol>
                        </>
                        :
                        <IonCol size="6">
                            <IonButton type="button" color="dark" expand="block"
                                onClick={() => setEdit(true)}
                            >
                                Editar
                            </IonButton>
                        </IonCol>
                    }
                </IonRow>
            </IonGrid>
        </Form>
    )
}

export default MedicoPassagemPlantao
