import { useEffect, useRef, useState } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonLabel,
    IonRow,
    IonText
} from "@ionic/react";
import { format, subDays } from "date-fns";

import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { useAuth } from "../../hooks/auth";
import { useLoading } from "../../hooks/loading";
import { useToast } from "../../hooks/toast";
import { useValidation } from "../../hooks/validation";

import api from "../../services/api";

import Main from "../../components/Main";

import Input from "../../components/Input";
import ReactSelect from "../../components/ReactSelect";
import Textarea from "../../components/Textarea";

import Formulario from './Formulario'

const sessionName = process.env.REACT_APP_SESSION_NAME;

export interface Row {
    id: number,
    id_paciente: number;
    nome_paciente: string;
    data: string;
    localizacao: string;
    leito: string;
    diagnostico: string
    diagnostico_causa: string[]
    doencas_pre_existentes: string[]
    conservador: string;
    relatorios_medicos: {
        data: string;
        descricao: string;
    }[];
    destino: string;
}

const Prontuario = () => {
    const { usuario } = useAuth();
    const { showLoading, hideLoading, showSaveMessage, hideSaveMessage } = useLoading();
    const { getValidationErrors } = useValidation();
    const { successToast } = useToast();

    const formRef = useRef<FormHandles>(null);
    const consultRef = useRef<FormHandles>(null);

    const [lastUpdate, setLastUpdate] = useState<Date | null>(null);
    const [rows, setRows] = useState<Row[]>([]);
    const [scroll, setScroll] = useState(new Date());

    const defaultValues = () => {
        return {
            data: format(new Date(), "yyyy-MM-dd")
        }
    }

    const consultValues = () => {
        return JSON.parse(
            `${localStorage.getItem(
                `@${sessionName}:Prontuario`
            ) || "{}"
            }`
        )
    }

    const handleSubmit: SubmitHandler = async data => {
        showSaveMessage();
        await api
            .post("medico/prontuario", data)
            .then(_response => {
                successToast()
                formRef.current?.reset(defaultValues())
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
        hideSaveMessage()
    }

    const handleConsult: SubmitHandler = async (values) => {
        localStorage.setItem(
            `@${sessionName}:Prontuario`,
            JSON.stringify(values)
        )

        showLoading();
        await api
            .get("prontuario", {
                params: {
                    id_hospital: values?.id_hospital?.value
                }
            })
            .then(response => {
                setRows(response.data)
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
        hideLoading();
    }

    useEffect(() => {
        if (!!lastUpdate) {
            consultRef?.current?.submitForm();
        }
    }, [lastUpdate])

    const selecionarPaciente = (id_paciente: number, nome_paciente: string) => {
        formRef.current?.setFieldValue("id_paciente", {
            value: id_paciente,
            label: nome_paciente
        })
        setScroll(new Date())
    }

    return (
        <Main title="Prontuário" scroll={scroll} back>
            {usuario.tipo_perfil === "MED" &&
                <IonCard>
                    <IonCardContent>
                        <IonGrid>
                            <Form
                                ref={formRef}
                                initialData={defaultValues()}
                                onSubmit={handleSubmit}
                            >
                                <IonRow className="ion-align-items-end">
                                    <ReactSelect
                                        label="Paciente"
                                        name="id_paciente"
                                        url="/select/paciente"
                                        params={{
                                            obito: "N",
                                            data_nascimento: "S",
                                            matricula: "S"
                                        }}
                                        required
                                        simple
                                    />
                                    <Input
                                        label="Data"
                                        name="data"
                                        type="date"
                                        min={format(subDays(new Date(), 1), "yyyy-MM-dd")}
                                        max={format(new Date(), "yyyy-MM-dd")}
                                        required
                                    />
                                    <Textarea
                                        label="Relatório Médico"
                                        name="relatorio_medico"
                                        rows={5}
                                        required
                                    />
                                    <IonCol className="ion-margin-top">
                                        <IonButton type="submit" color="primary" expand="block">
                                            Salvar
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </Form>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
            }
            <IonCard>
                <IonCardContent>
                    <Form
                        ref={consultRef}
                        initialData={consultValues()}
                        onSubmit={handleConsult}
                    >
                        <IonGrid>
                            <IonRow className="ion-align-items-end">
                                <ReactSelect
                                    label="Hospital"
                                    name="id_hospital"
                                    url="/select/hospital"
                                    params={{ status: "A" }}
                                    required
                                />
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="12">
                                    <IonButton type="submit" color="primary" expand="block">
                                        Consultar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </Form>
                </IonCardContent>
            </IonCard>
            {rows.length > 0 && (
                <>
                    {rows.map(row => {
                        return (
                            <IonCard key={row.id_paciente}>
                                <IonCardHeader>
                                    <IonCardTitle>
                                        <IonRow>
                                            <IonCol size="12">{row.nome_paciente}</IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonLabel>DATA DO PROCEDIMENTO: </IonLabel>
                                            <IonText>{row.data}</IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonLabel>LOCALIZAÇÃO: </IonLabel>
                                            <IonText>{row.localizacao}</IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonLabel>DIAGNÓSTICO: </IonLabel>
                                            <IonText>{row.diagnostico}</IonText>
                                        </IonCol>
                                        {row.diagnostico_causa.length > 0 &&
                                            <IonCol
                                                size="12"
                                            >
                                                <IonLabel>CAUSA: </IonLabel>

                                                {row.diagnostico_causa.map((causa, index) => (
                                                    <span key={index}>
                                                        <br />
                                                        <IonText>{causa}</IonText>
                                                    </span>
                                                ))}
                                            </IonCol>
                                        }
                                        {row.doencas_pre_existentes.length > 0 &&
                                            <IonCol
                                                size="12"
                                            >
                                                <IonLabel>DOENÇAS PRÉ EXISTENTES: </IonLabel>
                                                {row.doencas_pre_existentes.map(
                                                    (doencas_pre_existente, index) => (
                                                        <span key={index}>
                                                            <br />
                                                            <IonText>{doencas_pre_existente}</IonText>
                                                        </span>
                                                    )
                                                )}
                                            </IonCol>
                                        }
                                        <IonCol size="12">
                                            <IonLabel>LEITO: </IonLabel>
                                            <IonText>{row.leito}</IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonLabel>CONSERVADOR: </IonLabel>
                                            <IonText>
                                                {row.conservador}
                                            </IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonLabel>RELATÓRIOS: </IonLabel>
                                            {row.relatorios_medicos.length > 0 && (
                                                <IonRow>
                                                    {row.relatorios_medicos.map((relatorio_medico, index) => {
                                                        return (
                                                            <IonCol size="12" key={index}>
                                                                <IonText>
                                                                    <strong>{relatorio_medico.data}</strong> {" - "}
                                                                    {relatorio_medico.descricao}
                                                                </IonText>
                                                            </IonCol>
                                                        )
                                                    })}
                                                </IonRow>
                                            )}
                                        </IonCol>
                                    </IonRow>
                                    {usuario.tipo_perfil === "MED" &&
                                        <IonRow>
                                            <IonCol size="12">
                                                <Formulario
                                                    id_atendimento={row.id}
                                                    destino={row.destino}
                                                    setLastUpdate={setLastUpdate}
                                                    setRows={setRows}
                                                />
                                            </IonCol>
                                            <IonCol size="6">
                                                <IonButton
                                                    type="button"
                                                    onClick={() =>
                                                        selecionarPaciente(row.id_paciente, row.nome_paciente)
                                                    }
                                                    color="primary"
                                                    expand="block"
                                                >
                                                    Prescrever
                                                </IonButton>
                                            </IonCol>
                                            <IonCol size="6">
                                                <IonButton
                                                    type="button"
                                                    routerLink={`/paciente-relatorio/${row.id_paciente}/${row.nome_paciente}`}
                                                    color="tertiary"
                                                    expand="block"
                                                >
                                                    Relatórios
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>}

                                </IonCardContent>
                            </IonCard>
                        )
                    })}
                </>
            )}
        </Main>
    )
}

export default Prontuario
