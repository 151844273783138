import { useRef, useState } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonLabel,
    IonRow,
    IonText
} from "@ionic/react";
import { format, parseISO } from "date-fns";
import pt_BR from "date-fns/locale/pt-BR";
import Calendar from "react-calendar";

import { useToast } from "../../../hooks/toast";
import { useValidation } from "../../../hooks/validation";

import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import api from "../../../services/api";
import { AxiosRequestConfig } from "axios";

import Main from "../../../components/Main";
import Card from "../../../components/Card";
import Input from "../../../components/Input";
import ReactSelect from "../../../components/ReactSelect";

const sessionName = process.env.REACT_APP_SESSION_NAME

interface Lines {
    id: number
    technician: string
    date: string
    created_at: string
    edit: "Y" | "N"
    delete: "Y" | "N"
}

const EnfermagemTecnicoExtra = () => {
    const [scroll, setScroll] = useState(new Date())
    const { getValidationErrors } = useValidation()
    const { successToast } = useToast()
    const formRef = useRef<FormHandles>(null)
    const [id, setId] = useState<number>(0)
    const consultRef = useRef<FormHandles>(null)
    const [month, setMonth] = useState<string>("")
    const [total, setTotal] = useState<number>(0)
    const [days, setDays] = useState<string[]>([])
    const [rows, setRows] = useState<Lines[]>([])

    const consultDefaultValues = () => {
        return JSON.parse(
            `${localStorage.getItem(
                `@${sessionName}:NurseTechniciansExtras_ConsultForm`
            ) || "{}"
            }`
        )
    }

    const getList = async () => {
        const data = consultDefaultValues()

        await api
            .get("enfermagem/tecnico-extra", {
                params: {
                    technician_id: data.technician?.value,
                    month: data.month
                }
            })
            .then(response => {
                const { month, total, days, data } = response.data

                if (month) {
                    setMonth(month)
                }

                if (total) {
                    setTotal(total)
                }

                if (days) {
                    setDays(days)
                }

                if (data) {
                    setRows(data)
                }
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
    }

    const handleReset = () => {
        formRef.current?.reset()
        setId(0)
    }

    const handleSubmit: SubmitHandler = async data => {
        let method: AxiosRequestConfig["method"] = "post"
        if (id > 0) {
            method = "put"
        }
        await api({
            method: method,
            url: `enfermagem/tecnico-extra${id > 0 ? "/" + id : ""}`,
            data: data
        })
            .then(_response => {
                successToast()
                handleReset()
                if (total > 0) {
                    getList()
                }
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
    }

    const handleConsult: SubmitHandler = async data => {
        localStorage.setItem(
            `@${sessionName}:NurseTechniciansExtras_ConsultForm`,
            JSON.stringify(data)
        )
        getList()
    }

    const handleEdit = async (id: number) => {
        await api
            .get(`enfermagem/tecnico-extra/${id}`)
            .then(response => {
                setId(id)
                formRef.current?.setData(response.data)
                setScroll(new Date())
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
    }

    const handleDelete = async (id: number) => {
        await api.delete(`enfermagem/tecnico-extra/${id}`).then(response => {
            successToast()
            getList()
        })
    }

    return (
        <Main title="Técnicos (Extras)" scroll={scroll}>
            <Card>
                <IonCardContent>
                    <IonGrid>
                        <Form ref={formRef} onSubmit={handleSubmit}>
                            <IonRow className="ion-align-items-end">
                                <ReactSelect
                                    label="Técnico"
                                    name="technician_id"
                                    url="/enfermagem/select/tecnico"
                                    params={{ status: "A" }}
                                    required
                                    simple
                                />
                                <Input
                                    name="date"
                                    label="Data"
                                    type="date"
                                    max={format(new Date(), "yyyy-MM-dd")}
                                    required
                                />
                                <IonCol size="6" className="ion-margin-top">
                                    <IonButton type="submit" color="primary" expand="block">
                                        Salvar
                                    </IonButton>
                                </IonCol>
                                {id > 0 && (
                                    <IonCol size="6" className="ion-margin-top">
                                        <IonButton
                                            type="button"
                                            color="medium"
                                            expand="block"
                                            onClick={() => handleReset()}
                                        >
                                            Cancelar
                                        </IonButton>
                                    </IonCol>
                                )}
                            </IonRow>
                        </Form>
                    </IonGrid>
                </IonCardContent>
            </Card>
            <IonCard>
                <IonCardContent>
                    <IonGrid>
                        <Form
                            ref={consultRef}
                            initialData={consultDefaultValues()}
                            onSubmit={handleConsult}
                        >
                            <IonRow className="ion-align-items-end">
                                <ReactSelect
                                    label="Técnico"
                                    name="technician"
                                    url="/enfermagem/select/tecnico"
                                    params={{ status: "A" }}
                                    required
                                />
                                <Input
                                    name="month"
                                    label="Mês"
                                    type="month"
                                    max={format(new Date(), "yyyy-MM")}
                                />
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="6">
                                    <IonButton type="submit" color="primary" expand="block">
                                        Consultar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </Form>
                    </IonGrid>
                </IonCardContent>
            </IonCard>
            {rows.length > 0 && (
                <>
                    <IonCard>
                        <IonCardContent>
                            <IonRow>
                                <IonCol size="12">
                                    <IonLabel>EXTRAS: </IonLabel>
                                    <IonText>{total}</IonText>
                                </IonCol>
                                {days.length > 0 && (
                                    <IonCol size="12">
                                        <IonLabel>
                                            {format(parseISO(month + '-01'), "MMMM 'de' yyyy", {
                                                locale: pt_BR
                                            })}
                                        </IonLabel>
                                        <Calendar
                                            locale="pt-BR"
                                            onChange={() => { }}
                                            activeStartDate={parseISO(month + "-01")}
                                            tileClassName={(props: any) => {
                                                const date = props.date
                                                if (days.includes(format(date, "yyyy-MM-dd"))) {
                                                    return "react-calendar__tile--active"
                                                } else {
                                                    return ""
                                                }
                                            }}
                                            showNavigation={false}
                                            showNeighboringMonth={false}
                                            view="month"
                                        />
                                    </IonCol>
                                )}
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                    {rows.map(lines => {
                        return (
                            <IonCard key={lines.id}>
                                <IonCardHeader>
                                    <IonCardTitle>
                                        <IonRow>
                                            <IonCol size="12">
                                                <IonLabel>DATA: </IonLabel>
                                                <IonText>
                                                    {format(parseISO(lines.date), "dd/MM/yyyy")}
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonLabel>TÉCNICO: </IonLabel>
                                            <IonText>{lines.technician}</IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonLabel>CRIADO EM: </IonLabel>
                                            <IonText>
                                                {format(parseISO(lines.created_at), "dd/MM/yyyy")}
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        {lines.edit === "Y" && (
                                            <IonCol size="6">
                                                <IonButton
                                                    type="button"
                                                    color="primary"
                                                    expand="block"
                                                    onClick={() => handleEdit(lines.id)}
                                                >
                                                    Editar
                                                </IonButton>
                                            </IonCol>
                                        )}
                                        {lines.delete === "Y" && (
                                            <IonCol size="6">
                                                <IonButton
                                                    type="button"
                                                    color="danger"
                                                    expand="block"
                                                    onClick={() => handleDelete(lines.id)}
                                                >
                                                    Deletar
                                                </IonButton>
                                            </IonCol>
                                        )}
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        )
                    })}
                </>
            )}
        </Main>
    )
}

export default EnfermagemTecnicoExtra
