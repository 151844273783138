import { useCallback, useEffect, useRef } from "react"
import { IonCol } from "@ionic/react"
import { useField } from "@unform/core"

import Label from "../Label"
import { InputMask } from "./styles"

interface Props {
    size?: string
    label?: string
    name: string
    mask: string
    required?: boolean
    disabled?: boolean
    hidden?: boolean
}

const ReactInputMask = ({
    size,
    label,
    name,
    mask,
    required,
    disabled,
    hidden
}: Props) => {
    const inputRef = useRef<any>(null)
    const { fieldName, defaultValue, registerField } = useField(name)

    const fixValue = useCallback(
        (value: string) => {
            if (mask === '99:99') {
                const time = value.split(':')

                if (time.length === 2) {
                    const hours = ('00' + parseInt(time[0].replace('_', ''))).slice(-2)
                    const minutes = ('00' + parseInt(time[1].replace('_', '0'))).slice(-2)

                    return `${hours}:${minutes}`
                } else {
                    return value
                }
            } else {
                return value
            }
        },
        [mask]
    )

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: ref => {
                if (!!ref.value) {
                    return fixValue(ref.value)
                }
            },
            setValue: (ref, value: any) => {
                if (!!value) {
                    ref.setInputValue(fixValue(value))
                }
            },
            clearValue: (ref, value) => {
                if (value) {
                    ref.value = value
                } else {
                    ref.value = ''
                }
            }
        })
    }, [fieldName, fixValue, registerField])

    const onBlur = () => {
        const value = fixValue(inputRef?.current?.getInputValue())
        inputRef?.current?.setInputValue(value)
    }

    return (
        <IonCol size={size || "12"} style={!hidden ? {} : { display: "none" }} >
            {!!label && !hidden && <Label required={required}>{label}</Label>}
            <InputMask
                ref={inputRef}
                defaultValue={defaultValue}
                mask={mask}
                id={name}
                name={name}
                disabled={disabled}
                hidden={hidden}
                onBlur={onBlur}
            />
        </IonCol>
    )
}

export default ReactInputMask
