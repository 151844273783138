import { useEffect } from "react";
import { IonApp, IonSplitPane, isPlatform, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { createBrowserHistory } from "history";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* React Calendar */
import "react-calendar/dist/Calendar.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/global.css";

import { useAuth } from "./hooks/auth";

import Menu from "./components/Menu";
import Routes from "./routes";
import Login from "./pages/Login";
import Senha from "./pages/Senha"
import Assinatura from "./pages/Assinatura"

export const history = createBrowserHistory({})

setupIonicReact();

const App = () => {
  const { usuario, validateToken } = useAuth();
  const platform = isPlatform("mobileweb") || isPlatform("desktop");

  useEffect(() => {
    validateToken()
  }, [validateToken])

  if (!!usuario) {
    if (usuario.senha_temp === "S") {
      return <Senha />
    } else if (
      ["MED", "ENF", "TEC"].includes(usuario.tipo_perfil) &&
      usuario.assinatura !== "S" &&
      !platform
    ) {
      return <Assinatura />
    } else {
      return (
        <IonApp>
          <IonReactRouter history={history}>
            <IonSplitPane contentId="main">
              <Menu />
              <Routes />
            </IonSplitPane>
          </IonReactRouter>
        </IonApp>
      )
    }
  } else {
    return <Login />
  }
}

export default App;
