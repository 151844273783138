import { IonCard, IonCardContent, IonCol, IonGrid, IonLabel, IonRow, IonText, useIonViewDidEnter, useIonViewWillLeave } from "@ionic/react";
import { useState } from "react";
import { useParams } from "react-router";

import api from "../../../services/api";

import Main from "../../../components/Main";

interface Produto {
    id: number;
    nome: string;
    qtd: number;
}

interface ParamTypes {
    id: string;
    lote: string;
}

const MotoristaProduto = () => {
    const { id, lote } = useParams<ParamTypes>();
    const [scroll, setScroll] = useState(new Date());

    const [produtos, setProdutos] = useState<Produto[]>([]);

    const getData = async (id: string) => {
        api.get(`motorista/produto/${id}`).then(response => {
            const { data } = response;
            if (!!data)
                setProdutos(response.data)
        })
    }

    useIonViewDidEnter(() => {
        setProdutos([]);
        setScroll(new Date());
        getData(id);
    }, [id])

    useIonViewWillLeave(() => {
        setProdutos([])
    }, [id])

    return (
        <Main title={`Lote: ${lote}`} scroll={scroll} back>
            <IonGrid>
                <IonRow>
                    {produtos.map(produto => (
                        <IonCol size="12" key={produto.id}>
                            <IonCard>
                                <IonCardContent>
                                    <IonLabel>PRODUTO: </IonLabel>
                                    <IonText>{produto.nome}</IonText>
                                    <br />
                                    <IonLabel>QUANTIDADE: </IonLabel>
                                    <IonText>{produto.qtd}</IonText>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    ))}
                </IonRow>
            </IonGrid>
        </Main >
    )
}

export default MotoristaProduto;