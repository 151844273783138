import React from "react";
import { IonRouterOutlet } from "@ionic/react";

import Route from "./Route";

import Spinner from "../components/Spinner";
import Main from "../components/Main";

// Médico
import MedicoPrescricao from "../pages/Medico/Prescricao";
import MedicoPaciente from "../pages/Medico/Paciente";
import MedicoProcedimentos from "../pages/Medico/Procedimentos";
import MedicoRegistrarPlantao from "../pages/Medico/RegistrarPlantao";
import MedicoEmTratamento from "../pages/Medico/EmTratamento";
import MedicoCateterAvulso from "../pages/Medico/CateterAvulso";

// Enfermagem
import EnfermagemAtendimento from "../pages/Enfermagem/Atendimento";
import EnfermagemTecnicoFalta from "../pages/Enfermagem/TecnicoFalta";
import EnfermagemTecnicoExtra from "../pages/Enfermagem/TecnicoExtra";

// Técnico
import TecnicoAgendados from "../pages/Tecnico/Agendados";
import TecnicoEmAndamento from "../pages/Tecnico/EmAndamento";
import TecnicoAnotacao from "../pages/Tecnico/Anotacao";
import TecnicoEvolucao from "../pages/Tecnico/Evolucao";
import TecnicoEstoque from "../pages/Tecnico/Estoque";
import TecnicoAssumirAtendimento from "../pages/Tecnico/AssumirAtendimento";
import TecnicoPontuacao from "../pages/Tecnico/Pontuacao";

// Motorista

import MotoristaMaquinaMovimento from '../pages/Motorista/MaquinaMovimento'
import MotoristaMaquinaChecklist from '../pages/Motorista/MaquinaChecklist'
import MotoristaProdutoMovimento from "../pages/Motorista/ProdutoMovimento";
import MotoristaProduto from "../pages/Motorista/Produto";

// Administrativo
import AdministrativoIndicadoresDialise from "../pages/Administrativo/IndicadoresDialise";
import AdministrativoIndicadoresHospital from "../pages/Administrativo/IndicadoresHospital";

// Compartilhado
import Prescricao from "../pages/Prescricao";
import Anotacao from "../pages/Anotacao";
import Evolucao from "../pages/Evolucao";
import Prontuario from "../pages/Prontuario";
import PacienteRelatorio from "../pages/PacienteRelatorio";
import FolhaDialise from "../pages/FolhaDialise";
import Notificacao from "../pages/Notificacao";
import AbrirOS from "../pages/AbrirOS";
import Usuario from "../pages/Usuario";
import Senha from "../pages/Senha";

const Routes: React.FC = () => {
    return (
        <IonRouterOutlet id="main">
            <Route path="/" component={Spinner} exact />

            {/* Médico */}
            <Route
                path="/medico/prescricao"
                component={MedicoPrescricao}
                Medico
            />
            <Route
                path="/medico/paciente"
                component={MedicoPaciente}
                Medico
            />
            <Route
                path="/medico/procedimentos"
                component={MedicoProcedimentos}
                Medico
            />
            <Route
                path="/medico/registrar-plantao"
                component={MedicoRegistrarPlantao}
                Medico
            />
            <Route
                path="/medico/em-tratamento"
                component={MedicoEmTratamento}
                Medico
            />
            <Route
                path="/medico/cateter-avulso"
                component={MedicoCateterAvulso}
                Medico
                Enfermagem
                Tecnico
            />

            {/* Enfermagem */}
            <Route
                path="/enfermagem/atendimento"
                component={EnfermagemAtendimento}
                Enfermagem
                Tecnico
            />
            <Route
                path="/enfermagem/tecnico-falta"
                component={EnfermagemTecnicoFalta}
                Enfermagem
                Tecnico
            />
            <Route
                path="/enfermagem/tecnico-extra"
                component={EnfermagemTecnicoExtra}
                Enfermagem
                Tecnico
            />

            {/* Técnico */}
            <Route
                path="/tecnico/agendados"
                component={TecnicoAgendados}
                Enfermagem
                Tecnico
            />
            <Route
                path="/tecnico/em-andamento"
                component={TecnicoEmAndamento}
                Enfermagem
                Tecnico
            />
            <Route
                path="/tecnico/anotacao/:assistanceId/:patientName/:procName"
                component={TecnicoAnotacao}
                Enfermagem
                Tecnico
            />
            <Route
                path="/tecnico/evolucao/:assistanceId/:patientName"
                component={TecnicoEvolucao}
                Enfermagem
                Tecnico
            />
            <Route
                path="/tecnico/estoque/:id_atendimento/:nome_paciente"
                component={TecnicoEstoque}
                Enfermagem
                Tecnico
            />
            <Route
                path="/tecnico/assumir-atendimento"
                component={TecnicoAssumirAtendimento}
                Enfermagem
                Tecnico
            />
            <Route
                path="/tecnico/pontuacao"
                component={TecnicoPontuacao}
                Enfermagem
                Tecnico
            />

            {/* Motorista */}
            <Route
                path="/motorista/maquina-movimento"
                component={MotoristaMaquinaMovimento}
                Motorista
            />
            <Route
                path="/motorista/maquina-checklist/:id/:serial"
                component={MotoristaMaquinaChecklist}
                Motorista
            />
            <Route
                path="/motorista/produto-movimento"
                component={MotoristaProdutoMovimento}
                Motorista
            />
            <Route
                path="/motorista/produto/:id/:lote"
                component={MotoristaProduto}
                Motorista
            />

            {/* Administrativo */}
            <Route
                path="/administrativo/indicadores-dialise"
                component={AdministrativoIndicadoresDialise}
                Administrativo
            />
            <Route
                path="/administrativo/indicadores-hospital"
                component={AdministrativoIndicadoresHospital}
                Administrativo
            />

            {/* Compartilhado */}
            <Route
                path="/prescricao"
                component={Prescricao}
                Medico
                Enfermagem
                Tecnico
            />
            <Route
                path="/anotacao/:assistanceId/:procName"
                component={Anotacao}
                Medico
                Enfermagem
                Tecnico
            />
            <Route
                path="/evolucao/:assistanceId"
                component={Evolucao}
                Medico
                Enfermagem
                Tecnico
            />
            <Route
                path="/prontuario"
                component={Prontuario}
                Medico
                Enfermagem
                Tecnico
            />
            <Route
                path="/paciente-relatorio/:idPaciente/:nomePaciente"
                component={PacienteRelatorio}
                Medico
                Enfermagem
                Tecnico
            />
            <Route
                path="/folha-dialise"
                component={FolhaDialise}
                Medico
                Enfermagem
                Tecnico
            />
            <Route
                path="/notificacao"
                component={Notificacao}
                Medico
                Enfermagem
                Tecnico
            />
            <Route
                path="/abrir-os"
                component={AbrirOS}
                Medico
                Enfermagem
                Tecnico
            />
            <Route
                path="/usuario"
                component={Usuario}
                Medico
                Enfermagem
                Tecnico
                Motorista
                Administrativo
            />
            <Route
                path="/senha"
                component={() => <Main title="Alterar Senha"><Senha /></Main>}
                Medico
                Enfermagem
                Tecnico
                Motorista
                Administrativo
            />
        </IonRouterOutlet>
    );
};
export default Routes;
