import axios from "axios";

const api = axios.create()

const sessionName = process.env.REACT_APP_SESSION_NAME

api.interceptors.request.use(async config => {
  if (config?.headers) {
    const token = localStorage.getItem(`@${sessionName}:token`)
    if (token) {
      config.headers.Authorization = `${token}`
    }
  }

  const baseUrl = localStorage.getItem(`@${sessionName}:baseUrl`)
  if (baseUrl) {
    config.baseURL = `${baseUrl}`
  }

  return config
})

export default api
