import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from "react-router-dom";

import { useAuth } from "../hooks/auth";

interface RouteProps extends ReactDOMRouteProps {
  Medico?: boolean;
  Enfermagem?: boolean;
  Tecnico?: boolean;
  Motorista?: boolean;
  Administrativo?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  Medico = false,
  Enfermagem = false,
  Tecnico = false,
  Motorista = false,
  Administrativo = false,
  component: Component,
  ...rest
}) => {
  const { usuario } = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return !!usuario &&
          ((!!Medico &&
            Medico === (usuario?.tipo_perfil === "MED" ? true : false)) ||
            (!!Enfermagem &&
              Enfermagem ===
              (usuario?.tipo_perfil === "ENF" ? true : false)) ||
            (!!Tecnico &&
              Tecnico === (usuario?.tipo_perfil === "TEC" ? true : false)) ||
            (!!Motorista &&
              Motorista ===
              (usuario?.tipo_perfil === "MOT" ? true : false)) ||
            (!!Administrativo &&
              Administrativo === (usuario?.tipo_perfil === "ADM" ? true : false))) ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname:
                usuario?.tipo_perfil === "MED"
                  ? "/medico/prescricao"
                  : usuario?.tipo_perfil === "ENF"
                    ? "/enfermagem/atendimento"
                    : usuario?.tipo_perfil === "TEC"
                      ? "/tecnico/em-andamento"
                      : usuario?.tipo_perfil === "MOT"
                        ? "/motorista/maquina-movimento"
                        : usuario?.tipo_perfil === "ADM"
                          ? "/administrativo/indicadores-dialise"
                          : "/",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
