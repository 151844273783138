import { useState } from "react";
import { useParams } from "react-router";
import {
    IonCardContent,
    IonCardHeader,
    IonCol,
    IonLabel,
    IonRow,
    IonText,
    useIonViewDidEnter,
    useIonViewWillLeave
} from "@ionic/react";

import { useLoading } from "../../hooks/loading";
import { useValidation } from "../../hooks/validation";

import api from "../../services/api";

import Main from "../../components/Main";
import Card from "../../components/Card";

interface ParamTypes {
    idPaciente: string;
    nomePaciente: string;
}

interface Row {
    id: number;
    data: string;
    relatorio: string;
}

const PacienteRelatorio = () => {
    const { idPaciente, nomePaciente } = useParams<ParamTypes>();
    const { showLoading, hideLoading } = useLoading();
    const { getValidationErrors } = useValidation();

    const [rows, setRows] = useState<Row[]>([])

    const getList = async (idPaciente: string) => {
        showLoading();
        await api.get(`paciente-relatorio/${idPaciente}`).then(response => {
            setRows(response.data);
        })
            .catch(error => {
                getValidationErrors(error.response);
            })
        hideLoading();
    }

    useIonViewDidEnter(() => {
        setRows([]);
        getList(idPaciente);
    }, [idPaciente])

    useIonViewWillLeave(() => {
        setRows([]);
    }, [idPaciente])

    return (
        <Main title="Relatórios" back>
            <Card>
                <IonCardHeader>
                    <IonText><strong>{nomePaciente}</strong></IonText>
                </IonCardHeader>
            </Card>
            {rows.map(row => (
                <Card key={row.id}>
                    <IonCardContent>
                        <IonRow>
                            <IonCol size="12">
                                <IonLabel>DATA: </IonLabel>
                                <IonText>{row.data}</IonText>
                            </IonCol>
                            <IonCol size="12">
                                <IonLabel>RELATÓRIO: </IonLabel>
                                <IonText>{row.relatorio}</IonText>
                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                </Card>
            ))}
        </Main>
    )
}

export default PacienteRelatorio
