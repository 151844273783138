import { useState } from "react";
import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, IonSelectOption, IonText, useIonViewDidEnter } from "@ionic/react";
import { refresh } from "ionicons/icons";

import api from "../../../services/api";

import { useLoading } from "../../../hooks/loading";

import Main from "../../../components/Main";
import Card from "../../../components/Card";
import { IonSelect } from "../../../components/Select/styles";

const sessionName = process.env.REACT_APP_SESSION_NAME;

interface Carro {
    id: number
    placa: string
}

interface ProdutoMovimento {
    id_maquina: number;
    numero: string;
    data_movimentacao: string;
    serial: string;
    id_ordem_servico: string;
    tipo: string;
    placa_carro: string;
    origem: string;
    destino: string;
    transito: "A" | "S";
    retirado_pelo_usuario: "S" | "N";
}

const MotoristaMaquinaMovimento = () => {
    const { showLoading, hideLoading } =
        useLoading();

    const [carros, setCarros] = useState<Carro[]>([])
    const [placaDoCarro, setPlacaDoCarro] = useState(0)

    const [maquinasMovimentos, setMaquinasMovimentos] = useState<ProdutoMovimento[]>([]);

    const getCarros = async () => {
        await api.get("motorista/carro").then(response => {
            const { data } = response;
            if (data)
                setCarros(data);

            changePlacaDoCarro(
                parseInt(localStorage.getItem(`@${sessionName}:carroPlaca`) || "0")
            );
        })
    }

    const changePlacaDoCarro = (newPlacaDoCarro: number) => {
        localStorage.setItem(`@${sessionName}:carroPlaca`, String(newPlacaDoCarro));
        setPlacaDoCarro(newPlacaDoCarro);
        getData({ id_carro: newPlacaDoCarro });
    }

    const getData = async (params = {}) => {
        showLoading();
        await api.get("motorista/maquina", { params }).then(response => {
            const { data } = response;
            if (!!data)
                setMaquinasMovimentos(data)
        })
        hideLoading();
    }

    useIonViewDidEnter(async () => {
        await getCarros();
    }, [])

    const RefreshIcon = () => (
        <IonItem lines="none">
            <IonIcon
                icon={refresh}
                size="large"
                color="light"
                onClick={async () => {
                    await getCarros();
                }}
            />
        </IonItem>
    )

    return <Main title="Equipamentos" end={<RefreshIcon />}>
        <IonGrid>
            <IonRow>
                <IonCol size="12">
                    <Card>
                        <IonCardContent>
                            <IonLabel position="stacked">Placa do Carro</IonLabel>
                            <IonSelect
                                value={placaDoCarro}
                                onIonChange={event => changePlacaDoCarro(parseInt(event.detail.value))}
                                cancelText="Cancelar"
                                okText="Ok"
                                interface="action-sheet"
                            >
                                <IonSelectOption value={0}>TODOS</IonSelectOption>
                                {carros.map(carro => (
                                    <IonSelectOption key={carro.id} value={carro.id}>
                                        {carro.placa}
                                    </IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonCardContent>
                    </Card>
                </IonCol>
            </IonRow>
            <IonRow>
                {maquinasMovimentos.map(maquinaMovimento => (
                    <IonCol size="12" key={maquinaMovimento.id_maquina}>
                        <IonCard>
                            <IonCardContent>
                                <IonCardTitle>NÚMERO: {maquinaMovimento.numero}</IonCardTitle>
                                <br />
                                <IonLabel>DATA DE MOVIMENTAÇÃO: </IonLabel>
                                <IonText>{maquinaMovimento.data_movimentacao}</IonText>
                                <br />
                                <IonLabel>SERIAL: </IonLabel>
                                <IonText>{maquinaMovimento.serial}</IonText>
                                <br />
                                {maquinaMovimento.id_ordem_servico && (
                                    <>
                                        <IonLabel>ORDEM DE SERVIÇO: </IonLabel>
                                        <IonText>{maquinaMovimento.id_ordem_servico}</IonText>
                                        <br />
                                    </>
                                )}
                                <IonLabel>TIPO: </IonLabel>
                                <IonText>{maquinaMovimento.tipo}</IonText>
                                <br />
                                <IonLabel>PLACA: </IonLabel>
                                <IonText>{maquinaMovimento.placa_carro}</IonText>
                                <br />
                                <IonLabel>ORIGEM: </IonLabel>
                                <IonText>{maquinaMovimento.origem}</IonText>
                                <br />
                                <IonLabel>DESTINO: </IonLabel>
                                <IonText>{maquinaMovimento.destino}</IonText>
                                <IonRow className="ion-margin-top">
                                    <IonCol size="12">
                                        <IonButton
                                            type="button"
                                            routerLink={`/motorista/maquina-checklist/${maquinaMovimento.id_maquina}/${maquinaMovimento.serial}`}
                                            color={maquinaMovimento.transito === "A" ? "medium" : "dark"}
                                            expand="block"
                                        >
                                            {maquinaMovimento.transito === "A" ? "Confirmar Retirada" : "Confirmar Entrega"}
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                ))}
            </IonRow>
        </IonGrid>
    </Main >
}

export default MotoristaMaquinaMovimento;
