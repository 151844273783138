import styled from "styled-components";
import ReactSelect from "react-select";

const appColor = process.env.REACT_APP_COLOR;

interface Props {
    disabled?: boolean;
    hidden?: boolean
}

export default styled(ReactSelect)`
    ${(props: Props) => props.hidden ? 'display: none;' : ''};
    opacity: 1;

    .react-select__control {
        border: 2px solid #b0b0b0;
        opacity: 1;

        &:hover {
            box-shadow: 0 0 0 1px ${appColor};
            border: 2px solid ${appColor};
            border-radius: 4px;
        }
    }

    .react-select__menu {
        z-index: 9999;
    }
`;
