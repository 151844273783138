import { useEffect, useRef } from "react"
import { IonButton, IonCol, IonLabel, IonRow, IonText } from "@ionic/react"

import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { useLoading } from "../../../../hooks/loading";
import { useToast } from "../../../../hooks/toast";
import { useValidation } from "../../../../hooks/validation";

import api from "../../../../services/api";

import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import ReactSelect from "../../../../components/ReactSelect";
import { Atendimento, Data } from "..";


interface Props {
    id_atendimento: number;
    atendimento: Atendimento;
    setLastUpdate(date: Date): void;
    setData(data: Data | null): void;
}

interface FormValues {
    destino: string;
}

const EnfermagemAtendimentoFormulario = ({
    id_atendimento,
    atendimento,
    setLastUpdate,
    setData,
}: Props) => {
    const { showSaveMessage, hideSaveMessage } = useLoading();
    const { successToast } = useToast();
    const { getValidationErrors } = useValidation();

    const formRef = useRef<FormHandles>(null);

    const handleSubmit: SubmitHandler = async (values: FormValues) => {
        showSaveMessage()
        await api
            .put(`enfermagem/atendimento/${id_atendimento}`, values)
            .then(response => {
                if (!!response.data) {
                    successToast();
                }
            })
            .catch(error => {
                setData(null);
                getValidationErrors(error.response);
            })
        setLastUpdate(new Date());
        hideSaveMessage()
    }

    useEffect(() => {
        formRef?.current?.setData(atendimento)
    }, [atendimento])

    const handleSituacao = async (situacao: string) => {
        showSaveMessage()
        await api
            .put(`enfermagem/atendimento/${id_atendimento}`, { situacao })
            .then(response => {
                if (!!response.data) {
                    successToast();
                }
            })
            .catch(error => {
                setData(null);
                getValidationErrors(error.response);
            })
        setLastUpdate(new Date());
        hideSaveMessage()
    }

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <IonRow className="ion-align-items-end">
                {atendimento.situacao !== "REALIZADO" ?
                    <>
                        <Select
                            size="12"
                            label="Localizacao"
                            name="localizacao"
                            values={[
                                { value: "CTI", label: "CTI" },
                                { value: "ENFERMARIA", label: "ENFERMARIA" },
                                { value: "QUARTO", label: "QUARTO" },
                                { value: "POI", label: "POI" },
                                { value: "PÓS", label: "PÓS" },
                                { value: "UCO", label: "UCO" },
                                { value: "UCI", label: "UCI" },
                                { value: "UCIC", label: "UCIC" },
                                { value: "OUTROS", label: "OUTROS" }
                            ]}
                        />
                        <Input
                            label="Leito"
                            name="leito"
                            type="text"
                        />
                        <ReactSelect
                            label="Técnico 1"
                            name="id_tecnico1"
                            url="/enfermagem/select/tecnico"
                            params={{ status: "A" }}
                            simple
                        />
                        <Input
                            size="12"
                            label="Hora de Início do Técnico 1:"
                            name="inicio_tecnico1"
                            type="time"
                        />
                        {!!atendimento.id_tecnico1 && (
                            <>
                                <ReactSelect
                                    label="Técnico 2"
                                    name="id_tecnico2"
                                    url="/enfermagem/select/tecnico"
                                    params={{ status: "A" }}
                                    simple
                                />
                                <Input
                                    size="12"
                                    label="Hora de Início do Técnico 2:"
                                    name="inicio_tecnico2"
                                    type="time"
                                />
                            </>
                        )}
                        {!!atendimento.id_tecnico2 && (
                            <>
                                <ReactSelect
                                    label="Técnico 3"
                                    name="id_tecnico3"
                                    url="/enfermagem/select/tecnico"
                                    params={{ status: "A" }}
                                    simple
                                />
                                <Input
                                    size="12"
                                    label="Hora de Início do Técnico 3:"
                                    name="inicio_tecnico3"
                                    type="time"
                                />
                            </>
                        )}
                        {!!atendimento.id_tecnico3 && (
                            <>
                                <ReactSelect
                                    label="Técnico 4"
                                    name="id_tecnico4"
                                    url="/enfermagem/select/tecnico"
                                    params={{ status: "A" }}
                                    simple
                                />
                                <Input
                                    size="12"
                                    label="Hora de Início do Técnico 4:"
                                    name="inicio_tecnico4"
                                    type="time"
                                />
                            </>
                        )}
                        {!!atendimento.id_tecnico4 && (
                            <>
                                <ReactSelect
                                    label="Técnico 5"
                                    name="id_tecnico5"
                                    url="/enfermagem/select/tecnico"
                                    params={{ status: "A" }}
                                    simple
                                />
                                <Input
                                    size="12"
                                    label="Hora de Início do Técnico 5:"
                                    name="inicio_tecnico5"
                                    type="time"
                                />
                            </>
                        )}
                        {!!atendimento.id_tecnico5 && (
                            <>
                                <ReactSelect
                                    label="Técnico 6"
                                    name="id_tecnico6"
                                    url="/enfermagem/select/tecnico"
                                    params={{ status: "A" }}
                                    simple
                                />
                                <Input
                                    size="12"
                                    label="Hora de Início do Técnico 6:"
                                    name="inicio_tecnico6"
                                    type="time"
                                />
                            </>
                        )}
                        {!!atendimento.id_tecnico6 && (
                            <>
                                <ReactSelect
                                    label="Técnico 7"
                                    name="id_tecnico7"
                                    url="/enfermagem/select/tecnico"
                                    params={{ status: "A" }}
                                    simple
                                />
                                <Input
                                    size="12"
                                    label="Hora de Início do Técnico 7:"
                                    name="inicio_tecnico7"
                                    type="time"
                                />
                            </>
                        )}
                        {!!atendimento.id_tecnico7 && (
                            <>
                                <ReactSelect
                                    label="Técnico 8"
                                    name="id_tecnico8"
                                    url="/enfermagem/select/tecnico"
                                    params={{ status: "A" }}
                                    simple
                                />
                                <Input
                                    size="12"
                                    label="Hora de Início do Técnico 8:"
                                    name="inicio_tecnico8"
                                    type="time"
                                />
                            </>
                        )}
                    </>
                    :
                    <>
                        <IonCol size="12">
                            <IonLabel>LOCALIZAÇÃO: </IonLabel>
                            <IonText>{atendimento.localizacao}</IonText>
                        </IonCol>
                        <IonCol size="12">
                            <IonLabel>LEITO: </IonLabel>
                            <IonText>{atendimento.leito}</IonText>
                        </IonCol>
                        {!!atendimento.nome_tecnico1 &&
                            <>
                                <IonCol size="12">
                                    <IonLabel>TÉCNICO 1: </IonLabel>
                                    <IonText>{atendimento.nome_tecnico1}</IonText>
                                </IonCol>
                                <IonCol size="12">
                                    <IonLabel>HORA DE INÍCIO DO TÉCNICO 1: </IonLabel>
                                    <IonText>{atendimento.inicio_tecnico1}</IonText>
                                </IonCol>
                            </>
                        }
                    </>
                }
                <Select
                    size="12"
                    label="Destino"
                    name="destino"
                    values={[
                        { value: "Em Tratamento", label: "EM TRATAMENTO" },
                        { value: "Alta Nefro", label: "ALTA NEFRO" },
                        { value: "Alta Hospitalar", label: "ALTA HOSPITALAR" },
                        { value: "Alta Encaminhado para o Programa", label: "ALTA ENCAMINHADO PARA O PROGRAMA" },
                        { value: "Transferência", label: "TRANSFERÊNCIA" },
                        { value: "Óbito", label: "ÓBITO" },
                    ]}
                />
            </IonRow>
            <IonRow className="ion-margin-top">
                <IonCol size="6">
                    <IonButton
                        type="submit"
                        color="primary"
                        expand="block"
                    >
                        Salvar
                    </IonButton>
                </IonCol>
                <IonCol size="6">
                    <IonButton
                        type="button"
                        routerLink={`/prescricao?id_atendimento=${atendimento.id}`}
                        color="tertiary"
                        expand="block"
                    >
                        Prescrição
                    </IonButton>
                </IonCol>
            </IonRow>
            <IonRow className="ion-margin-top">
                {
                    atendimento.situacao !== "ANDAMENTO" &&
                    atendimento.situacao !== "REALIZADO" &&
                    atendimento.situacao !== "SEMRH" &&
                    <IonCol size="12">
                        <IonButton
                            type="button"
                            color="danger"
                            expand="block"
                            onClick={() => handleSituacao("SEMRH")}
                            style={{
                                whiteSpace: "pre-line"
                            }}
                        >
                            COLOCAR COMO SEM RH
                        </IonButton>
                    </IonCol>
                }
                {atendimento.situacao !== "PENDENTE" &&
                    atendimento.situacao !== "REALIZADO" &&
                    atendimento.situacao !== "ANDAMENTO" && (
                        <IonCol size="12">
                            <IonButton
                                type="button"
                                color="primary"
                                expand="block"
                                onClick={() => handleSituacao("PENDENTE")}
                                style={{
                                    whiteSpace: "pre-line"
                                }}
                            >
                                COLOCAR COMO PENDENTE
                            </IonButton>
                        </IonCol>
                    )}
                {atendimento.situacao !== "CANCELADO" &&
                    atendimento.situacao !== "REALIZADO" &&
                    atendimento.situacao !== "SUSPENSO" && (
                        <IonCol size="12">
                            <IonButton
                                type="button"
                                color="danger"
                                expand="block"
                                onClick={() => handleSituacao("CANCELADO")}
                            >
                                CANCELAR
                            </IonButton>
                        </IonCol>
                    )}
            </IonRow>
        </Form>
    )
}

export default EnfermagemAtendimentoFormulario
