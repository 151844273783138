import { useEffect, useRef } from "react"
import { IonCol } from "@ionic/react"
import { useField } from "@unform/core"

import Label from "../Label"
import { IonSelect, IonSelectOption } from "./styles";

export interface Option {
    value: string | number
    label: string
    disabled?: boolean
}

interface Props {
    size?: string
    label?: string
    name: string
    values?: Option[]
    onChange?(value: string | string[] | number | number[]): void
    required?: boolean
    disabled?: boolean
    hidden?: boolean
    multiple?: boolean
    children?: React.ReactNode
}

const Select: React.FC<Props> = ({
    size,
    label,
    name,
    values,
    onChange,
    required,
    disabled,
    hidden,
    multiple,
    children
}) => {
    const selectRef = useRef<HTMLIonSelectElement>(null)
    const { fieldName, defaultValue, registerField } = useField(name)

    useEffect(() => {
        if (!!selectRef && selectRef.current) selectRef.current.value = defaultValue;
    }, [defaultValue])

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: ref => {
                const value = ref.value;
                if (typeof value === "number") {
                    if (value >= 0) {
                        return value
                    }
                } else {
                    if (!!value) {
                        return value
                    }
                }
            },
            setValue: (ref, value) => {
                if (typeof value === "number") {
                    if (value >= 0) {
                        ref.value = value
                    }
                } else if (!!value) {
                    ref.value = value
                } else if (value === "") {
                    ref.value = ""
                }
            },
            clearValue: (ref, value) => {
                if (value) {
                    ref.value = value
                } else {
                    ref.value = ""
                }
            }
        })
    }, [fieldName, registerField])

    return (
        <IonCol size={size || "12"} style={!hidden ? {} : { display: "none" }} >
            {!!label && !hidden && <Label required={required}>{label}</Label>}
            <IonSelect
                ref={selectRef}
                name={name}
                interface={`${!!multiple ? "alert" : "action-sheet"}`}
                okText="Ok"
                cancelText="Cancelar"
                multiple={multiple}
                disabled={disabled}
                hidden={hidden}
                onIonChange={e => !!onChange && onChange(e.detail.value)}
                interfaceOptions={
                    !!multiple && { mode: "md", cssClass: "select-multi-options" }
                }
            >
                {!!values ?
                    values.map((option, index) => (
                        <IonSelectOption style={{ overflow: "visible" }} key={index} value={option.value} disabled={option.disabled}>
                            {option.label}
                        </IonSelectOption>
                    ))
                    :
                    children
                }
            </IonSelect>
        </IonCol>
    )
}

export default Select
