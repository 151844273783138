import React, { useCallback, useEffect, useState } from 'react'
import { IonCol, IonLabel } from '@ionic/react'
import Select, { Props as SelectProps } from 'react-select'

import { useAuth } from '../../hooks/auth'
import api from '../../services/api'

interface Props {
  size?: string
  label: string
  type: string
  value: SelectProps
  onChange(value: SelectProps): void
  id?: number
}

const SearchInput: React.FC<Props> = ({
  size,
  label,
  value,
  type,
  onChange,
  id,
  ...rest
}) => {
  const { usuario } = useAuth();
  const [machines, setMachines] = useState([])

  const searchMachines = useCallback(
    (searchValue?: string | null) => {
      api
        .get(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/select/maquina`, {
          params: { search: searchValue, type: type, id: id }
        })
        .then(resp => {
          setMachines(resp.data)
        })
    },
    [id, type, usuario.tipo_perfil]
  )

  useEffect(() => {
    searchMachines()
  }, [searchMachines])

  return (
    <IonCol size={size || '12'}>
      <IonLabel position="stacked">{label}</IonLabel>
      <Select
        placeholder=""
        classNamePrefix="select"
        value={value}
        onChange={value => {
          if (value) {
            onChange(value)
          } else {
            onChange({} as SelectProps)
          }
        }}
        onInputChange={value => searchMachines(value)}
        noOptionsMessage={() => 'Nenhuma opção'}
        options={machines}
        styles={{
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
        components={{
          IndicatorSeparator: () => null
        }}
        {...rest}
      />
    </IonCol>
  )
}

export default SearchInput
