import { useEffect, useRef } from "react";
import {
  IonApp,
  IonButton,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  isPlatform
} from "@ionic/react";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { Header, Logo } from "./styles";
import LogoImg from "../../assets/logo.png";

import api from "../../services/api";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import { useValidation } from "../../hooks/validation";

import Card from "../../components/Card";
import Input from "../../components/Input"
import ReactSelect from "../../components/ReactSelect"

const sessionName = process.env.REACT_APP_SESSION_NAME

const Login = () => {
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const { getValidationErrors } = useValidation();

  const formRef = useRef<FormHandles>(null)

  const getUnitFromStorage = () => {
    try {
      return JSON.parse(`${localStorage.getItem(`@${sessionName}:empresa`)}`)
    } catch (_error) {
      return {}
    }
  }

  const initialData = {
    nome: localStorage.getItem(`@${sessionName}:nome`) || "",
    empresa: getUnitFromStorage()
  }

  useEffect(() => {
    const platform = isPlatform("mobileweb") || isPlatform("desktop")
    if (!platform) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT)
    }
  }, [])

  const handleSubmit: SubmitHandler = async data => {
    const { nome, senha, empresa } = data

    if (empresa) {
      const id_empresa = empresa?.value;
      if (id_empresa) {
        const baseUrl = `${process.env.REACT_APP_API}/${empresa?.value}`

        localStorage.setItem(`@${sessionName}:nome`, nome ? nome : "")
        localStorage.setItem(`@${sessionName}:empresa`, JSON.stringify(empresa))
        localStorage.setItem(`@${sessionName}:baseUrl`, baseUrl)

        await api
          .post(`login`, {
            nome,
            senha
          })
          .then(response => {
            const { usuario, token } = response.data;
            signIn(usuario, token, baseUrl)
          })
          .catch(error => {
            getValidationErrors(error.response)
          })
      }
    } else {
      addToast({ message: "Selecione uma empresa.", color: "danger" });
    }
  }

  return (
    <IonApp>
      <IonPage>
        <Header>
          <Logo src={LogoImg} alt="Logo" />
        </Header>
        <IonContent scrollEvents={true}>
          <Card>
            <IonCardContent>
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={initialData}
              >
                <IonGrid>
                  <IonRow>

                    <Input
                      label="Usuário"
                      name="nome"
                      autoFocus
                      autoComplete="username"
                      autoCapitalize="off"
                      autoCorrect="off"
                    />
                    <Input
                      label="Senha"
                      name="senha"
                      type="password"
                      autoComplete="off"
                      autoCapitalize="off"
                      autoCorrect="off"
                    />
                    <ReactSelect
                      label="Unidade"
                      name="empresa"
                      url={`${process.env.REACT_APP_API}/empresa`}
                      params={{ status: "A" }}
                    />
                    <IonCol size="12" style={{ marginTop: "30px" }}>
                      <IonButton type="submit" color="primary" expand="block">
                        Entrar
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </Form>
            </IonCardContent>
          </Card>
        </IonContent>
      </IonPage>
    </IonApp>
  )
}

export default Login
