import React from "react";

interface Props {
  color?: string
  marginTop?: string
  children?: React.ReactNode
}

const fontSize = "8px"

export const Container = ({ children }: Props) => {
  return <div style={{ width: "99%", padding: "10px" }}>{children}</div>
}

export const Header = ({ children }: Props) => {
  return (
    <table style={{ width: "100%", marginBottom: "5px", textAlign: "center" }}>
      {children}
    </table>
  )
}

export const Span = ({ color, children }: Props) => {
  return <span style={{ color: `${color}`, fontSize }}>{children}</span>
}

export const Text = ({ children }: Props) => {
  return (
    <p
      style={{
        margin: "5px",
        fontSize
      }}
    >
      {children}
    </p>
  )
}

export const H2 = ({ color, children }: Props) => {
  const fontSize = "18px"
  return (
    <h2 style={{ margin: "0px", fontSize, fontWeight: 400, color: `${color}` }}>
      {children}
    </h2>
  )
}
export const H3 = ({ color, children }: Props) => {
  const fontSize = "16px"
  return (
    <h3 style={{ margin: "0px", fontSize, fontWeight: 400, color: `${color}` }}>
      {children}
    </h3>
  )
}
export const H4 = ({ color, children }: Props) => {
  const fontSize = "14px"
  return (
    <h4 style={{ margin: "0px", fontSize, fontWeight: 400, color: `${color}` }}>
      {children}
    </h4>
  )
}

export const Logo = ({ children }: Props) => {
  return (
    <th style={{ width: "100px", textAlign: "left", padding: "3px" }}>
      {children}
    </th>
  )
}

interface thProps extends Props {
  index?: number
  columns?: number
  color?: string
  textAlign?:
  | "start"
  | "end"
  | "left"
  | "right"
  | "center"
  | "justify"
  | "match-parent"
  borderWidth?: string
  colSpan?: number
  rowSpan?: number
}

export const Th: React.FC<thProps> = ({
  index,
  columns,
  color,
  textAlign,
  borderWidth,
  colSpan,
  rowSpan,
  children
}) => {
  borderWidth = !!borderWidth ? borderWidth : "1px"
  if (!!index && !!columns) {
    if (index === 1) {
      borderWidth = "1px 0px 1px 1px"
    } else if (index === columns) {
      borderWidth = "1px 1px 1px 0px"
    } else if (index < columns) {
      borderWidth = "1px 0px 1px 0px"
    }
  }

  return (
    <th
      colSpan={colSpan}
      rowSpan={rowSpan}
      style={{
        textAlign,
        borderWidth,
        borderStyle: "solid",
        borderColor: "black",
        borderCollapse: "collapse",
        color: `${color}`,
        padding: "3px"
      }}
    >
      {children}
    </th>
  )
}

interface tdProps extends Props {
  index?: number
  columns?: number
  color?: string
  textAlign?:
  | "start"
  | "end"
  | "left"
  | "right"
  | "center"
  | "justify"
  | "match-parent"
  borderWidth?: string
  colSpan?: number
  rowSpan?: number
}

export const Td: React.FC<tdProps> = ({
  index,
  columns,
  color,
  textAlign,
  borderWidth,
  colSpan,
  rowSpan,
  children
}) => {
  borderWidth = !!borderWidth ? borderWidth : "1px"
  if (!!index && !!columns) {
    if (index === 1) {
      borderWidth = "1px 0px 1px 1px"
    } else if (index === columns) {
      borderWidth = "1px 1px 1px 0px"
    } else if (index < columns) {
      borderWidth = "1px 0px 1px 0px"
    }
  }

  return (
    <td
      colSpan={colSpan}
      rowSpan={rowSpan}
      style={{
        textAlign,
        borderWidth,
        borderStyle: "solid",
        borderColor: "black",
        borderCollapse: "collapse",
        color: `${color}`,
        padding: "3px"
      }}
    >
      {children}
    </td>
  )
}

export const Grid = ({ marginTop, children }: Props) => {
  return (
    <table
      style={{
        width: "100%",
        fontSize,
        borderCollapse: "collapse",
        tableLayout: "fixed",
        marginTop: `${marginTop}`
      }}
    >
      {children}
    </table>
  )
}

export const Annotations = ({ children }: Props) => {
  return (
    <table
      style={{
        width: "100%",
        fontSize,
        borderCollapse: "collapse",
        textAlign: "center",
        marginTop: "10px"
      }}
    >
      {children}
    </table>
  )
}

export const Evolutions = ({ children }: Props) => {
  return (
    <table
      style={{
        width: "100%",
        fontSize,
        borderCollapse: "collapse",
        textAlign: "center",
        marginTop: "10px"
      }}
    >
      {children}
    </table>
  )
}

export const Estoque = ({ children }: Props) => {
  return (
    <table
      style={{
        width: "100%",
        fontSize,
        borderCollapse: "collapse",
        textAlign: "center",
        marginTop: "10px"
      }}
    >
      {children}
    </table>
  )
}

export const Signatures = ({ children }: Props) => {
  return (
    <table
      style={{
        width: "100%",
        tableLayout: "fixed",
        marginTop: "15px",
        border: "1px solid black",
        borderCollapse: "collapse"
      }}
    >
      {children}
    </table>
  )
}
