interface InterruptedDialysis {
  value: string
  label: string
}

const interrupted_dialysis: InterruptedDialysis[] = [
  {
    value: 'Óbito',
    label: 'Óbito'
  },
  {
    value: 'Instabilidade hemodinâmica',
    label: 'Instabilidade hemodinâmica'
  },
  {
    value: 'Problemas cateter',
    label: 'Problemas cateter'
  },
  {
    value: 'Suspensão do procedimento',
    label: 'Suspensão do procedimento'
  },
  {
    value: 'Coagulação de sistema',
    label: 'Coagulação de sistema'
  },
  {
    value: 'Problemas equipamento',
    label: 'Problemas equipamento'
  },
  {
    value: 'Decisão logística por RH',
    label: 'Decisão logística por RH'
  },
  {
    value: 'Decisão logística por Equipamento',
    label: 'Decisão logística por Equipamento'
  },
  {
    value: 'Outros',
    label: 'Outros'
  }
]

export { interrupted_dialysis }
