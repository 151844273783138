import { useRef } from "react"
import {
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton
} from "@ionic/react";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import api from "../../../services/api";

import { useLoading } from "../../../hooks/loading";
import { useToast } from "../../../hooks/toast";
import { useValidation } from "../../../hooks/validation";

import Main from "../../../components/Main";
import Card from "../../../components/Card";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import ReactInputMask from "../../../components/ReactInputMask";
import ReactSelect, { Option } from "../../../components/ReactSelect";

interface FormValues {
    nome: string;
    id_hospital: number;
    cpf: string;
    id_convenio: number;
    matricula: string;
    data_nascimento: string;
    sexo: "M" | "F";
}

const MedicoPaciente = () => {
    const { showLoading, hideLoading, showSaveMessage, hideSaveMessage } =
        useLoading();
    const { successToast } = useToast();
    const { getValidationErrors } = useValidation();

    const formRef = useRef<FormHandles>(null);

    const getConvenio = async (id_hospital: number) => {
        if (id_hospital > 0) {
            showLoading()
            await api
                .get(`/medico/hospital-convenio/${id_hospital}`)
                .then(response => {
                    if (!!response?.data) {
                        formRef?.current?.setFieldValue("id_convenio", response?.data);
                        hideLoading();
                    }
                })
        }
    }

    const handleSubmit: SubmitHandler = async (values: FormValues) => {
        showSaveMessage();
        await api
            .post("medico/paciente", values)
            .then(response => {
                if (!!response.data) {
                    successToast();
                    formRef?.current?.reset();
                }
            })
            .catch(error => {
                getValidationErrors(error.response);
            })
        hideSaveMessage();
    }

    return (
        <Main title="Novo Paciente" back>
            <Card>
                <IonCardContent>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <IonGrid>
                            <IonRow className="ion-align-items-end">
                                <Input
                                    label="Nome"
                                    name="nome"
                                    type="text"
                                    required
                                />
                                <ReactSelect
                                    label="Hospital"
                                    name="id_hospital"
                                    url="/medico/select/hospital"
                                    params={{ status: "A" }}
                                    onChange={(option: Option) =>
                                        getConvenio(parseInt(`${option?.value}`))
                                    }
                                    simple
                                />
                                <ReactInputMask
                                    label="CPF"
                                    name="cpf"
                                    mask="999.999.999-99"
                                />
                                <ReactSelect
                                    label="Convênio"
                                    name="id_convenio"
                                    url="/medico/select/convenio"
                                    params={{ status: "A" }}
                                    simple
                                    required
                                />
                                <Input
                                    label="Matrícula"
                                    name="matricula"
                                    type="text"
                                    required
                                />
                                <ReactInputMask
                                    label="Data de Nascimento"
                                    name="data_nascimento"
                                    mask="99/99/9999"
                                    required
                                />
                                <Select
                                    label="Sexo"
                                    name="sexo"
                                    values={
                                        [
                                            { value: "M", label: "MASCULINO" },
                                            { value: "F", label: "FEMININO" }
                                        ]}
                                />
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="6">
                                    <IonButton type="submit" color="primary" expand="block">
                                        Salvar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </Form>
                </IonCardContent>
            </Card>
        </Main>
    )
}

export default MedicoPaciente;