import { useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonLabel,
    IonRow,
    IonSelectOption,
    IonText
} from "@ionic/react";

import { Printer } from "@awesome-cordova-plugins/printer";

import api from "../../services/api";
import { useValidation } from "../../hooks/validation";

import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import Main from "../../components/Main";
import Input from "../../components/Input";
import Select from "../../components/Select";
import ReactSelect from "../../components/ReactSelect";

import DialysisSheetToPrint from "./FolhaDialiseToPrint";

interface FormValues {
    search_by: string
    patient_id: number
}

interface DataValues {
    id: number
    date: string
    hospital: string
    patient: string
    doctor: string
    sheet: string
}

const FolhaDialise = () => {
    const { getValidationErrors } = useValidation()

    const formRef = useRef<FormHandles>(null)
    const [searchBy, setSearchBy] = useState("P")
    const [data, setData] = useState<DataValues[]>([])

    const handleSubmit: SubmitHandler = async (params: FormValues) => {
        await api
            .get("dialysis_sheets", {
                params
            })
            .then(response => {
                setData(response.data)
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
    }

    const handlePrint = async (id: string) => {
        await api
            .get(`dialysis_sheets/${id}`)
            .then(response => {
                const { assistance, name, date, version } = response.data

                let content = ReactDOMServer.renderToStaticMarkup(
                    <DialysisSheetToPrint
                        assistance={assistance}
                        name={name}
                        date={date}
                        version={version}
                    />
                )
                let title = assistance.id
                if (!!assistance.sheet) title = assistance.sheet

                Printer.isAvailable().then(() => {
                    Printer.print(content, {
                        name: `Folha_${title}`,
                        autoFit: true,
                        orientation: "portrait",
                        margin: false
                    })
                })
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
    }

    return (
        <Main title="Folha de Diálise">
            <IonCard>
                <IonCardContent>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <IonRow className="ion-align-items-end">
                            <Select
                                label="Buscar por"
                                name="search_by"
                                onChange={(value: string) => setSearchBy(value)}
                            >
                                <IonSelectOption value="H">Hospital</IonSelectOption>
                                <IonSelectOption value="P">Paciente</IonSelectOption>
                            </Select>
                            {searchBy === "H" && (
                                <>
                                    <Input label="Data" name="date" type="date" />
                                    <ReactSelect
                                        label="Hospital"
                                        name="hospital_id"
                                        url="/rs/hospitals"
                                        simple
                                    />
                                </>
                            )}
                            {searchBy === "P" && (
                                <ReactSelect
                                    label="Paciente"
                                    name="patient_id"
                                    url="/rs/patients"
                                    simple
                                />
                            )}
                            <IonCol size="12" style={{ marginTop: "15px" }}>
                                <IonButton type="submit" color="primary" expand="block">
                                    Consultar
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </Form>
                </IonCardContent>
            </IonCard>
            {data.map(values => {
                return (
                    <IonCard key={values.id} className="ion-margin-vertical">
                        <IonCardHeader color="primary">
                            <IonCardTitle>Data: {values.date}</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonRow className="ion-margin-top">
                                <IonCol size="12">
                                    <IonLabel>Hospital: </IonLabel>
                                    <IonText>{values.hospital}</IonText>
                                </IonCol>
                                <IonCol size="12">
                                    <IonLabel>Paciente: </IonLabel>
                                    <IonText>{values.patient}</IonText>
                                </IonCol>
                                <IonCol size="12">
                                    <IonLabel>Médico: </IonLabel>
                                    <IonText>{values.doctor}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="12">
                                    <IonButton
                                        type="button"
                                        onClick={() => handlePrint(values.sheet)}
                                        color="dark"
                                        expand="block"
                                    >
                                        Imprimir
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                )
            })}
        </Main>
    )
}

export default FolhaDialise
