import { createContext, useContext, useCallback } from "react";
import { ValidationError } from "yup";

import { useAuth } from "./auth";
import { useToast } from "./toast";

interface ValidationErrorsContextData {
  getValidationErrors(error: any): void
}

const ValidationErrorsContext = createContext<ValidationErrorsContextData>(
  {} as ValidationErrorsContextData
)

interface Props {
  children: React.ReactNode
}

const ValidationErrorsProvider = ({ children }: Props) => {
  const { addToast } = useToast();
  const { signOut } = useAuth();

  const getValidationErrors = useCallback(
    (error: any) => {
      if (!error) {
        addToast({ message: "Falha ao estabelecer conexão.", color: "danger" });
      } else if (error instanceof ValidationError) {
        addToast({ message: error.errors[0], color: "warning" });
      } else if (error?.data?.error) {
        addToast({ message: error.data.error, color: "warning" });
      }

      if (error?.data?.valido === "N") {
        signOut();
      }
    },
    [addToast, signOut]
  )

  return (
    <ValidationErrorsContext.Provider value={{ getValidationErrors }}>
      {children}
    </ValidationErrorsContext.Provider>
  )
}

function useValidation(): ValidationErrorsContextData {
  const context = useContext(ValidationErrorsContext);

  if (!context) {
    throw new Error(
      "useValidation must be used within a ValidationErrorsProvider"
    );
  }

  return context;
}

export { ValidationErrorsContext, ValidationErrorsProvider, useValidation };
