import { useEffect, useRef } from "react";
import { IonCol, IonText } from "@ionic/react";
import { AutocompleteTypes, TextFieldTypes } from "@ionic/core";
import { useField } from "@unform/core";

import Label from "../Label";
import { IonInput } from "./styles";

interface Props {
  size?: string
  label?: string
  name: string
  type?: TextFieldTypes
  onChange?(value: any): void
  onBlur?(value: any): void
  required?: boolean
  disabled?: boolean
  hidden?: boolean
  notes?: string
  autoCapitalize?: string
  autoComplete?: AutocompleteTypes
  autoCorrect?: "on" | "off"
  autoFocus?: boolean
  clearInput?: boolean
  clearOnEdit?: boolean
  inputMode?:
  | "search"
  | "text"
  | "email"
  | "tel"
  | "url"
  | "decimal"
  | "none"
  | "numeric"
  max?: string
  maxLength?: number
  min?: string
  minLength?: number
  step?: string
  spellCheck?: boolean
}
const Input = ({
  size,
  label,
  notes,
  name,
  type,
  required,
  disabled,
  hidden,
  autoCapitalize,
  autoComplete,
  autoCorrect,
  autoFocus,
  clearInput,
  clearOnEdit,
  inputMode,
  max,
  maxLength,
  min,
  minLength,
  step,
  spellCheck,
  onChange,
  onBlur
}: Props) => {
  const inputRef = useRef<HTMLIonInputElement>(null)
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    if (!!inputRef && inputRef.current) inputRef.current.value = defaultValue;
  }, [defaultValue])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: ref => {
        if (!!ref.value) {
          const value = ref.value;

          if (type === "number" && typeof value === "string") {
            if (!step) {
              return parseInt(value);
            } else {
              return parseFloat(value);
            }
          } else {
            return value;
          }
        }
      },
      setValue: (ref, value) => {
        if (typeof value === "number") {
          ref.value = value.toString();
        } else {
          if (!!value) ref.value = value;
        }
      },
      clearValue: (ref, value) => {
        if (value) ref.value = value;
        else ref.value = "";
      }
    })
  }, [fieldName, registerField, step, type])

  return (
    <IonCol size={size || "12"} style={!hidden ? {} : { display: "none" }} >
      {!!label && !hidden && <Label required={required}>{label}</Label>}
      <IonInput
        ref={inputRef}
        name={name}
        type={type}
        autocapitalize={autoCapitalize}
        autocomplete={autoComplete}
        autocorrect={autoCorrect}
        autofocus={autoFocus}
        clearInput={clearInput}
        clearOnEdit={clearOnEdit}
        inputmode={inputMode}
        max={max}
        maxlength={maxLength}
        min={min}
        minlength={minLength}
        step={step}
        disabled={disabled}
        hidden={hidden}
        spellcheck={spellCheck}
        onIonChange={e => !!onChange && onChange(e.detail.value!)}
        onBlur={e => !!onBlur && onBlur(e.target.value!)}
      />
      {!!notes && <IonText>{notes}</IonText>}
    </IonCol>
  )
}

export default Input
