import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonNote,
    isPlatform,
} from "@ionic/react";
import { radioButtonOffOutline, arrowForwardOutline } from "ionicons/icons";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation";

import { useAuth } from "../../hooks/auth";

const appColor = process.env.REACT_APP_COLOR;

interface AppPage {
    url: string;
    title: string;
}

// Médico
const menuMedico: AppPage[] = [
    {
        title: 'Prescrição',
        url: '/medico/prescricao',
    },
    {
        title: 'Procedimentos',
        url: '/medico/procedimentos',
    },
    {
        title: 'Registrar Plantão',
        url: '/medico/registrar-plantao',
    },
    {
        title: 'Prontuário',
        url: '/prontuario',
    },
    {
        title: 'Em Tratamento',
        url: '/medico/em-tratamento',
    },
    {
        title: 'Cateter (Avulso)',
        url: '/medico/cateter-avulso',
    },
    // {
    //     title: 'Folha de Diálise',
    //     url: '/folha-dialise',
    // },
    {
        title: 'Notificação',
        url: '/notificacao',
    },
    {
        title: 'Abrir OS',
        url: '/abrir-os',
    },
    {
        title: 'Dados do Médico',
        url: '/usuario',
    },
    {
        title: 'Alterar Senha',
        url: '/senha',
    }
];

// Enfermagem
const menuEnfermagem: AppPage[] = [
    {
        title: "Atendimentos",
        url: "/enfermagem/atendimento",
    },
    {
        title: "Prontuário",
        url: "/prontuario",
    },
    {
        title: "Técnicos (Faltas)",
        url: "/enfermagem/tecnico-falta",
    },
    {
        title: "Técnicos (Extras)",
        url: "/enfermagem/tecnico-extra",
    },
    {
        title: "Agendados",
        url: "/tecnico/agendados",
    },
    {
        title: "Em Andamento",
        url: "/tecnico/em-andamento",
    },
    {
        title: "Assumir Atendimentos",
        url: "/tecnico/assumir-atendimento",
    },
    {
        title: "Pontuação",
        url: "/tecnico/pontuacao",
    },
    {
        title: 'Cateter (Avulso)',
        url: '/medico/cateter-avulso',
    },
    // {
    //     title: 'Folha de Diálise',
    //     url: '/folha-dialise',
    // },
    {
        title: 'Notificação',
        url: '/notificacao',
    },
    {
        title: 'Abrir OS',
        url: '/abrir-os',
    },
    {
        title: 'Dados do Usuário',
        url: '/usuario',
    },
    {
        title: 'Alterar Senha',
        url: '/senha',
    }
];

// Technician
const menuTecnico: AppPage[] = [
    {
        title: "Agendados",
        url: "/tecnico/agendados",
    },
    {
        title: "Em Andamento",
        url: "/tecnico/em-andamento",
    },
    {
        title: "Assumir Atendimentos",
        url: "/tecnico/assumir-atendimento",
    },
    {
        title: "Pontuação",
        url: "/tecnico/pontuacao",
    },
    {
        title: 'Cateter (Avulso)',
        url: '/medico/cateter-avulso',
    },
    {
        title: 'Notificação',
        url: '/notificacao',
    },
    {
        title: "Abrir OS",
        url: "/abrir-os",
    },
    {
        title: 'Dados do Usuário',
        url: '/usuario',
    },
    {
        title: 'Alterar Senha',
        url: '/senha',
    }
];

// Driver
const menuMotorista: AppPage[] = [
    {
        title: "Equipamentos",
        url: "/motorista/maquina-movimento",
    },
    {
        title: "Produtos",
        url: "/motorista/produto-movimento",
    },
    {
        title: 'Notificação',
        url: '/notificacao',
    },
    {
        title: 'Dados do Usuário',
        url: '/usuario',
    },
    {
        title: 'Alterar Senha',
        url: '/senha',
    }
];

// Administrativo
const menuAdministrativo: AppPage[] = [
    {
        title: "Indicadores Diálise",
        url: "/administrativo/indicadores-dialise",
    },
    {
        title: "Indicadores p/ Hospital",
        url: "/administrativo/indicadores-hospital",
    },
    {
        title: 'Notificação',
        url: '/notificacao',
    },
    {
        title: 'Dados do Usuário',
        url: '/usuario',
    },
    {
        title: 'Alterar Senha',
        url: '/senha',
    }
];

const appVersion = process.env.REACT_APP_VERSION;

const Menu = () => {
    const location = useLocation();
    const { usuario, signOut } = useAuth();

    const platform = isPlatform("ios") || isPlatform("mobileweb") || isPlatform("desktop");

    const [items, setItems] = useState<AppPage[]>([]);

    useEffect(() => {
        const platformOrientation =
            isPlatform("mobileweb") || isPlatform("desktop");
        if (!platformOrientation) {
            ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
        }

        if (usuario.tipo_perfil === "MED") {
            setItems(menuMedico);
        }
        else if (usuario.tipo_perfil === "ENF") {
            setItems(menuEnfermagem);
        } else if (usuario.tipo_perfil === "TEC") {
            setItems(menuTecnico);
        } else if (usuario.tipo_perfil === "MOT") {
            setItems(menuMotorista);
        }
        else if (usuario.tipo_perfil === "ADM") {
            setItems(menuAdministrativo);
        }
    }, [usuario]);

    return (
        <IonMenu contentId="main" type="overlay">
            <IonContent>
                <IonList
                    id="inbox-list"
                    style={{ marginTop: `${isPlatform("ios") ? "50px" : "5px"}` }}
                >
                    {items.map((appPage, index) => {
                        if (platform && appPage.title === "Folha de Diálise") {
                            return false;
                        }
                        return (
                            <IonMenuToggle key={index} autoHide={false}>
                                <IonItem
                                    className={
                                        location.pathname === appPage.url ? "selected" : ""
                                    }
                                    style={{
                                        "--padding-start": "10px",
                                        "--padding-end": "10px",
                                        padding: "2px",
                                        "--background": `${location.pathname === appPage.url
                                            ? "rgba(var(--ion-menu-selected-color-rgb), 0.14)"
                                            : "transparent"
                                            }`,
                                    }}
                                    routerLink={appPage.url}
                                    routerDirection="none"
                                    lines="none"
                                    detail={false}
                                >
                                    <IonIcon
                                        slot="start"
                                        icon={radioButtonOffOutline}
                                        style={{
                                            color: `${location.pathname === appPage.url
                                                ? "var(--ion-icon-color)"
                                                : "#000"
                                                }`,
                                        }}
                                    />
                                    <IonLabel>{appPage.title}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        );
                    })}
                    <IonItem
                        onClick={signOut}
                        lines="none"
                        detail={false}
                        style={{
                            "--padding-start": "10px",
                            "--padding-end": "10px",
                            padding: "2px",
                        }}
                    >
                        <IonIcon
                            slot="start"
                            icon={arrowForwardOutline}
                            style={{ color: "#000" }}
                        />
                        <IonLabel>Sair</IonLabel>
                    </IonItem>
                </IonList>
            </IonContent>
            <IonFooter
                style={{
                    padding: "10px 20px",
                    textAlign: "right",
                    borderTop: `3px solid ${appColor}`,
                }}
                mode="ios"
            >
                <IonHeader
                    style={{
                        fontSize: "20px",
                        fontWeight: 500,
                        minHeight: "20px",
                    }}
                >
                    {usuario.nome_completo} <br />
                    {usuario.perfil}
                </IonHeader>
                <IonNote
                    style={{
                        fontSize: "16px",
                        marginBottom: "18px",
                        color: `${appColor}`,
                        minHeight: "26px",
                    }}
                >
                    {usuario.nome_empresa} <br />
                    Versão: {appVersion}
                </IonNote>
            </IonFooter>
        </IonMenu>
    );
};

export default Menu;
