const procHomeCare = (proc: string) => {
    if ([
        'HEMODIÁLISE DOMICILIAR',
        'DIÁLISE PERITONIAL DOMICILIAR'
    ].includes(proc)) {
        return 'HC'
    } else {
        return 'H'
    }
}

export default procHomeCare;