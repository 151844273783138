import React from "react";

import {
  Container,
  Header,
  Span,
  H2,
  H3,
  H4,
  Logo,
  Grid,
  Th,
  Td,
  Annotations,
  Evolutions,
  Signatures,
  Text,
  Estoque
} from "./styles";

import LogoSrc from "./logo";
import {
  ContinuousProcType,
  PeritonealProcType
} from "../../../legacy/shared/procTypes";

interface Props {
  assistance: {
    id: number
    sheet: number
    patient_name: string
    patient_cpf: string
    patient_birth_date: string
    patient_sex: string
    agreement: string
    enrollment: string | number
    hospital: string
    date: string
    start: string
    start_date: string
    end: string
    end_date: string
    localization: string
    bed: string
    proc: string
    time: string
    access: string
    acesso_data: string;
    site: string
    catheter: string
    catheter_type: string
    catheter_change: string
    tenckhoff: string
    dialysis_sessions: number
    precaution: string
    hemodialysis_machine: string
    reverse_osmosis_machine: string
    capd_machine: string

    // Prescription
    priming: number
    kit: string
    capillary_type: string
    line_type: string
    uf: number
    blood_flow: number
    dialysate_flow: number
    replacement_flow: number
    weight: number
    temperature: number
    anticoagulation: string
    initial_citrate: string
    initial_calcium: string
    diagnosis: string
    use_of_vasoactive_amines: string
    amount_of_vasoactive_amines: number
    clinical_condition: string
    mechanical_ventilation: string
    hemodynamically: string
    total_volume: number
    infusion_volume: number
    last_infusion_volume: number
    length_of_stay: number
    bags_concentration: string
    ultrafiltration_hour: number
    heparin: number
    saline_solution: number
    catheter_sealant_type: number
    potassium_chloride: number
    sodium_phosphate: number
    bicarbonate_prescription: number
    sodium_prescription: number
    kdigo: number

    // Exams
    diuresis: number
    hydric_balance_signal: string
    hydric_balance: number
    creatinine: number
    urea: number
    potassium: number
    phosphor: number
    calcium: number
    pcr: number
    sodium: number
    chlorine: number
    ph: number
    bicarbonate: number
    be: number
    hemoglobin: number
    ht: number
    covid: string

    annotations: {
      id: number
      time: string
      partial_balance: number
      sf: number
      heparin: string
      citrate: number
      calcium: number
      pa: string
      pbe: number
      pv: number
      tf: number
      tmp: number
      fc: number
      tax: number
      arterial_pressure: number
      venous_pressure: number
      serum: number
      blood_flow: number
      dialysate_flow: number
    }[]

    evolutions: {
      id: number
      date: string
      text: string
    }[]

    estoque: {
      id_produto: number
      produto: string
      lote: string
      validade: string
      qtd: number
    }[]

    // Doctor
    doctor_name: string
    doctor_signature: string
    doctor_crm: string

    // Technicians
    technician1_id: number
    technician1_name: string
    technician1_coren: string
    technician1_signature: string

    technician2_id: number
    technician2_name: string
    technician2_coren: string
    technician2_signature: string

    technician3_id: number
    technician3_name: string
    technician3_coren: string
    technician3_signature: string

    technician4_id: number
    technician4_name: string
    technician4_coren: string
    technician4_signature: string
  }
  name: string
  date: string
  version: string
}

interface ExamsData {
  title: string
  value: number | string
}

interface PrescriptionData {
  title: string
  value: number | string
  title2?: string
  value2?: string
}

const appVersion = process.env.REACT_APP_VERSION

const DialysisSheetToPrint: React.FC<Props> = ({ assistance, name, date }) => {
  const Exams = () => {
    let examsRows: ExamsData[] = []

    if (!!assistance.diuresis)
      examsRows.push({
        title: "DIURESE (ml/24): ",
        value: assistance.diuresis
      })

    if (
      (assistance.hydric_balance_signal === "POSITIVO" ||
        assistance.hydric_balance_signal === "NEGATIVO") &&
      !!assistance.hydric_balance
    ) {
      examsRows.push({
        title: "BH (ml/24): ",
        value: `${assistance.hydric_balance_signal} ${assistance.hydric_balance}`
      })
    }

    if (!!assistance.creatinine)
      examsRows.push({
        title: "CREATININA (mg/dl): ",
        value: assistance.creatinine
      })

    if (!!assistance.urea)
      examsRows.push({
        title: "UREIA (mg/dl): ",
        value: assistance.urea
      })

    if (!!assistance.potassium)
      examsRows.push({
        title: "POTÁSSIO (mEq/l): ",
        value: assistance.potassium
      })

    if (!!assistance.phosphor)
      examsRows.push({
        title: "FÓSFORO (mg/dl): ",
        value: assistance.phosphor
      })

    if (!!assistance.calcium)
      examsRows.push({
        title: "CÁLCIO (mg/dl): ",
        value: assistance.calcium
      })

    if (!!assistance.pcr)
      examsRows.push({
        title: "PCR (Unidade): ",
        value: assistance.pcr
      })

    if (!!assistance.sodium)
      examsRows.push({
        title: "SÓDIO (mEq/l): ",
        value: assistance.sodium
      })

    if (!!assistance.chlorine)
      examsRows.push({
        title: "CLORO (mEq/l): ",
        value: assistance.chlorine
      })

    if (!!assistance.ph)
      examsRows.push({
        title: "Ph",
        value: assistance.ph
      })

    if (!!assistance.bicarbonate)
      examsRows.push({
        title: "BICARBONATO (mEq/l): ",
        value: assistance.bicarbonate
      })

    if (!!assistance.be)
      examsRows.push({
        title: "BE (Unidade): ",
        value: assistance.be
      })

    if (!!assistance.hemoglobin)
      examsRows.push({
        title: "HEMOGLOBINA (G/dl): ",
        value: assistance.hemoglobin
      })

    if (!!assistance.ht)
      examsRows.push({
        title: "HT (%): ",
        value: assistance.ht
      })

    if (!!assistance.covid)
      examsRows.push({
        title: "COVID: ",
        value: assistance.covid
      })

    let rows = []
    for (let i = 0; i < examsRows.length; i += 4) {
      rows.push(
        <tr>
          <Td index={1} columns={4}>
            {!!examsRows[i] && (
              <>
                <strong>{examsRows[i].title}</strong>
                {examsRows[i].value}
              </>
            )}
          </Td>
          <Td index={2} columns={4}>
            {!!examsRows[i + 1] && (
              <>
                <strong>{examsRows[i + 1].title}</strong>
                {examsRows[i + 1].value}
              </>
            )}
          </Td>
          <Td index={3} columns={4}>
            {!!examsRows[i + 2] && (
              <>
                <strong>{examsRows[i + 2].title}</strong>
                {examsRows[i + 2].value}
              </>
            )}
          </Td>
          <Td index={4} columns={4}>
            {!!examsRows[i + 3] && (
              <>
                <strong>{examsRows[i + 3].title}</strong>
                {examsRows[i + 3].value}
              </>
            )}
          </Td>
        </tr>
      )
    }

    return rows
  }

  const Prescription = () => {
    let prescriptionRows: PrescriptionData[] = []

    if (ContinuousProcType.includes(assistance.proc)) {
      if (!!assistance.priming)
        prescriptionRows.push({
          title: "PRIMING: ",
          value: assistance.priming
        })

      if (!!assistance.capillary_type)
        prescriptionRows.push({
          title: "TIPO DE CAPILAR: ",
          value: assistance.capillary_type
        })

      if (!!assistance.line_type)
        prescriptionRows.push({
          title: "TIPO DE LINHA: ",
          value: assistance.line_type
        })

      if (!!assistance.kit)
        prescriptionRows.push({
          title: "KIT: ",
          value: assistance.kit
        })

      if (!!assistance.uf)
        prescriptionRows.push({
          title: "UF (mL por sessão): ",
          value: assistance.uf
        })

      if (!!assistance.blood_flow)
        prescriptionRows.push({
          title: "FLUXO DE SANGUE (ml/min): ",
          value: assistance.blood_flow
        })

      if (!!assistance.dialysate_flow)
        prescriptionRows.push({
          title: "FLUXO DE DIALISATO (ml/h): ",
          value: assistance.dialysate_flow
        })

      if (!!assistance.replacement_flow)
        prescriptionRows.push({
          title: "FLUXO DE REPOSIÇÃO (ml/h): ",
          value: assistance.replacement_flow
        })

      if (!!assistance.weight)
        prescriptionRows.push({
          title: "PESO (Kg): ",
          value: assistance.weight
        })

      if (!!assistance.temperature)
        prescriptionRows.push({
          title: "TEMPERATURA: ",
          value: assistance.temperature
        })

      if (!!assistance.anticoagulation)
        prescriptionRows.push({
          title: "ANTICOAGULAÇÃO: ",
          value: assistance.anticoagulation
        })

      if (!!assistance.initial_citrate)
        prescriptionRows.push({
          title: "DOSE INICIAL CITRATO: ",
          value: assistance.initial_citrate
        })

      if (!!assistance.initial_calcium)
        prescriptionRows.push({
          title: "DOSE INICIAL CÁLCIO: ",
          value: assistance.initial_calcium
        })
    } else if (PeritonealProcType.includes(assistance.proc)) {
      if (!!assistance.priming)
        prescriptionRows.push({
          title: "PRIMING: ",
          value: assistance.priming
        })

      if (!!assistance.uf)
        prescriptionRows.push({
          title: "UF (mL por sessão): ",
          value: assistance.uf
        })

      if (!!assistance.total_volume)
        prescriptionRows.push({
          title: "VOLUME TOTAL (ml): ",
          value: assistance.total_volume
        })

      if (!!assistance.infusion_volume)
        prescriptionRows.push({
          title: "VOLUME DE INFUSÃO (ml): ",
          value: assistance.infusion_volume
        })

      if (!!assistance.last_infusion_volume)
        prescriptionRows.push({
          title: "VOLUME DA ÚLTIMA INFUSÃO (ml): ",
          value: assistance.last_infusion_volume
        })

      if (!!assistance.length_of_stay)
        prescriptionRows.push({
          title: "TEMPO DE PERMANÊNCIA: ",
          value: assistance.length_of_stay
        })

      if (!!assistance.heparin)
        prescriptionRows.push({
          title: "HEPARINA: ",
          value: assistance.heparin
        })

      if (!!assistance.bags_concentration)
        prescriptionRows.push({
          title: "CONCENTRAÇÃO DE BOLSAS: ",
          value: assistance.bags_concentration
        })
    } else {
      if (!!assistance.priming)
        prescriptionRows.push({
          title: "PRIMING: ",
          value: assistance.priming
        })

      if (!!assistance.capillary_type)
        prescriptionRows.push({
          title: "TIPO DE CAPILAR: ",
          value: assistance.capillary_type
        })

      if (!!assistance.blood_flow)
        prescriptionRows.push({
          title: "FLUXO DE SANGUE (ml/min): ",
          value: assistance.blood_flow
        })

      if (!!assistance.dialysate_flow)
        prescriptionRows.push({
          title: "FLUXO DE DIALISATO (ml/h): ",
          value: assistance.dialysate_flow
        })

      if (!!assistance.ultrafiltration_hour)
        prescriptionRows.push({
          title: "ULTRAFILTRAÇÃO (ml/h): ",
          value: assistance.ultrafiltration_hour
        })

      if (!!assistance.uf)
        prescriptionRows.push({
          title: "UF (mL por sessão): ",
          value: assistance.uf
        })

      if (!!assistance.heparin)
        prescriptionRows.push({
          title: "HEPARINA: ",
          value: assistance.heparin
        })

      if (!!assistance.saline_solution)
        prescriptionRows.push({
          title: "SORO FISIOLÓGICO (ml/min): ",
          value: assistance.saline_solution
        })

      if (!!assistance.catheter_sealant_type)
        prescriptionRows.push({
          title: "TIPO DE SELANTE DO CATETER: ",
          value: assistance.catheter_sealant_type
        })

      if (!!assistance.potassium_chloride)
        prescriptionRows.push({
          title: "KCl (Ampola): ",
          value: assistance.potassium_chloride
        })

      if (!!assistance.sodium_phosphate)
        prescriptionRows.push({
          title: "FOSFATO DE SÓDIO (SACHE): ",
          value: assistance.sodium_phosphate
        })

      if (!!assistance.bicarbonate_prescription)
        prescriptionRows.push({
          title: "BICARBONATO: ",
          value: assistance.bicarbonate_prescription
        })

      if (!!assistance.sodium_prescription)
        prescriptionRows.push({
          title: "SÓDIO: ",
          value: assistance.sodium_prescription
        })

      if (!!assistance.temperature)
        prescriptionRows.push({
          title: "TEMPERATURA: ",
          value: assistance.temperature
        })

      if (!!assistance.kdigo)
        prescriptionRows.push({
          title: "KDIGO: ",
          value: assistance.kdigo
        })
    }

    if (!!assistance.diagnosis)
      prescriptionRows.push({
        title: "DIAGNÓSTICO: ",
        value: assistance.diagnosis
      })

    if (
      assistance.use_of_vasoactive_amines === "SIM" &&
      !!assistance.amount_of_vasoactive_amines
    ) {
      prescriptionRows.push({
        title: "USO DE AMINAS VASOATIVAS: ",
        value: `${assistance.use_of_vasoactive_amines}`,
        title2: "QUANTIDADE (ml/h):",
        value2: `${assistance.amount_of_vasoactive_amines}`
      })
    }

    if (!!assistance.clinical_condition)
      prescriptionRows.push({
        title: "QUADRO CLÍNICO: ",
        value: assistance.clinical_condition
      })

    if (!!assistance.mechanical_ventilation)
      prescriptionRows.push({
        title: "VENTILAÇÃO MECÂNICA: ",
        value: assistance.mechanical_ventilation
      })

    if (!!assistance.hemodynamically)
      prescriptionRows.push({
        title: "HEMODINAMICAMENTE: ",
        value: assistance.hemodynamically
      })

    let rows = []
    for (let i = 0; i < prescriptionRows.length; i += 4) {
      rows.push(
        <tr>
          <Td index={1} columns={4}>
            {!!prescriptionRows[i] && (
              <>
                <strong>{prescriptionRows[i].title}</strong>
                {prescriptionRows[i].value}{" "}
                <strong>{prescriptionRows[i].title2}</strong>
                {prescriptionRows[i].value2}
              </>
            )}
          </Td>
          <Td index={2} columns={4}>
            {!!prescriptionRows[i + 1] && (
              <>
                <strong>{prescriptionRows[i + 1].title}</strong>
                {prescriptionRows[i + 1].value}{" "}
                <strong>{prescriptionRows[i + 1].title2}</strong>
                {prescriptionRows[i + 1].value2}
              </>
            )}
          </Td>
          <Td index={3} columns={4}>
            {!!prescriptionRows[i + 2] && (
              <>
                <strong>{prescriptionRows[i + 2].title}</strong>
                {prescriptionRows[i + 2].value}{" "}
                <strong>{prescriptionRows[i + 2].title2}</strong>
                {prescriptionRows[i + 2].value2}
              </>
            )}
          </Td>
          <Td index={4} columns={4}>
            {!!prescriptionRows[i + 3] && (
              <>
                <strong>{prescriptionRows[i + 3].title}</strong>
                {prescriptionRows[i + 3].value}{" "}
                <strong>{prescriptionRows[i + 3].title2}</strong>
                {prescriptionRows[i + 3].value2}
              </>
            )}
          </Td>
        </tr>
      )
    }

    if (
      !!assistance.hemodialysis_machine ||
      !!assistance.reverse_osmosis_machine ||
      !!assistance.capd_machine
    ) {
      rows.push(
        <tr>
          {!!assistance.hemodialysis_machine && (
            <Td index={1} columns={4} color="#007bff">
              <strong>
                MÁQUINA DE HEMODIÁLISE: {assistance.hemodialysis_machine}
              </strong>
            </Td>
          )}
          {!!assistance.reverse_osmosis_machine && (
            <Td index={2} columns={4} color="#007bff">
              <strong>
                MÁQUINA DE OSMOSE REVERSA: {assistance.reverse_osmosis_machine}
              </strong>
            </Td>
          )}
          {!!assistance.capd_machine && (
            <Td index={3} columns={4} color="#007bff">
              <strong>
                MÁQUINA DIÁLISE PERITONEAl: {assistance.capd_machine}
              </strong>
            </Td>
          )}
          {!assistance.hemodialysis_machine && <Td index={1} columns={4}></Td>}
          {!assistance.reverse_osmosis_machine && (
            <Td index={2} columns={4}></Td>
          )}
          {!assistance.capd_machine && <Td index={3} columns={4}></Td>}
          <Td index={4} columns={4}></Td>
        </tr>
      )
    }

    rows.push(
      <tr>
        <Td index={1} columns={4} color="#007bff">
          <strong>DATA PRESCRITA: {assistance.date}</strong>
        </Td>
        <Td index={2} columns={4} color="#007bff">
          <strong>
            INÍCIO DO PROCEDIMENTO: {assistance.start_date} {assistance.start}
          </strong>
        </Td>
        <Td index={3} columns={4} color="#007bff">
          <strong>
            TÉRMINO DO PROCEDIMENTO: {assistance.end_date} {assistance.end}
          </strong>
        </Td>
        <Td index={4} columns={4}></Td>
      </tr>
    )

    return rows
  }

  return (
    <Container>
      <Header>
        <thead>
          <tr>
            <Logo>
              <img width="136" src={LogoSrc} alt="Logo" />
            </Logo>
            <th>
              <H2>HEMODIÁLISE - PRONTUÁRIO</H2>
              <H3>TERAPIA SUBSTITUTIVA - INTRA-HOSPITALAR</H3>
            </th>
            <th style={{ width: "150px" }}>
              <H3 color="#dc3545">
                {!!assistance.sheet ? assistance.sheet : assistance.id}
              </H3>
            </th>
          </tr>
        </thead>
      </Header>
      <Grid>
        <tbody>
          <tr>
            <Td index={1} columns={3}>
              <strong>NOME DO PACIENTE: </strong>
              {assistance.patient_name}
            </Td>
            <Td index={2} columns={3}>
              <strong>CPF: </strong>
              {assistance.patient_cpf}
            </Td>
            <Td index={3} columns={3}>
              <strong>DATA DE NASC.: </strong>
              {assistance.patient_birth_date}
            </Td>
          </tr>
          <tr>
            <Td index={1} columns={3} colSpan={2}>
              <strong>CONVÊNIO: </strong>
              {assistance.agreement}
            </Td>
            <Td index={3} columns={3}>
              <strong>MATR.: </strong>
              {assistance.enrollment}
            </Td>
          </tr>
          <tr>
            <Td index={1} columns={3} colSpan={2}>
              <strong>HOSPITAL: </strong>
              {assistance.hospital}
            </Td>
            <Td index={3} columns={3}>
              <strong>DATA INT.: </strong>
              {assistance.date}
            </Td>
          </tr>
          <tr>
            <Td index={1} columns={3}>
              <strong>LOCALIZAÇÃO: </strong>
              {assistance.localization}
            </Td>
            <Td index={2} columns={3}>
              <strong>LEITO: </strong>
              {assistance.bed}
            </Td>
            <Td index={3} columns={3}>
              <strong>SEXO: </strong>
              {assistance.patient_sex}
            </Td>
          </tr>
          <tr>
            <Td index={1} columns={3}>
              <strong>PROCEDIMENTO: </strong>
              {assistance.proc}
            </Td>
            <Td index={2} columns={3}>
              <strong>TEMPO: </strong>
              {assistance.time}
            </Td>
            <Td index={3} columns={3}>
              <strong>ACESSO: </strong>
              {assistance.access}
            </Td>
          </tr>
          <tr>
            <Td index={1} columns={3}>
              <strong>ACESSO: </strong>
              {assistance.acesso_data}
            </Td>
            <Td index={2} columns={3}>
              <strong>SITIO: </strong>
              {assistance.site}
            </Td>
            <Td index={3} columns={3}>
              <strong>IMPLANTOU CATETER: </strong>
              {assistance.catheter}
            </Td>
          </tr>
          {assistance.catheter === "SIM" && (
            <tr>
              <Td index={1} columns={3}>
                <strong>CATETER TIPO: </strong>
                {assistance.catheter_type}
              </Td>
              <Td index={2} columns={3}>
                {assistance.catheter_type === "TROCA" && (
                  <>
                    <strong>MOTIVO DA TROCA: </strong>
                    {assistance.catheter_change}
                  </>
                )}
              </Td>
              <Td index={3} columns={3}>
                {assistance.proc.substr(0, 2) === "DP" && (
                  <>
                    <strong>TENCKHOFF: </strong>
                    {assistance.tenckhoff}
                  </>
                )}
              </Td>
            </tr>
          )}
          <tr>
            <Td index={1} columns={3}>
              <strong>SESSÕES DE DIÁLISE: </strong>
              {assistance.dialysis_sessions}
            </Td>
            <Td index={3} columns={3} colSpan={2}>
              <strong>PRECAUÇÃO: </strong>
              {assistance.precaution}
            </Td>
          </tr>
        </tbody>
      </Grid>
      <Grid marginTop="10px">
        <thead style={{ textAlign: "center" }}>
          <tr>
            <Th colSpan={4}>
              <H4>
                <strong>PRESCRIÇÃO</strong>
              </H4>
            </Th>
          </tr>
        </thead>
        <tbody>{Prescription()}</tbody>
      </Grid>
      <Grid marginTop="10px">
        <thead style={{ textAlign: "center" }}>
          <tr>
            <Th colSpan={4}>
              <H4>
                <strong>EXAMES</strong>
              </H4>
            </Th>
          </tr>
        </thead>
        <tbody>{Exams()}</tbody>
      </Grid>
      {!!assistance.annotations && (
        <Annotations>
          <thead>
            <tr>
              <Th colSpan={11}>
                <H4>
                  <strong>ANOTAÇÕES DE ENFERMAGEM</strong>
                </H4>
              </Th>
            </tr>
            <tr>
              <Th rowSpan={2}>Hora</Th>
              {ContinuousProcType.includes(assistance.proc) ? (
                <>
                  <Th rowSpan={2}>Balanço Parcial</Th>
                  <Th colSpan={4}>Anticoagulação</Th>
                  <Th colSpan={5}>Pressões</Th>
                </>
              ) : (
                <>
                  <Th>PA(mmHg)</Th>
                  <Th>FC(bpm)</Th>
                  <Th>Tax(ºC)</Th>
                  <Th>Solução de Heparina</Th>
                  <Th>P.Art(mmHg)</Th>
                  <Th>P.Ven(mmHg)</Th>
                  <Th>Soro(ml)</Th>
                  <Th>Fluxo Sanguíneo(ml/min)</Th>
                  <Th>Fluxo Dialisato(ml/min)</Th>
                </>
              )}
            </tr>
            {ContinuousProcType.includes(assistance.proc) && (
              <tr>
                <Th>SF 0,9%</Th>
                <Th>Heparina</Th>
                <Th>Citrato</Th>
                <Th>Cálcio</Th>
                <Th>PA(mmHg)</Th>
                <Th>PBE</Th>
                <Th>PV</Th>
                <Th>TF</Th>
                <Th>TMP</Th>
              </tr>
            )}
          </thead>
          <tbody>
            {assistance.annotations.map(annotation => (
              <tr key={annotation.id}>
                <Td>{annotation.time}</Td>
                {ContinuousProcType.includes(assistance.proc) ? (
                  <>
                    <Td>{annotation.partial_balance}</Td>
                    <Td>{annotation.sf}</Td>
                    <Td>{annotation.heparin}</Td>
                    <Td>{annotation.citrate}</Td>
                    <Td>{annotation.calcium}</Td>
                    <Td>{annotation.pa}</Td>
                    <Td>{annotation.pbe}</Td>
                    <Td>{annotation.pv}</Td>
                    <Td>{annotation.tf}</Td>
                    <Td>{annotation.tmp}</Td>
                  </>
                ) : (
                  <>
                    <Td>{annotation.pa}</Td>
                    <Td>{annotation.fc}</Td>
                    <Td>{annotation.tax}</Td>
                    <Td>{annotation.heparin}</Td>
                    <Td>{annotation.arterial_pressure}</Td>
                    <Td>{annotation.venous_pressure}</Td>
                    <Td>{annotation.serum}</Td>
                    <Td>{annotation.blood_flow}</Td>
                    <Td>{annotation.dialysate_flow}</Td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </Annotations>
      )}
      {!!assistance.evolutions && (
        <Evolutions>
          <thead>
            <tr>
              <Th colSpan={2}>
                <H4>
                  <strong>EVOLUÇÃO DE ENFERMAGEM</strong>
                </H4>
              </Th>
            </tr>
            <tr>
              <Th>Hora</Th>
              <Th>Evolução</Th>
            </tr>
          </thead>
          <tbody>
            {assistance.evolutions.map(evolution => (
              <tr key={evolution.id}>
                <Td>{evolution.date}</Td>
                <Td>{evolution.text}</Td>
              </tr>
            ))}
          </tbody>
        </Evolutions>
      )}
      {!!assistance.estoque && (
        <Estoque>
          <thead>
            <tr>
              <Th colSpan={4}>
                <H4>
                  <strong>CONTROLE DE ESTOQUE</strong>
                </H4>
              </Th>
            </tr>
            <tr>
              <Th>Produto</Th>
              <Th>Lote</Th>
              <Th>Validade</Th>
              <Th>Quantidade</Th>
            </tr>
          </thead>
          <tbody>
            {assistance.estoque.map((estoque) => (
              <tr key={estoque.id_produto}>
                <Td>{estoque.produto}</Td>
                <Td>{estoque.lote}</Td>
                <Td>{estoque.validade}</Td>
                <Td>{estoque.qtd}</Td>
              </tr>
            ))}
          </tbody>
        </Estoque>
      )}
      <Signatures>
        <thead>
          <tr>
            <Th textAlign="left" borderWidth="0px 1px 0px 1px">
              <Text>
                MÉDICO: <Span color="#007bff">{assistance.doctor_name}</Span>
              </Text>
              <Text>
                CRM: <Span color="#007bff">{assistance.doctor_crm}</Span>
              </Text>
            </Th>
            <Th textAlign="left" borderWidth="0px 1px 0px 1px">
              <Text>
                TÉCNICO(A) DE ENFERMAGEM:{" "}
                <Span color="#007bff">{assistance.technician1_name}</Span>
              </Text>
              <Text>
                COREN:{" "}
                <Span color="#007bff">{assistance.technician1_coren}</Span>
              </Text>
            </Th>
            <Th textAlign="left" borderWidth="0px 1px 0px 1px">
              {assistance.technician2_id && (
                <>
                  <Text>
                    TÉCNICO(A) DE ENFERMAGEM:{" "}
                    <Span color="#007bff">{assistance.technician2_name}</Span>
                  </Text>
                  <Text>
                    COREN:{" "}
                    <Span color="#007bff">{assistance.technician2_coren}</Span>
                  </Text>
                </>
              )}
            </Th>
          </tr>
          <tr style={{ height: "70px" }}>
            <Td textAlign="center" borderWidth="0px 1px 1px 1px">
              {!!assistance.doctor_signature && (
                <img
                  style={{ maxHeight: "60px", maxWidth: "100%" }}
                  alt="assinatura"
                  src={`${assistance.doctor_signature}`}
                />
              )}
            </Td>
            <Td textAlign="center" borderWidth="0px 1px 1px 1px">
              {!!assistance.technician1_signature && (
                <img
                  style={{ maxHeight: "60px", maxWidth: "100%" }}
                  alt="assinatura"
                  src={`${assistance.technician1_signature}`}
                />
              )}
            </Td>
            <Td textAlign="center" borderWidth="0px 1px 1px 1px">
              {assistance.technician2_id > 0 &&
                !!assistance.technician2_signature && (
                  <img
                    style={{ maxHeight: "60px", maxWidth: "100%" }}
                    alt="assinatura"
                    src={`${assistance.technician2_signature}`}
                  />
                )}
            </Td>
          </tr>
          {assistance.technician3_id > 0 && (
            <>
              <tr>
                <Th textAlign="left" borderWidth="0px 1px 0px 1px">
                  <Text>
                    TÉCNICO(A) DE ENFERMAGEM:{" "}
                    <Span color="#007bff">{assistance.technician3_name}</Span>
                  </Text>
                  <Text>
                    COREN:{" "}
                    <Span color="#007bff">{assistance.technician3_coren}</Span>
                  </Text>
                </Th>
                <Th textAlign="left" borderWidth="0px 1px 0px 1px">
                  {assistance.technician4_id > 0 && (
                    <>
                      <Text>
                        TÉCNICO(A) DE ENFERMAGEM:{" "}
                        <Span color="#007bff">
                          {assistance.technician4_name}
                        </Span>
                      </Text>
                      <Text>
                        COREN:{" "}
                        <Span color="#007bff">
                          {assistance.technician4_coren}
                        </Span>
                      </Text>
                    </>
                  )}
                </Th>
                <Th textAlign="left" borderWidth="0px 1px 0px 1px"></Th>
              </tr>
              <tr style={{ height: "70px" }}>
                <Td textAlign="center" borderWidth="0px 1px 1px 1px">
                  {!!assistance.technician3_signature && (
                    <img
                      style={{ maxHeight: "60px", maxWidth: "100%" }}
                      alt="assinatura"
                      src={`${assistance.technician3_signature}`}
                    />
                  )}
                </Td>
                <Td textAlign="center" borderWidth="0px 1px 1px 1px">
                  {assistance.technician4_id > 0 &&
                    !!assistance.technician4_signature && (
                      <img
                        style={{ maxHeight: "60px", maxWidth: "100%" }}
                        alt="assinatura"
                        src={`${assistance.technician4_signature}`}
                      />
                    )}
                </Td>
                <Td textAlign="center" borderWidth="0px 1px 1px 1px">
                  {" "}
                </Td>
              </tr>
            </>
          )}
        </thead>
      </Signatures>
      <Text>
        Impressão realizada pelo usuário <strong>{name}</strong> na data{" "}
        <strong>{date}</strong> na versão{" "}
        <strong>{appVersion} @ APP</strong>
      </Text>
    </Container>
  )
}

export default DialysisSheetToPrint
