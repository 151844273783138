import Yup from "../../../services/schema";

import tipoProcedimento from "../../../helpers/tipoProcedimento";

export default Yup.object().shape({
  relatorio_medico: Yup.string()
    .nullable()
    .when("proc", {
      is: (value: string) =>
        value === 'VISITA HOSPITALAR' ||
        value === 'PARECER' ||
        tipoProcedimento(value) === "C",
      then: Yup.string().required().label("relatório médico")
    })
    .when("sessoes_dialise", {
      is: (value: string) => parseInt(value) === 0 || ((parseInt(value) + 1) % 5) === 0,
      then: Yup.string().required().label("relatório médico")
    }),
  classificacao: Yup.number().min(0).required().label("classificação"),
  outra_doenca_pre_existente: Yup.string()
    .nullable()
    .when("doencas_pre_existentes", {
      is: (value: string[]) => !!value && value.includes("OUTROS"),
      then: Yup.string().required().label("qual outra doença pré existente?")
    }),
  doencas_pre_existentes: Yup.mixed()
    .nullable()
    .when("diagnostico", {
      is: (value: string) =>
        value === "IRA" || value === "DRC 5D" || value === "DRC (A)",
      then: Yup.array().min(1).required().label("doenças pré existentes")
    }),
  diagnostico_causa: Yup.mixed()
    .nullable()
    .when("diagnostico", {
      is: (value: string) => value === "IRA" || value === "DRC (A)",
      then: Yup.array().min(1).required().label("causa")
    }),
  diagnostico: Yup.string().required().label("diagnóstico"),
  indice_captacao: Yup.string().required().label("índice de captação"),
  precaucao: Yup.string().oneOf(["Y", "N"]).required().label("precaução"),
  cateter_motivo: Yup.string()
    .nullable()
    .when("cateter_tipo", {
      is: (value: string) => value === "T",
      then: Yup.string().required().label("motivo da troca")
    }),
  cateter_tipo: Yup.string()
    .nullable()
    .when("cateter", {
      is: (value: string) => value === "S",
      then: Yup.string().oneOf(["N", "T"]).required().label("cateter tipo")
    }),
  cateter: Yup.string().oneOf(["S", "N"]).required().label("implantou cateter?"),
  sitio: Yup.string()
    .nullable()
    .when("acesso", {
      is: (value: string) => value !== "" && value !== "TENCKHOFF" && value !== "A DEFINIR",
      then: Yup.string().required().label("sitio")
    }),
  acesso_data: Yup.string()
    .nullable()
    .when("acesso", {
      is: (value: string) => value !== "" && value !== "A DEFINIR",
      then: Yup.string().required().label("data de confecção/implante do acesso venosso")
    }),
  acesso: Yup.string().required().label("acesso"),
  tempo: Yup.string().required().label("tempo"),
  proc: Yup.string().required().label("procedimento"),
  leito: Yup.string().required().label("leito"),
  localizacao: Yup.string().required().label("localização"),
  id_convenio: Yup.number().required().label("convênio"),
  id_hospital: Yup.number().required().label("hospital"),
  pediatrico: Yup.string().oneOf(["S", "N"]).required().label("pediátrico"),
  data: Yup.string().required().label("data"),
  id_paciente: Yup.number().required().label("paciente")
})
