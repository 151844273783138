import { useRef, useState } from "react";
import {
    IonButton,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonLabel,
    IonRow,
    IonText
} from "@ionic/react";
import { format } from "date-fns";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import api from "../../../services/api";

import { useLoading } from "../../../hooks/loading";
import { useToast } from "../../../hooks/toast";
import { useValidation } from "../../../hooks/validation";

import Main from "../../../components/Main";
import Card from "../../../components/Card";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import ReactSelect from "../../../components/ReactSelect";

const sessionName = process.env.REACT_APP_SESSION_NAME

interface Rows {
    id: number;
    data: string;
    hospital: string;
    tipo: string;
    deletar: "S" | "N";
}

const MedicoRegistrarPlantao = () => {
    const { showLoading, hideLoading } = useLoading();
    const { successToast } = useToast();
    const { getValidationErrors } = useValidation();

    const formRef = useRef<FormHandles>(null);
    const consultRef = useRef<FormHandles>(null);
    const [total, setTotal] = useState<number>(0);
    const [rows, setRows] = useState<Rows[]>([]);

    const formDefaultValues = () => {
        return {
            data: format(new Date(), "yyyy-MM-dd")
        }
    }

    const consultDefaultValues = () => {
        return JSON.parse(
            `${localStorage.getItem(
                `@${sessionName}:MedicoRegistrarPlantao`
            ) || "{}"
            }`
        )
    }

    const handleSubmit: SubmitHandler = async data => {
        showLoading();
        await api
            .post("medico/registrar-plantao", data)
            .then(_response => {
                successToast();
                formRef.current?.reset(formDefaultValues());
                if (total > 0) {
                    getList();
                }
            })
            .catch(error => {
                getValidationErrors(error.response);
            })
        hideLoading();
    }

    const getList = async () => {
        const data = consultDefaultValues();

        showLoading();
        await api
            .get("medico/registrar-plantao", {
                params: {
                    id_hospital: data?.id_hospital?.value,
                    mes: data?.mes
                }
            })
            .then(response => {
                const { rows, total } = response.data;

                if (total) {
                    setTotal(total);
                }

                if (rows) {
                    setRows(rows);
                }
            })
            .catch(error => {
                getValidationErrors(error.response);
            })
        hideLoading();
    }

    const handleConsult: SubmitHandler = async data => {
        localStorage.setItem(
            `@${sessionName}:MedicoRegistrarPlantao`,
            JSON.stringify(data)
        )
        getList()
    }

    const handleDelete = async (id: number) => {
        showLoading();
        await api.delete(`medico/registrar-plantao/${id}`).then(_response => {
            successToast()
            getList()
        })
        hideLoading();
    }

    return (
        <Main title="Registrar Plantão">
            <Card>
                <IonCardContent>
                    <IonGrid>
                        <Form
                            ref={formRef}
                            initialData={formDefaultValues()}
                            onSubmit={handleSubmit}
                        >
                            <IonRow className="ion-align-items-end">
                                <ReactSelect
                                    label="Hospital"
                                    name="id_hospital"
                                    url="/medico/select/hospital"
                                    params={{ status: "A" }}
                                    required
                                    simple
                                />
                                <Input
                                    label="Data"
                                    name="data"
                                    type="date"
                                    max={format(new Date(), "yyyy-MM-dd")}
                                    required
                                />
                                <Select
                                    label="Plantão"
                                    name="plantao"
                                    values={[
                                        { value: "SA", label: "Sobreaviso" },
                                        { value: "MA", label: "Manhã" },
                                        { value: "TA", label: "Tarde" },
                                        { value: "NO", label: "Noite" },
                                    ]}
                                    required
                                />
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="6">
                                    <IonButton type="submit" color="primary" expand="block">
                                        Salvar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </Form>
                    </IonGrid>
                </IonCardContent>
            </Card>
            <Card>
                <IonCardContent>
                    <IonGrid>
                        <Form
                            ref={consultRef}
                            initialData={consultDefaultValues()}
                            onSubmit={handleConsult}
                        >
                            <IonRow className="ion-align-items-end">
                                <ReactSelect
                                    label="Hospital"
                                    name="id_hospital"
                                    url="/medico/select/hospital"
                                    required
                                />
                                <Input
                                    label="Mês"
                                    name="mes"
                                    type="month"
                                    max={format(new Date(), "yyyy-MM")}
                                    required
                                />
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="6">
                                    <IonButton type="submit" color="primary" expand="block">
                                        Consultar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </Form>
                    </IonGrid>
                </IonCardContent>
            </Card>
            {rows.length > 0 && (
                <>
                    <Card>
                        <IonCardHeader>
                            <IonCardTitle>
                                <IonRow>
                                    <IonCol size="12">
                                        <IonLabel>Visitas: </IonLabel>
                                        <IonText>{total}</IonText>
                                    </IonCol>
                                </IonRow>
                            </IonCardTitle>
                        </IonCardHeader>
                    </Card>
                    {rows.map(line => {
                        return (
                            <Card key={line.id}>
                                <IonCardHeader>
                                    <IonCardTitle>
                                        <IonRow>
                                            <IonCol size="12">
                                                <IonLabel>Data: </IonLabel>
                                                <IonText>{line.data}</IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonLabel>Hospital: </IonLabel>
                                            <IonText>{line.hospital}</IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonLabel>Tipo </IonLabel>
                                            <IonText>{line.tipo}</IonText>
                                        </IonCol>
                                    </IonRow>
                                    {line.deletar === "S" && (
                                        <IonRow>
                                            <IonCol size="6">
                                                <IonButton
                                                    type="button"
                                                    color="danger"
                                                    expand="block"
                                                    onClick={() => handleDelete(line.id)}
                                                >
                                                    Deletar
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                </IonCardContent>
                            </Card>
                        )
                    })}
                </>
            )}
        </Main>
    )
}

export default MedicoRegistrarPlantao
