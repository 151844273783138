import { IonCol, IonLabel, IonText } from "@ionic/react"

interface Props {
    show?: boolean;
    label: string;
    value: string;
}

const Item = ({ show = true, label, value }: Props) => {
    return (
        show && !!value ?
            <IonCol size="12" className="ion-no-padding">
                <IonLabel>{label}</IonLabel>
                <IonText>{value}</IonText>
            </IonCol>
            :
            <></>
    )
}

export default Item;