import { useEffect, useRef, useState } from "react";
import {
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonCardHeader,
    IonCardTitle,
    IonLabel,
    IonText,
} from "@ionic/react";

import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import api from "../../../services/api";

import { useLoading } from "../../../hooks/loading";
import { useValidation } from "../../../hooks/validation";

import Main from "../../../components/Main";
import Card from "../../../components/Card";
import ReactSelect from "../../../components/ReactSelect";

import Formulario from './Formulario'

const sessionName = process.env.REACT_APP_SESSION_NAME;

export interface Row {
    id: number;
    data: string;
    id_paciente: number;
    nome_paciente: string;
    destino: string;
    periodicidade_prevista: string;
}

const MedicoEmTratamento = () => {
    const { showLoading, hideLoading } = useLoading();
    const { getValidationErrors } = useValidation();

    const consultRef = useRef<FormHandles>(null);
    const [lastUpdate, setLastUpdate] = useState<Date | null>(null);
    const [rows, setRows] = useState<Row[]>([]);

    const consultValues = () => {
        return JSON.parse(
            `${localStorage.getItem(
                `@${sessionName}:MedicoEmTratamento`
            ) || "{}"
            }`
        )
    }

    const handleConsult: SubmitHandler = async (values) => {
        localStorage.setItem(
            `@${sessionName}:MedicoEmTratamento`,
            JSON.stringify(values)
        )

        showLoading();
        await api
            .get("medico/em-tratamento", {
                params: {
                    id_hospital: values?.id_hospital?.value
                }
            })
            .then(response => {
                setRows(response.data)
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
        hideLoading();
    }

    useEffect(() => {
        if (!!lastUpdate) {
            consultRef?.current?.submitForm();
        }
    }, [lastUpdate])

    return (
        <Main title="Em Tratamento">
            <Card>
                <IonCardContent>
                    <Form
                        ref={consultRef}
                        initialData={consultValues()}
                        onSubmit={handleConsult}
                    >
                        <IonGrid>
                            <IonRow className="ion-align-items-end">
                                <ReactSelect
                                    label="Hospital"
                                    name="id_hospital"
                                    url="/medico/select/hospital"
                                    params={{ status: "A" }}
                                    required
                                />
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="12">
                                    <IonButton type="submit" color="primary" expand="block">
                                        Consultar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </Form>
                </IonCardContent>
            </Card>
            {rows.map(row => {
                return (
                    <IonCard key={row.id}>
                        <IonCardHeader>
                            <IonCardTitle>Data: {row.data}</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonRow>
                                <IonCol size="12">
                                    <IonLabel>Paciente: </IonLabel>
                                    <IonText>{row.nome_paciente}</IonText>
                                </IonCol>
                                <IonCol size="12">
                                    <Formulario
                                        id_atendimento={row.id}
                                        destino={row.destino}
                                        periodicidade_prevista={row.periodicidade_prevista}
                                        setLastUpdate={setLastUpdate}
                                        setRows={setRows}
                                    />
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="6">
                                    <IonButton
                                        type="button"
                                        routerLink={`/medico/prescricao?id_paciente=${row.id_paciente}&nome_paciente=${row.nome_paciente}`}
                                        color="primary"
                                        expand="block"
                                    >
                                        Prescrever
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                )
            })}
        </Main>
    )
}

export default MedicoEmTratamento