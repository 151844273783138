import { IonAlert } from "@ionic/react";

interface Props {
    show: boolean
    setShow(show: boolean): void
    message: string
    header?: string
    onAccept: () => void
}

const Alert = ({ show, setShow, header, message, onAccept }: Props) => {
    return (
        <IonAlert
            isOpen={show}
            onDidDismiss={() => setShow(false)}
            header={header}
            message={message}
            buttons={[
                {
                    text: "Não",
                    role: "cancel"
                },
                {
                    text: "Sim",
                    handler: () => onAccept()
                }
            ]}
        />
    )
}

export default Alert;
