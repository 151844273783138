import React, { useRef, useState } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonIcon,
    IonLabel,
    IonRow,
    IonSelectOption,
    IonSpinner,
    IonText
} from "@ionic/react";
import { search } from "ionicons/icons";
import { format, parseISO } from "date-fns";

import { useToast } from "../../hooks/toast"
import { useValidation } from "../../hooks/validation";

import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import api from "../../services/api";

import Main from "../../components/Main";


import Select from "../../components/Select";
import Input from "../../components/Input";
import Textarea from "../../components/Textarea";

import SearchMachines from "../../legacy/components/Machines";

const sessionName = process.env.REACT_APP_SESSION_NAME

interface Patient {
    id: number
    name: string
}

interface Lines {
    id: number
    machine: number
    type: string
    date: string
    urgency: number
    tried_to_solve: "N" | "Y"
    solution_form: string
    fault: string
    cause: string
    was_in_procedure: "N" | "Y"
    patient: string
    equipment_was_replaced: "N" | "Y"
    replacement_machine: string
    status: string
}

const AbrirOS = () => {
    const { getValidationErrors } = useValidation()
    const { successToast } = useToast()
    const formRef = useRef<FormHandles>(null)
    const [triedToSolve, setTriedToSolve] = useState<string>("N")
    const [wasInProcedure, setWasInProcedure] = useState<string>("N")
    const [loading, setLoading] = useState<boolean>(false)
    const [patient, setPatient] = useState<Patient>({ id: 0, name: "" })
    const [thisIsThePatient, setThisIsThePatient] = useState<string>("N")
    const [thisEquipmentWasReplaced, setThisEquipmentWasReplaced] =
        useState<string>("N")
    const consultRef = useRef<FormHandles>(null)
    const [rows, setRows] = useState<Lines[]>([])

    const formDefaultValues = () => {
        return {
            tried_to_solve: "N",
            was_in_procedure: "N"
        }
    }

    const triedToSolveChange = (value: string) => {
        setTriedToSolve(value)
    }

    const wasInProcedureChange = (value: string) => {
        setWasInProcedure(value)
    }

    const thisIsThePatientChange = (value: string) => {
        setThisIsThePatient(value)
    }

    const thisEquipmentWasReplacedChange = (value: string) => {
        setThisEquipmentWasReplaced(value)
    }

    const handleConsultId = async () => {
        const assistance_id = formRef.current?.getFieldValue("assistance_id")
        if (assistance_id > 0) {
            setLoading(true)
            await api
                .get("abrir-os/paciente", {
                    params: {
                        assistance_id: assistance_id
                    }
                })
                .then(response => {
                    const { patient_id, patient_name } = response.data
                    setPatient({
                        id: patient_id,
                        name: patient_name
                    })
                })
                .catch(error => {
                    getValidationErrors(error.response)
                })
            setLoading(false)
        }
    }

    const consultDefaultValues = () => {
        return JSON.parse(
            `${localStorage.getItem(`@${sessionName}:ServicesOrders_ConsultForm`) ||
            "{}"
            }`
        )
    }

    const getList = async () => {
        const data = consultDefaultValues()

        if (data.month) {
            await api
                .get("abrir-os", {
                    params: {
                        month: data.month
                    }
                })
                .then(response => {
                    setRows(response.data)
                })
                .catch(error => {
                    getValidationErrors(error.response)
                })
        }
    }

    const handleReset = () => {
        formRef.current?.reset()
        setTriedToSolve("N")
        setWasInProcedure("N")
        setPatient({ id: 0, name: "" })
        setThisIsThePatient("N")
        setThisEquipmentWasReplaced("N")
    }

    const handleSubmit: SubmitHandler = async data => {
        await api
            .post("abrir-os", data)
            .then(_response => {
                successToast()
                handleReset()
                getList()
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
    }

    const handleConsult: SubmitHandler = async data => {
        localStorage.setItem(
            `@${sessionName}:ServicesOrders_ConsultForm`,
            JSON.stringify(data)
        )
        getList()
    }

    const handleMachineChange = async (value: string) => {
        await api.get(`abrir-os/maquina/${value}`).then(response => {
            const { type } = response.data
            formRef.current?.setFieldValue("type", type)
        })
    }
    return (
        <Main title="Abrir OS">
            <IonCard>
                <IonCardContent>
                    <IonGrid>
                        <Form
                            ref={formRef}
                            initialData={formDefaultValues()}
                            onSubmit={handleSubmit}
                        >
                            <IonRow className="ion-align-items-end">
                                <SearchMachines
                                    name="machine_id"
                                    label="Máquina"
                                    onChange={value => handleMachineChange(value)}
                                />
                                <Select name="type" label="Tipo" disabled>
                                    <IonSelectOption value="H">Hemodiálise</IonSelectOption>
                                    <IonSelectOption value="T">Osmose</IonSelectOption>
                                    <IonSelectOption value="C">Home Choice</IonSelectOption>
                                </Select>
                                <Select name="urgency" label="Urgência">
                                    <IonSelectOption value={0}>0</IonSelectOption>
                                    <IonSelectOption value={1}>1</IonSelectOption>
                                    <IonSelectOption value={2}>2</IonSelectOption>
                                    <IonSelectOption value={3}>3</IonSelectOption>
                                    <IonSelectOption value={4}>4</IonSelectOption>
                                </Select>
                                <Select
                                    name="tried_to_solve"
                                    label="Tentou Solucionar?"
                                    onChange={(value: string) => triedToSolveChange(value)}
                                >
                                    <IonSelectOption value="N">Não</IonSelectOption>
                                    <IonSelectOption value="Y">Sim</IonSelectOption>
                                </Select>
                                {triedToSolve === "Y" && (
                                    <Textarea
                                        name="solution_form"
                                        label="Tentou Solucionar de que Forma?"
                                        rows={3}
                                    />
                                )}
                                <Textarea name="fault" label="Defeito Apresentado" rows={3} />
                                <Textarea name="cause" label="Causa Provável" rows={3} />
                                <Select
                                    name="was_in_procedure"
                                    label="Equipamento estava em procedimento ao apresentar o defeito?"
                                    onChange={(value: string) => wasInProcedureChange(value)}
                                >
                                    <IonSelectOption value="N">Não</IonSelectOption>
                                    <IonSelectOption value="Y">Sim</IonSelectOption>
                                </Select>
                                {wasInProcedure === "Y" && (
                                    <>
                                        <Input
                                            name="assistance_id"
                                            type="number"
                                            inputMode="decimal"
                                            label="Digite o número da folha do procedimento"
                                        />
                                        <IonCol size="12">
                                            <IonButton
                                                color="medium"
                                                onClick={() => handleConsultId()}
                                            >
                                                <IonIcon icon={search} />
                                            </IonButton>
                                        </IonCol>
                                        {!!loading && (
                                            <IonCol size="12">
                                                <IonSpinner
                                                    color="primary"
                                                    name="lines"
                                                    style={{
                                                        display: "block",
                                                        margin: "auto",
                                                        height: "50px"
                                                    }}
                                                />
                                            </IonCol>
                                        )}
                                        {patient.id > 0 && (
                                            <>
                                                <IonCol size="12">
                                                    <IonLabel>PACIENTE: </IonLabel>
                                                    <IonText>{patient.name}</IonText>
                                                </IonCol>
                                                <Select
                                                    name="this_is_the_patient"
                                                    label="Este é o paciente?"
                                                    onChange={(value: string) => thisIsThePatientChange(value)}
                                                >
                                                    <IonSelectOption value="N">Não</IonSelectOption>
                                                    <IonSelectOption value="Y">Sim</IonSelectOption>
                                                </Select>
                                                {thisIsThePatient === "Y" && (
                                                    <>
                                                        <Select
                                                            name="equipment_was_replaced"
                                                            label="O equipamento foi substituído?"
                                                            onChange={(value: string) =>
                                                                thisEquipmentWasReplacedChange(value)
                                                            }
                                                        >
                                                            <IonSelectOption value="N">Não</IonSelectOption>
                                                            <IonSelectOption value="Y">Sim</IonSelectOption>
                                                        </Select>
                                                        {thisEquipmentWasReplaced === "Y" && (
                                                            <SearchMachines
                                                                name="replacement_machine_id"
                                                                label="Qual o equipamento foi usado na substituição?"
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                                <IonCol size="6" className="ion-margin-top">
                                    <IonButton type="submit" color="primary" expand="block">
                                        Salvar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </Form>
                    </IonGrid>
                </IonCardContent>
            </IonCard>
            <IonCard>
                <IonCardContent>
                    <IonGrid>
                        <Form
                            ref={consultRef}
                            initialData={consultDefaultValues()}
                            onSubmit={handleConsult}
                        >
                            <IonRow className="ion-align-items-end">
                                <Input
                                    name="month"
                                    label="Mês"
                                    type="month"
                                    max={format(new Date(), "yyyy-MM")}
                                />
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="6">
                                    <IonButton type="submit" color="primary" expand="block">
                                        Consultar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </Form>
                    </IonGrid>
                </IonCardContent>
            </IonCard>
            {rows.length > 0 && (
                <>
                    {rows.map(lines => {
                        return (
                            <IonCard key={lines.id}>
                                <IonCardHeader>
                                    <IonCardTitle>
                                        <IonRow>
                                            <IonCol size="12">
                                                <IonLabel>MÁQUINA: </IonLabel>
                                                <IonText>{lines.machine}</IonText>
                                            </IonCol>
                                            <IonCol size="12">
                                                <IonLabel>TIPO: </IonLabel>
                                                <IonText>{lines.type}</IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonLabel>DATA: </IonLabel>
                                            <IonText>
                                                {format(parseISO(lines.date), "dd/MM/yyyy")}
                                            </IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonLabel>SITUAÇÃO: </IonLabel>
                                            <IonText>{lines.status}</IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonLabel>URGÊNCIA: </IonLabel>
                                            <IonText>{lines.urgency}</IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonLabel>TENTOU SOLUCIONAR? </IonLabel>
                                            <IonText>
                                                {lines.tried_to_solve === "Y" ? "SIM" : "NÃO"}
                                            </IonText>
                                        </IonCol>
                                        {lines.tried_to_solve === "Y" && (
                                            <IonCol size="12">
                                                <IonLabel>TENTOU SOLUCIONAR DE QUE FORMA? </IonLabel>
                                                <IonText>{lines.solution_form}</IonText>
                                            </IonCol>
                                        )}
                                        <IonCol size="12">
                                            <IonLabel>DEFEITO APRESENTADO: </IonLabel>
                                            <IonText>{lines.fault}</IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonLabel>CAUSA PROVÁVEL: </IonLabel>
                                            <IonText>{lines.cause}</IonText>
                                        </IonCol>
                                        <IonCol size="12">
                                            <IonLabel>
                                                O EQUIPAMENTO ESTAVA EM PROCEDIMENTO AO APRESENTAR O
                                                DEFEITO?{" "}
                                            </IonLabel>
                                            <IonText>
                                                {lines.was_in_procedure === "Y" ? "SIM" : "NÃO"}
                                            </IonText>
                                        </IonCol>
                                        {lines.was_in_procedure === "Y" && (
                                            <>
                                                <IonCol size="12">
                                                    <IonLabel>PACIENTE: </IonLabel>
                                                    <IonText>{lines.patient}</IonText>
                                                </IonCol>
                                                <IonCol size="12">
                                                    <IonLabel>O EQUIPAMENTO FOI SUBSTITUÍDO? </IonLabel>
                                                    <IonText>
                                                        {lines.equipment_was_replaced === "Y"
                                                            ? "SIM"
                                                            : "NÃO"}
                                                    </IonText>
                                                </IonCol>
                                                {lines.equipment_was_replaced === "Y" && (
                                                    <IonCol size="12">
                                                        <IonLabel>
                                                            QUAL EQUIPAMENTO FOI USADO NA SUBSTITUIÇÃO:{" "}
                                                        </IonLabel>
                                                        <IonText>{lines.replacement_machine}</IonText>
                                                    </IonCol>
                                                )}
                                            </>
                                        )}
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        )
                    })}
                </>
            )}
        </Main>
    )
}

export default AbrirOS;
