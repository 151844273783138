import { useEffect, useRef, useState } from "react";
import { IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonLabel, IonRow, IonText, useIonViewDidEnter, useIonViewWillLeave } from "@ionic/react";
import { useHistory, useParams } from "react-router";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { useAuth } from "../../../hooks/auth";
import { useLoading } from "../../../hooks/loading";
import { useToast } from "../../../hooks/toast";
import { useValidation } from "../../../hooks/validation";

import api from "../../../services/api";

import Main from "../../../components/Main";
import Input from "../../../components/Input";
import ReactSelect from "../../../components/ReactSelect";

interface ParamTypes {
    id_atendimento: string;
    nome_paciente: string;
}

interface ProdutoEstoque {
    id: number;
    paciente: string;
    hospital: string;
    produto: string;
    lote: string;
    validade: string;
    qtd: number;
}

const TecnicoEstoque = () => {
    const { usuario } = useAuth();
    const [scroll, setScroll] = useState(new Date());
    const params = useParams<ParamTypes>()
    const [idAtendimento, setIdAtendimento] = useState(0);
    const [nomePaciente, setNomePaciente] = useState("");

    const [produtos, setProdutos] = useState<ProdutoEstoque[]>([]);
    const { showLoading, hideLoading, showSaveMessage, hideSaveMessage } = useLoading()

    const formRef = useRef<FormHandles>(null)
    const { successToast } = useToast()
    const { getValidationErrors } = useValidation()
    const history = useHistory()

    useEffect(() => {
        if (!!params) {
            setIdAtendimento(parseInt(params.id_atendimento));
            setNomePaciente(params.nome_paciente);
        }
    }, [params])

    const getList = async (idAtendimento: number) => {
        showLoading()
        await api.get(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/estoque/${idAtendimento}`)
            .then(response => {
                setProdutos(response.data)
            })
        hideLoading()
    }

    useIonViewDidEnter(() => {
        setProdutos([])
        reset()
        getList(idAtendimento)
    }, [idAtendimento])

    useIonViewWillLeave(() => {
        reset()
        setProdutos([])
    }, [idAtendimento])

    const submit: SubmitHandler = async data => {
        showSaveMessage()
        await api
            .post(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/estoque/${idAtendimento}`, data)
            .then(_response => {
                successToast()
                reset()
                getList(idAtendimento)
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
        hideSaveMessage()
    }

    const estornar = async (id: number) => {
        showLoading()
        await api
            .delete(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/estoque/${id}`)
            .then(_response => {
                successToast()
                reset()
                getList(idAtendimento)
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
        showLoading()
    }

    const reset = () => {
        formRef?.current?.reset()
        setScroll(new Date())
    }

    return (
        <Main title={`Estoque: ${idAtendimento}`} scroll={scroll} back>
            <IonCard>
                <IonCardContent>
                    <IonGrid>
                        <Form ref={formRef} onSubmit={submit}>
                            <IonRow className="ion-align-items-end">
                                <ReactSelect
                                    label="Produto"
                                    name="id_produto"
                                    url={`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/select/produto`}
                                    simple
                                    required
                                    clearable
                                />
                                <Input
                                    label="Lote"
                                    name="lote"
                                    type="text"
                                    inputMode="text"
                                />
                                <Input
                                    label="Validade"
                                    name="validade"
                                    type="date"
                                />
                                <Input
                                    label="Quantidade"
                                    name="qtd"
                                    type="number"
                                    inputMode="numeric"
                                />
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="12">
                                    <IonLabel>Paciente: </IonLabel>
                                    <IonText>{nomePaciente}</IonText>
                                </IonCol>
                                <IonCol size="6" className="ion-margin-top">
                                    <IonButton type="submit"
                                        color="primary"
                                        expand="block"
                                    >
                                        Salvar
                                    </IonButton>
                                </IonCol>
                                <IonCol size="6" className="ion-margin-top">
                                    <IonButton
                                        type="button"
                                        onClick={() => history.goBack()}
                                        color="medium"
                                        expand="block"
                                    >
                                        Voltar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </Form>
                    </IonGrid>
                </IonCardContent>
            </IonCard>
            {produtos.map(produto => (
                <IonCard key={produto.id}>
                    <IonCardContent>
                        <IonRow>
                            <IonCol size="12">
                                <IonLabel>PACIENTE: </IonLabel>
                                <IonText>{produto.paciente}</IonText>
                            </IonCol>
                            <IonCol size="12">
                                <IonLabel>HOSPITAL: </IonLabel>
                                <IonText>{produto.hospital}</IonText>
                            </IonCol>
                            <IonCol size="12">
                                <IonLabel>PRODUTO: </IonLabel>
                                <IonText>{produto.produto}</IonText>
                            </IonCol>
                            <IonCol size="12">
                                <IonLabel>LOTE: </IonLabel>
                                <IonText>{produto.lote}</IonText>
                            </IonCol>
                            <IonCol size="12">
                                <IonLabel>VALIDADE: </IonLabel>
                                <IonText>{produto.validade}</IonText>
                            </IonCol>
                            <IonCol size="12">
                                <IonLabel>QUANTIDADE: </IonLabel>
                                <IonText>{produto.qtd}</IonText>
                            </IonCol>
                        </IonRow>

                        <IonRow className="ion-margin-top">
                            <IonCol size="6">
                                <IonButton
                                    type="button"
                                    color="danger"
                                    expand="block"
                                    onClick={() => estornar(produto.id)}
                                >
                                    Estornar
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                </IonCard>
            ))}
        </Main>
    )

}

export default TecnicoEstoque;