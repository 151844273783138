import styled from "styled-components"
import { IonHeader, IonImg } from "@ionic/react"

export const Header = styled(IonHeader)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 5px 10px 5px;
`

export const Logo = styled(IonImg)`
  width: 60%;
  height: auto;
`
