import { useState } from "react";
import { useParams } from "react-router";
import {
    IonCard,
    IonCardContent,
    IonCol,
    IonLabel,
    IonRow,
    IonText,
    useIonViewDidEnter
} from "@ionic/react";
import { format } from "date-fns";

import api from "../../services/api";

import Main from "../../components/Main";

interface ParamTypes {
    assistanceId: string
}

interface AssistisEvolution {
    id: number
    evolution: string
    created_at: string
    updated_at: string
}

const Evolucao = () => {
    const { assistanceId } = useParams<ParamTypes>()
    const [data, setData] = useState<AssistisEvolution[]>([])

    useIonViewDidEnter(() => {
        api.get(`evolucao/${assistanceId}`).then(response => {
            setData(response.data)
        })
    })

    return (
        <Main title="Evolução" back>
            {data.map(values => {
                return (
                    <IonCard key={values.id}>
                        <IonCardContent>
                            <IonRow>
                                <IonCol size="12">
                                    <IonLabel>Evolução: </IonLabel>
                                    <IonText>{values.evolution}</IonText>
                                </IonCol>
                                <IonCol size="12">
                                    {!!values.created_at && (
                                        <>
                                            <IonLabel>Data: </IonLabel>
                                            <IonText>
                                                {format(
                                                    new Date(values.created_at.replace(" ", "T")),
                                                    "dd/MM/yyyy HH:mm"
                                                )}
                                            </IonText>
                                        </>
                                    )}
                                </IonCol>
                                <IonCol size="12">
                                    {!!values.updated_at &&
                                        values.updated_at !== values.created_at && (
                                            <>
                                                <IonLabel>Última Edição: </IonLabel>
                                                <IonText>
                                                    {format(
                                                        new Date(values.updated_at.replace(" ", "T")),
                                                        "dd/MM/yyyy HH:mm"
                                                    )}
                                                </IonText>
                                            </>
                                        )}
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                )
            })}
        </Main>
    )
}

export default Evolucao;
