import styled from "styled-components";
import { IonCard as Card } from "@ionic/react";

const appColor = process.env.REACT_APP_COLOR;

export const IonCard = styled(Card)`
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top-width: 5px;
    border-top-style: solid;
    border-top-color: ${appColor};
    overflow: visible;
`
