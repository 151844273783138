import { useMemo, useState } from "react"
import { useLocation } from "react-router-dom";
import {
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonItemDivider,
    IonLabel,
    IonRow,
    IonText,
    useIonViewDidEnter,
    useIonViewDidLeave
} from "@ionic/react";

import { useLoading } from "../../hooks/loading";

import api from "../../services/api";

import Main from "../../components/Main";
import Item from "../../components/Item";

import tipoProcedimento from "../../helpers/tipoProcedimento";
import procHomeCare from "../../helpers/procHomeCare";

interface Values {
    date: string
    patient_name: string
    hospital_name: string
    localization: string
    bed: string
    proc: string
    time: string
    access: string
    acesso_data: string;
    site: string
    catheter: string
    catheter_type: string
    tenckhoff: string
    catheter_reason: string
    precaution: string
    capitation_index: string
    catheter_time: string
    sessions: string
    diagnostic: string
    diagnostic_cause: string[]
    pre_existing_diseases: string[]
    classification: string
    conservative: string
    medical_report: string
    patient_weight: string
    patient_weight_to_lose: string
    priming: string
    capillary_type: string
    blood_flow: string
    dialysate_flow: string
    solucao_basica: string;
    ultrafiltration: string
    uf: string
    saline_solution_ml: string
    saline_solution_min: string
    heparin_type: string
    heparin: string
    heparin_min: number
    catheter_sealant: string
    kcl: string
    sodium_phosphate: string
    bicarbonate_anticoagulant: string
    sodium_anticoagulant: string
    temperature: string
    kdigo: string
    total_volume: string
    infusion_volume: string
    last_infusion_volume: string
    length_of_stay: string
    bag_concentrations: string
    line_type: string
    kit: string
    replacement_flow: string
    weight: string
    prescribed_dialysis_dose: string
    citrate_dose: string
    anticoagulating: string
    initial_citrate: string
    initial_calcium: string
    vasoactive_amines: string
    vasoactive_amines_amount: string
    clinical_condition: string
    mechanical_ventilation: string
    hemodynamically: string
    bh_sinal: string
    bh: string
    diuresis: string
    creatinine: string
    urea: string
    potassium: string
    phosphor: string
    calcium: string
    pcr: string
    sodium: string
    chlorine: string
    ph: string
    bicarbonate: string
    be: string
    hemoglobin: string
    ht: string
    covid: string
    note: string

    descricao_banho1: string;
    descricao_banho2: string;
    descricao_banho3: string;
    uf_total: string;
    uf_efetiva: string;
    tempo_total: string;
    numero_trocas: string;
    volume_infusao_bolsa: string;
    qtde_bolsas: string;
    numero_ciclos: string;
}

function useQuery() {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
}

const Prescricao = () => {
    const query = useQuery()
    const { showLoading, hideLoading } = useLoading()

    const [values, setValues] = useState<Values | null>(null)

    const getList = async (assistance_id: number) => {
        showLoading()
        await api.get(`/prescricao/${assistance_id}`).then(response => {
            if (response?.data) {
                setValues(response.data)
                hideLoading()
            }
        })
    }

    useIonViewDidEnter(() => {
        const assistance_id = query.get("id_atendimento")

        if (!!assistance_id) getList(parseInt(assistance_id))
    }, [query])

    useIonViewDidLeave(() => {
        setValues(null)
    }, [])

    return (
        <Main title="Prescrição" back>
            {!!values && (
                <>
                    <IonCard>
                        <IonCardContent>
                            <IonGrid>
                                <IonRow className="ion-align-items-end">
                                    <IonItemDivider className="ion-margin-bottom" color="primary">
                                        <IonLabel>PROCEDIMENTO</IonLabel>
                                    </IonItemDivider>
                                    <IonCol size="12" className="ion-no-padding">
                                        <IonLabel>PACIENTE: </IonLabel>
                                        <IonText>
                                            <strong>{values.patient_name}</strong>
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="12" className="ion-no-padding">
                                        <IonLabel>DATA PRESCRITA: </IonLabel>
                                        <IonText>
                                            <strong>{values.date}</strong>
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="12" className="ion-no-padding">
                                        <IonLabel>HOSPITAL: </IonLabel>
                                        <IonText>{values.hospital_name}</IonText>
                                    </IonCol>
                                    <IonCol size="12" className="ion-no-padding">
                                        <IonLabel>LOCALIZAÇÃO: </IonLabel>
                                        <IonText>{values.localization}</IonText>
                                    </IonCol>
                                    <IonCol size="12" className="ion-no-padding">
                                        <IonLabel>Leito: </IonLabel>
                                        <IonText>{values.bed}</IonText>
                                    </IonCol>
                                    <IonCol size="12" className="ion-no-padding">
                                        <IonLabel color="primary">
                                            <strong>PROCEDIMENTO: </strong>
                                        </IonLabel>
                                        <IonText>
                                            <strong>{values.proc}</strong>
                                        </IonText>
                                    </IonCol>
                                    <IonCol size="12" className="ion-no-padding">
                                        <IonLabel>TEMPO PRESCRITO: </IonLabel>
                                        <IonText>{values.time}</IonText>
                                    </IonCol>
                                    <IonCol size="12" className="ion-no-padding">
                                        <IonLabel>ACESSO: </IonLabel>
                                        <IonText>{values.access}</IonText>
                                    </IonCol>
                                    {!!values.acesso_data &&
                                        <IonCol size="12" className="ion-no-padding">
                                            <IonLabel>DATA DE CONFECÇÃO/IMPLANTE DO ACESSO VENOSSO: </IonLabel>
                                            <IonText>{values.acesso_data}</IonText>
                                        </IonCol>
                                    }
                                    {values.access !== "TENCKHOFF" && (
                                        <IonCol size="12" className="ion-no-padding">
                                            <IonLabel>SITIO: </IonLabel>
                                            <IonText>{values.site}</IonText>
                                        </IonCol>
                                    )}
                                    <IonCol size="12" className="ion-no-padding">
                                        <IonLabel>IMPLANTOU CATETER? </IonLabel>
                                        <IonText>{values.catheter}</IonText>
                                    </IonCol>
                                    {!!values.catheter_type && (
                                        <IonCol size="12" className="ion-no-padding">
                                            <IonLabel>CATETER TIPO: </IonLabel>
                                            <IonText>{values.catheter_type}</IonText>
                                        </IonCol>
                                    )}
                                    {!!values.tenckhoff && (
                                        <IonCol size="12" className="ion-no-padding">
                                            <IonLabel>THENKOFF: </IonLabel>
                                            <IonText>{values.tenckhoff}</IonText>
                                        </IonCol>
                                    )}
                                    {!!values.catheter_reason && (
                                        <IonCol size="12" className="ion-no-padding">
                                            <IonLabel>MOTIVO DA TROCA: </IonLabel>
                                            <IonText>{values.catheter_reason}</IonText>
                                        </IonCol>
                                    )}
                                    <IonCol size="12" className="ion-no-padding">
                                        <IonLabel>PRECAUÇÃO: </IonLabel>
                                        <IonText>{values.precaution}</IonText>
                                    </IonCol>
                                    <IonCol size="12" className="ion-no-padding">
                                        <IonLabel>ÍNDICE DE CAPTAÇÃO: </IonLabel>
                                        <IonText>{values.capitation_index}</IonText>
                                    </IonCol>
                                    <IonCol size="12" className="ion-no-padding">
                                        <IonLabel>TEMPO DE PERMANÊNCIA DE CATETER: </IonLabel>
                                        <IonText>{values.catheter_time}</IonText>
                                    </IonCol>
                                    <IonCol size="12" className="ion-no-padding">
                                        <IonLabel>Nº DE SESSÕES DE DIÁLISE: </IonLabel>
                                        <IonText>{values.sessions}</IonText>
                                    </IonCol>
                                    <IonCol size="12" className="ion-no-padding">
                                        <IonLabel>DIAGNÓSTICO: </IonLabel>
                                        <IonText>{values.diagnostic}</IonText>
                                    </IonCol>
                                    {(values.diagnostic === "IRA" ||
                                        values.diagnostic === "DRC (A)") && (
                                            <IonCol
                                                size="12"
                                                className="ion-no-padding ion-margin-bottom"
                                            >
                                                <IonLabel>CAUSA: </IonLabel>
                                                {values.diagnostic_cause.map((cause, index) => (
                                                    <span key={index}>
                                                        <br />
                                                        <IonText>{cause}</IonText>
                                                    </span>
                                                ))}
                                            </IonCol>
                                        )}
                                    <IonCol
                                        size="12"
                                        className="ion-no-padding ion-margin-bottom"
                                    >
                                        <IonLabel>DOENÇAS PRÉ EXISTENTES: </IonLabel>
                                        {values.pre_existing_diseases.map(
                                            (pre_existing_disease, index) => (
                                                <span key={index}>
                                                    <br />
                                                    <IonText>{pre_existing_disease}</IonText>
                                                </span>
                                            )
                                        )}
                                    </IonCol>
                                    <IonCol size="12" className="ion-no-padding">
                                        <IonLabel>CLASSIFICAÇÃO: </IonLabel>
                                        <IonText>{values.classification}</IonText>
                                    </IonCol>
                                    {values.proc === "VISITA HOSPITALAR" && (
                                        <IonCol size="12" className="ion-no-padding">
                                            <IonLabel>CONSERVADOR: </IonLabel>
                                            <IonText>{values.conservative}</IonText>
                                        </IonCol>
                                    )}
                                    {!!values.medical_report && (
                                        <IonCol
                                            size="12"
                                            className="ion-no-padding ion-margin-bottom"
                                        >
                                            <IonLabel>RELATÓRIO MÉDICO: </IonLabel>
                                            <IonText>
                                                <br />
                                                {values.medical_report}
                                            </IonText>
                                        </IonCol>
                                    )}
                                    <IonItemDivider
                                        className="ion-margin-top ion-margin-bottom"
                                        color="primary"
                                    >
                                        <IonLabel>PRESCRIÇÃO</IonLabel>
                                    </IonItemDivider>

                                    <Item show={tipoProcedimento(values.proc) !== "DPA" && tipoProcedimento(values.proc) !== "CAPD"} label="PRIMING: " value={values.priming} />
                                    <Item show={tipoProcedimento(values.proc) !== "DPA" && tipoProcedimento(values.proc) !== "CAPD"} label="TIPO DE CAPILAR: " value={values.capillary_type} />
                                    <Item show={tipoProcedimento(values.proc) === "C"} label="TIPO DE LINHA: " value={values.line_type} />
                                    <Item show={tipoProcedimento(values.proc) === "C"} label="KIT: " value={values.kit} />
                                    <Item show={tipoProcedimento(values.proc) === "H"} label="ULTRAFILTRAÇÃO (ml/hora): " value={values.ultrafiltration} />
                                    <Item show={tipoProcedimento(values.proc) !== "DPA" && tipoProcedimento(values.proc) !== "CAPD"} label="UF (mL por sessão): " value={values.uf} />


                                    <Item show={tipoProcedimento(values.proc) === "H" || tipoProcedimento(values.proc) === "C"} label="FLUXO DE SANGUE (ml/min): " value={values.blood_flow} />
                                    <Item show={tipoProcedimento(values.proc) === "H" || tipoProcedimento(values.proc) === "C"} label={tipoProcedimento(values.proc) === "C" ? "FLUXO DIALISATO (ml/h)" : "FLUXO DIALISATO (ml/min)"} value={values.dialysate_flow} />
                                    <Item show={tipoProcedimento(values.proc) === "H"} label="SOLUÇÃO BÁSICA: " value={values.solucao_basica} />

                                    <Item show={tipoProcedimento(values.proc) === "DPA"} label="VOLUME TOTAL (ml): " value={values.total_volume} />
                                    <Item show={tipoProcedimento(values.proc) === "DPA"} label="VOLUME DE INFUSÃO (ml): " value={values.infusion_volume} />
                                    <Item show={tipoProcedimento(values.proc) === "DPA"} label="VOLUME DA ÚLTIMA INFUSÃO (ml): " value={values.last_infusion_volume} />


                                    <Item show={tipoProcedimento(values.proc) === "DPA" || tipoProcedimento(values.proc) === "CAPD"} label="TEMPO TOTAL:" value={values.tempo_total} />
                                    <Item show={tipoProcedimento(values.proc) === "CAPD"} label="NÚMERO DE TROCAS:" value={values.numero_trocas} />
                                    <Item show={tipoProcedimento(values.proc) === "CAPD"} label="VOLUME DE INFUSÃO POR BOLSA:" value={values.volume_infusao_bolsa} />
                                    <Item show={tipoProcedimento(values.proc) === "DPA" || tipoProcedimento(values.proc) === "CAPD"} label="TEMPO DE PERMANÊNCIA:" value={values.length_of_stay} />
                                    <Item show={tipoProcedimento(values.proc) === "DPA" || tipoProcedimento(values.proc) === "CAPD"} label="NÚMERO DE CICLOS:" value={values.numero_ciclos} />
                                    <Item show={tipoProcedimento(values.proc) === "DPA" || tipoProcedimento(values.proc) === "CAPD"} label="QUANTIDADE DE BOLSAS:" value={values.qtde_bolsas} />

                                    <Item label="DESCRIÇÃO DO BANHO: " value={values.descricao_banho1} />
                                    <Item show={tipoProcedimento(values.proc) !== "H" && !!values.descricao_banho1} label="" value={values.descricao_banho2} />
                                    <Item show={tipoProcedimento(values.proc) !== "H" && !!values.descricao_banho1} label="" value={values.descricao_banho3} />

                                    {tipoProcedimento(values.proc) === "H" &&
                                        <IonItemDivider
                                            className="ion-margin-top ion-margin-bottom"
                                            color="primary"
                                        >
                                            <IonLabel>ANTICOAGULAÇÃO</IonLabel>
                                        </IonItemDivider>
                                    }
                                    <Item show={tipoProcedimento(values.proc) === "H" && !!values.saline_solution_ml && !!values.saline_solution_min} label="SORO FISIOLÓGICO (ml/min): " value={`${values.saline_solution_ml} mililitros (ml) a cada ${values.saline_solution_min} minutos`} />
                                    <Item show={tipoProcedimento(values.proc) === "H" || tipoProcedimento(values.proc) === "DPA"} label="HEPARINA: " value={`${values.heparin_type === "BL" ? `${values.heparin} mL (bolus)`
                                        : values.heparin_type === "FR"
                                            ? `${values.heparin} mL a cada ${values.heparin_min} min (fracionada)`
                                            : values.heparin}`} />

                                    <Item show={tipoProcedimento(values.proc) === "H"} label="TIPO SELANTE DO CATETER: " value={values.catheter_sealant} />

                                    {tipoProcedimento(values.proc) === "H" &&
                                        <>
                                            <IonItemDivider
                                                className="ion-margin-top ion-margin-bottom"
                                                color="primary"
                                            >
                                                <IonLabel>REPOSIÇÃO DE ELETRÓLITOS</IonLabel>
                                            </IonItemDivider>
                                            <Item label="KCL (Ampola): " value={values.kcl} />
                                            <Item label="FOSFATO DE SÓDIO (SACHE): " value={values.sodium_phosphate} />
                                        </>
                                    }

                                    <IonItemDivider
                                        className="ion-margin-top ion-margin-bottom"
                                        color="primary"
                                    >
                                        <IonLabel>PARÂMETROS DA MÁQUINA</IonLabel>
                                    </IonItemDivider>
                                    <Item show={tipoProcedimento(values.proc) === "H"} label="BICARBONATO: " value={values.bicarbonate_anticoagulant} />
                                    <Item show={tipoProcedimento(values.proc) === "H"} label="SÓDIO: " value={values.sodium_anticoagulant} />
                                    <Item show={tipoProcedimento(values.proc) === "H" || tipoProcedimento(values.proc) === "C"} label="TEMPERATURA: " value={values.temperature} />
                                    <Item show={tipoProcedimento(values.proc) !== "DPA"} label="UF TOTAL: " value={values.uf_total} />
                                    <Item label="UF EFETIVA: " value={values.uf_efetiva} />

                                    <Item show={tipoProcedimento(values.proc) === "C"} label="FLUXO DE REPOSIÇÃO (ml/h): " value={values.replacement_flow} />
                                    <Item show={tipoProcedimento(values.proc) === "C"} label="PESO (Kg): " value={values.patient_weight} />
                                    <Item show={procHomeCare(values.proc) !== "H"} label="PESO A PERDER: " value={values.patient_weight} />

                                    <Item show={tipoProcedimento(values.proc) === "C"} label="ANTICOAGULAÇÃO: " value={values.anticoagulating} />
                                    <Item show={tipoProcedimento(values.proc) === "C"} label="DOSE INICIAL CITRATO: " value={values.initial_citrate} />
                                    <Item show={tipoProcedimento(values.proc) === "C"} label="DOSE INICIAL CÁLCIO: " value={values.initial_calcium} />

                                    <Item label="USO DE AMINAS VASOATIVAS: " value={values.vasoactive_amines} />
                                    <Item show={!!values.vasoactive_amines} label="QUANTIDADE (ml/hora): " value={values.vasoactive_amines_amount} />
                                    <Item show={procHomeCare(values.proc) !== "HC"} label="QUADRO CLÍNICO: " value={values.clinical_condition} />
                                    <Item show={procHomeCare(values.proc) !== "HC"} label="VENTILAÇÃO MECÂNICA: " value={values.mechanical_ventilation} />
                                    <Item show={procHomeCare(values.proc) !== "HC"} label="HEMODINAMICAMENTE: " value={values.hemodynamically} />

                                    <IonItemDivider
                                        className="ion-margin-top ion-margin-bottom"
                                        color="primary"
                                    >
                                        <IonLabel>EXAMES</IonLabel>
                                    </IonItemDivider>
                                    <Item show={!!values.bh} label="BH (ml/24): " value={`${values.bh_sinal} ${values.bh}`} />
                                    <Item label="DIURESE (ml/24): " value={values.diuresis} />
                                    <Item label="CREATININA (mg/dl): " value={values.creatinine} />
                                    <Item label="UREIA (mg/dl): " value={values.urea} />
                                    <Item label="POTÁSSIO (mEq/l): " value={values.potassium} />
                                    <Item label="FÓSFORO (mg/dl): " value={values.phosphor} />
                                    <Item label="CÁLCIO (mg/dl): " value={values.calcium} />
                                    <Item label="PCR (Unidade): " value={values.pcr} />
                                    <Item label="SÓDIO (mEq/l): " value={values.sodium} />
                                    <Item label="CLORO (mEq/l): " value={values.chlorine} />
                                    <Item label="Ph: " value={values.ph} />
                                    <Item label="BICARBONATO (mEq/l): " value={values.bicarbonate} />
                                    <Item label="BE (Unidade): " value={values.be} />
                                    <Item label="HEMOGLOBINA (G/dl):" value={values.hemoglobin} />
                                    <Item label="HT (%): " value={values.ht} />
                                    <Item show={procHomeCare(values.proc) !== "HC"} label="COVID: " value={values.covid} />


                                    {!!values.note && (
                                        <IonCol size="12" className="ion-no-padding">
                                            <IonLabel>Observação: </IonLabel>
                                            <br />
                                            <IonText>{values.note}</IonText>
                                        </IonCol>
                                    )}
                                </IonRow>
                            </IonGrid>
                        </IonCardContent>
                    </IonCard>
                </>
            )}
        </Main>
    )
}

export default Prescricao
