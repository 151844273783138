import React, { useCallback, useEffect, useState } from 'react'
import { IonCol, IonLabel } from '@ionic/react'
import Select, { Props as SelectProps } from 'react-select'

import api from '../../services/api'
import { useAuth } from '../../hooks/auth'

interface Props {
  size?: string
  label: string
  value: SelectProps
  onChange(value: SelectProps): void
}

const SearchInput: React.FC<Props> = ({
  size,
  label,
  value,
  onChange,
  ...rest
}) => {
  const { usuario } = useAuth();
  const [technicians, setTechnicians] = useState([])

  const searchTechnicians = useCallback((searchValue?: string | null) => {
    api
      .get(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/select/tecnico`, {
        params: { search: searchValue }
      })
      .then(resp => {
        setTechnicians(resp.data)
      })
  }, [usuario.tipo_perfil])

  useEffect(() => {
    searchTechnicians()
  }, [searchTechnicians])

  return (
    <IonCol size={size || '12'}>
      <IonLabel position="stacked">{label}</IonLabel>
      <Select
        placeholder=""
        classNamePrefix="select"
        value={value}
        onChange={value => {
          if (value) {
            onChange(value)
          } else {
            onChange({} as SelectProps)
          }
        }}
        onInputChange={value => searchTechnicians(value)}
        noOptionsMessage={() => 'Nenhuma opção'}
        options={technicians}
        styles={{
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
        components={{
          IndicatorSeparator: () => null
        }}
        {...rest}
      />
    </IonCol>
  )
}

export default SearchInput
