import React, {
    createContext,
    useCallback,
    useState,
    useContext,
    useEffect
} from "react"

import { useAuth } from "./auth";
import api from "../services/api";

export interface NotificationsContextData {
    agendados: number;
    emAndamento: number;
    getContadorAtendimentos(): void;
}

const NotificationsContext = createContext<NotificationsContextData>(
    {} as NotificationsContextData
)

interface Props {
    children: React.ReactNode;
}

const NotificationsProvider = ({ children }: Props) => {
    const { usuario } = useAuth();
    const [agendados, setAgendados] = useState(0);
    const [emAndamento, setEmAndamento] = useState(0);

    const getContadorAtendimentos = useCallback(async () => {
        if (!!usuario) {
            await api.get(`${usuario?.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/contador-atendimentos`).then(response => {
                setAgendados(response.data?.agendados || 0)
                setEmAndamento(response.data?.em_andamento || 0)
            })
        }
    }, [usuario])

    useEffect(() => {
        const interval = setInterval(function () {
            if (usuario?.tipo_perfil === "TEC" || usuario?.tipo_perfil === "ENF") {
                getContadorAtendimentos()
            }
        }, 60000)

        if (usuario?.tipo_perfil === "TEC" || usuario?.tipo_perfil === "ENF") {
            getContadorAtendimentos()
        }
        return () => clearInterval(interval)
    }, [usuario?.tipo_perfil, getContadorAtendimentos])

    return (
        <NotificationsContext.Provider
            value={{
                agendados,
                emAndamento,
                getContadorAtendimentos
            }}
        >
            {children}
        </NotificationsContext.Provider>
    )
}

function useNotifications(): NotificationsContextData {
    const context = useContext(NotificationsContext);

    if (!context) {
        throw new Error(
            "useNotifications must be used within an NotificationsProvider"
        )
    }

    return context;
}

export { NotificationsProvider, useNotifications };
