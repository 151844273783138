import { useRef, useState } from "react";
import { useHistory } from "react-router";
import {
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonLabel,
    IonText
} from "@ionic/react";

import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import api from "../../../services/api";

import { useAuth } from "../../../hooks/auth";
import { useLoading } from "../../../hooks/loading";
import { useToast } from "../../../hooks/toast";
import { useValidation } from "../../../hooks/validation";

import Main from "../../../components/Main";
import Card from "../../../components/Card";
import ReactSelect from "../../../components/ReactSelect";

const sessionName = process.env.REACT_APP_SESSION_NAME;

export interface Row {
    id: number;
    situacao: string;
    paciente: string;
    hospital: string;
    data: string;
    inicio: string;
    medico: string;
}

interface AtendimentoProps {
    row: Row;
}

const Atendimento = ({ row }: AtendimentoProps) => {
    const { usuario } = useAuth();
    const { showSaveMessage, hideSaveMessage } = useLoading();
    const { successToast } = useToast();
    const { getValidationErrors } = useValidation();
    const history = useHistory();

    const handleAssumir = async (id: number) => {
        showSaveMessage();
        await api
            .put(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/assumir-atendimento/${id}`)
            .then(_response => {
                successToast();
                history.push("/tecnico/em-andamento");
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
        hideSaveMessage();
    }

    return (
        <IonCard className={`card-${row.situacao?.toLowerCase()}`}>
            <IonCardContent>
                <IonRow>
                    <IonCol size="12">
                        <IonLabel>Situação: </IonLabel>
                        <IonText>{row.situacao}</IonText>
                    </IonCol>
                    <IonCol size="12">
                        <IonLabel>Paciente: </IonLabel>
                        <IonText>{row.paciente}</IonText>
                    </IonCol>
                    <IonCol size="12">
                        <IonLabel>Hospital: </IonLabel>
                        <IonText>{row.hospital}</IonText>
                    </IonCol>
                    <IonCol size="12">
                        <IonLabel>Data: </IonLabel>
                        <IonText>{row.data}</IonText>
                    </IonCol>
                    <IonCol size="12">
                        <IonLabel>Hora de Início: </IonLabel>
                        <IonText>{row.inicio}</IonText>
                    </IonCol>
                    <IonCol size="12">
                        <IonLabel>Médico: </IonLabel>
                        <IonText>{row.medico}</IonText>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-margin-top">
                    <IonCol size="12">
                        <IonButton
                            type="button"
                            color="primary"
                            expand="block"
                            onClick={() => handleAssumir(row.id)}
                        >
                            Assumir
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonCardContent>
        </IonCard>
    )
}

const TecnicoAssumirAtendimento = () => {
    const { usuario } = useAuth();
    const { showLoading, hideLoading } = useLoading();
    const { getValidationErrors } = useValidation();

    const consultRef = useRef<FormHandles>(null);
    const [rows, setRows] = useState<Row[]>([]);

    const consultValues = () => {
        return JSON.parse(
            `${localStorage.getItem(
                `@${sessionName}:TecnicoAssumirAtendimento`
            ) || "{}"
            }`
        )
    }

    const handleConsult: SubmitHandler = async (values) => {
        localStorage.setItem(
            `@${sessionName}:TecnicoAssumirAtendimento`,
            JSON.stringify(values)
        )

        showLoading();
        await api
            .get(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/assumir-atendimento`, {
                params: {
                    id_hospital: values?.id_hospital?.value
                }
            })
            .then(response => {
                setRows(response.data)
            })
            .catch(error => {
                getValidationErrors(error.response)
            })
        hideLoading();
    }

    return (
        <Main title="Assumir Atendimentos">
            <Card>
                <IonCardContent>
                    <Form
                        ref={consultRef}
                        initialData={consultValues()}
                        onSubmit={handleConsult}
                    >
                        <IonGrid>
                            <IonRow className="ion-align-items-end">
                                <ReactSelect
                                    label="Hospital"
                                    name="id_hospital"
                                    url={`/${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/select/hospital`}
                                    params={{ status: "A" }}
                                    required
                                />
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="12">
                                    <IonButton type="submit" color="primary" expand="block">
                                        Consultar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </Form>
                </IonCardContent>
            </Card>
            {rows.map(row => {
                return <Atendimento key={row.id} row={row} />
            })}
        </Main>
    );
};

export default TecnicoAssumirAtendimento;
