import { useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCol,
    IonGrid,
    IonLabel,
    IonRow,
    IonText,
    useIonViewDidEnter,
    useIonViewWillLeave
} from "@ionic/react";
import { format } from "date-fns";

import { useAuth } from "../../../hooks/auth";
import { useToast } from "../../../hooks/toast";
import { useValidation } from "../../../hooks/validation";
import api from "../../../services/api";

import Main from "../../../components/Main";
import Textarea from "../../../components/Textarea";


interface ParamTypes {
    assistanceId: string;
    patientName: string;
}

interface AssistsEvolution {
    id: number;
    assistance_id: number;
    evolution: string;
    created_at: string;
}

const TecnicoEvolucao = () => {
    const { usuario } = useAuth();
    const formRef = useRef<FormHandles>(null);
    const { successToast } = useToast();
    const { getValidationErrors } = useValidation();
    const { assistanceId, patientName } = useParams<ParamTypes>();
    const history = useHistory();

    const [scroll, setScroll] = useState(new Date())
    const [id, setId] = useState<number>(0)
    const [data, setData] = useState<AssistsEvolution[]>([])

    const getList = (assistanceId: string) => {
        api.get(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/evolucao/${assistanceId}`).then(response => {
            setData(response.data);
        })
    }

    useIonViewDidEnter(() => {
        formRef?.current?.reset();
        setData([]);
        setId(0);
        setScroll(new Date());
        getList(assistanceId);
    }, [assistanceId])

    useIonViewWillLeave(() => {
        formRef?.current?.reset();
        setData([]);
        setId(0);
    }, [assistanceId])

    const handleSubmit: SubmitHandler = async data => {
        if (id > 0) {
            update(data);
        } else {
            create(data);
        }
    }

    const create = async (data: AssistsEvolution) => {
        await api
            .post(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/evolucao/${assistanceId}`, data)
            .then(_response => {
                successToast();
                handleReset();
                getList(assistanceId);
            })
            .catch(error => {
                getValidationErrors(error.response);
            })
    }

    const update = async (data: AssistsEvolution) => {
        await api
            .put(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/evolucao/${assistanceId}/${id}`, data)
            .then(_response => {
                successToast();
                handleReset();
                getList(assistanceId);
            })
            .catch(error => {
                getValidationErrors(error.response);
            })
    }

    const handleReset = () => {
        formRef?.current?.reset();
        setId(0);
        setScroll(new Date());
    }

    const setFormValues = (values: AssistsEvolution) => {
        if (!!values.id) {
            setId(values.id)
            formRef?.current?.setData({ ...values })
            setScroll(new Date())
        }
    }

    return (
        <Main title="Evolução" scroll={scroll} back>
            <IonCard>
                <IonCardHeader>
                    <IonRow>
                        <IonCol size="6">
                            <IonButton
                                type="button"
                                color="success"
                                expand="block"
                                onClick={() => handleReset()}
                            >
                                Novo
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonCardHeader>
                <IonCardContent>
                    <IonGrid>
                        <Form ref={formRef} onSubmit={handleSubmit}>
                            <IonRow className="ion-align-items-end">
                                <Textarea
                                    label="Evolução"
                                    name="evolution"
                                    rows={10}
                                    size="12"
                                />
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="12">
                                    <IonLabel>Paciente: </IonLabel>
                                    <IonText>{patientName}</IonText>
                                </IonCol>
                                <IonCol size="6" className="ion-margin-top">
                                    <IonButton type="submit" color="primary" expand="block">
                                        SALVAR
                                    </IonButton>
                                </IonCol>
                                <IonCol size="6" className="ion-margin-top">
                                    <IonButton
                                        type="button"
                                        onClick={() => history.goBack()}
                                        color="medium"
                                        expand="block"
                                    >
                                        Voltar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </Form>
                    </IonGrid>
                </IonCardContent>
            </IonCard>
            {data.map(values => (
                <IonCard key={values.id}>
                    <IonCardContent>
                        <IonRow>
                            <IonCol size="12">
                                <IonLabel>Hora: </IonLabel>
                                <IonText>
                                    {!!values.created_at &&
                                        format(
                                            new Date(values.created_at?.replace(" ", "T")),
                                            "HH:mm"
                                        )}
                                </IonText>
                            </IonCol>
                            <IonCol size="12">
                                <IonLabel>Evolução: </IonLabel>
                                <IonText>{values.evolution}</IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-margin-top">
                            <IonCol size="6">
                                <IonButton
                                    type="button"
                                    color="primary"
                                    expand="block"
                                    onClick={() => setFormValues(values)}
                                >
                                    Editar
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                </IonCard>
            ))}
        </Main>
    )
}

export default TecnicoEvolucao;
