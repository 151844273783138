import { useRef, useEffect } from "react";
import {
  IonButtons,
  IonBackButton,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonIcon,
  IonBadge,
  useIonViewDidEnter
} from "@ionic/react";
import { alarm, hourglass } from "ionicons/icons";

import { useAuth } from "../../hooks/auth";
import { useNotifications } from "../../hooks/notifications";

interface Props {
  title: string;
  scroll?: unknown;
  back?: boolean;
  end?: any;
  footer?: any;
  children: any;
}

const Main: React.FC<Props> = ({
  title,
  scroll,
  back,
  end,
  footer,
  children
}) => {
  const { usuario, appUpdated } = useAuth()
  const { agendados, emAndamento } = useNotifications()

  const contentRef = useRef<HTMLIonContentElement>(null)

  useEffect(() => {
    contentRef?.current?.scrollToTop(500)
  }, [scroll])

  useIonViewDidEnter(() => {
    document.addEventListener("ionBackButton", (ev: any) => {
      ev.detail.register(10, () => { })
    })
  })

  return (
    <IonPage>
      <IonHeader mode="md">
        {!appUpdated && (
          <div className="app-not-updated">Atualização Disponível</div>
        )}
        <IonToolbar mode="md">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButtons slot="end">
            {(usuario?.tipo_perfil === "TEC" || usuario?.tipo_perfil === "ENF") && (
              <>
                <IonItem
                  mode="md"
                  lines="none"
                  routerLink="/tecnico/agendados"
                >
                  <IonIcon
                    mode="md"
                    size="large"
                    slot="start"
                    color="light"
                    icon={alarm}
                  />
                  <IonBadge mode="md" color="tertiary">
                    {agendados}
                  </IonBadge>
                </IonItem>
                <IonItem
                  mode="md"
                  lines="none"
                  routerLink="/tecnico/em-andamento"
                >
                  <IonIcon
                    mode="md"
                    size="large"
                    slot="start"
                    color="light"
                    icon={hourglass}
                  />
                  <IonBadge mode="md" color="secondary">
                    {emAndamento}
                  </IonBadge>
                </IonItem>
              </>
            )}

            {back && <IonBackButton />}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonToolbar mode="md">
        <IonTitle>{title}</IonTitle>
        <IonButtons slot="end">{end}</IonButtons>
      </IonToolbar>

      <IonContent scrollEvents={false} ref={contentRef} id="content">
        {children}
      </IonContent>

      {footer}
    </IonPage>
  )
}

export default Main;
