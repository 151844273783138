import * as Yup from "yup";

/* eslint-disable */
Yup.setLocale({
    mixed: {
        notType: "O campo ${label} é inválido.",
        required: "O campo ${label} é obrigatório."
    },
    number: {
        min: "O campo ${label} deve ser maior ou igual a ${min}.",
        max: "O campo ${label} deve ser menor ou igual a ${max}."
    },
    array: {
        min: "O campo ${label} é obrigatório.",
        max: "O campo ${label} é obrigatório."
    }
});
/* eslint-enable */

export default Yup;
