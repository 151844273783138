import { useEffect, useRef, useState } from "react"
import { IonGrid, IonRow } from "@ionic/react"

import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { useLoading } from "../../../../hooks/loading";
import { useToast } from "../../../../hooks/toast";
import { useValidation } from "../../../../hooks/validation";

import api from "../../../../services/api";

import Select from "../../../../components/Select";
import { Row } from "..";

interface Props {
    id_atendimento: number;
    destino: string;
    periodicidade_prevista: string;
    setLastUpdate(date: Date): void;
    setRows(rows: Row[]): void;
}

interface FormValues {
    destino: string;
    periodicidade_prevista: string;
}

const MedicoEmTratamentoFormulario = ({
    id_atendimento,
    destino,
    periodicidade_prevista,
    setLastUpdate,
    setRows,
}: Props) => {
    const { showSaveMessage, hideSaveMessage } = useLoading();
    const { successToast } = useToast();
    const { getValidationErrors } = useValidation();

    const formRef = useRef<FormHandles>(null);
    const [edit, setEdit] = useState(false);

    const handleSubmit: SubmitHandler = async (values: FormValues) => {
        showSaveMessage()
        await api
            .put(`medico/em-tratamento/${id_atendimento}`, values)
            .then(response => {
                if (!!response.data) {
                    successToast();
                }
            })
            .catch(error => {
                setRows([]);
                getValidationErrors(error.response);
            })
        setLastUpdate(new Date());
        hideSaveMessage()
    }

    useEffect(() => {
        setEdit(false);
        formRef?.current?.setData({
            destino,
            periodicidade_prevista
        })
    }, [destino, periodicidade_prevista])

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <IonGrid>
                <IonRow className="ion-align-items-end">
                    <Select
                        size="12"
                        label="Destino"
                        name="destino"
                        onChange={() => {
                            if (edit)
                                formRef?.current?.submitForm();

                            setEdit(true);
                        }}
                        values={[
                            { value: "Em Tratamento", label: "EM TRATAMENTO" },
                            { value: "Alta Nefro", label: "ALTA NEFRO" },
                            { value: "Alta Hospitalar", label: "ALTA HOSPITALAR" },
                            { value: "Alta Encaminhado para o Programa", label: "ALTA ENCAMINHADO PARA O PROGRAMA" },
                            { value: "Transferência", label: "TRANSFERÊNCIA" },
                            { value: "Óbito", label: "ÓBITO" },
                        ]}
                    />
                    <Select
                        label="Periodicidade Prevista"
                        name="periodicidade_prevista"
                        onChange={() => {
                            if (edit)
                                formRef?.current?.submitForm();

                            setEdit(true);
                        }}
                        values={[
                            { value: "Dias Alternados", label: "DIAS ALTERNADOS" },
                            { value: "Diário", label: "DIÁRIO" },
                            { value: "Paliativo", label: "PALIATIVO" },
                        ]}
                    />
                </IonRow>
            </IonGrid>
        </Form>
    )
}

export default MedicoEmTratamentoFormulario
