import React, { createContext, useCallback, useState, useContext } from 'react'
import { isPlatform } from '@ionic/react'
import axios from 'axios'

interface Usuario {
    id_usuario: number
    id_perfil: number
    nome_completo: string
    perfil: string
    tipo_perfil: "MED" | "ENF" | "TEC" | "MOT" | "ADM"
    id_empresa: number
    nome_empresa: string
    senha_temp: "S" | "N"
    assinatura: "S" | "N"
    dev: "S" | "N"
}

interface AuthState {
    usuario: Usuario
    token: string
    baseUrl: string
}

export interface AuthContextData {
    usuario: Usuario
    appUpdated: boolean
    signIn(userEncrypted: string, token: string, baseUrl: string): void
    signOut(): void
    validateToken(): void
    alterarSenha(senha_temp: Usuario["senha_temp"]): void
    atualizarAssinatura(assinatura: Usuario["assinatura"]): void
}

const sessionName = process.env.REACT_APP_SESSION_NAME
const appVersion = process.env.REACT_APP_VERSION

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const userDecrypt = (userEncrypted: string) => {
    let userDecrypted = userEncrypted.split('').reverse().join('')
    userDecrypted = atob(userDecrypted)

    return JSON.parse(userDecrypted)
}

export const userEncrypt = (userDecrypted: Usuario) => {
    let userEncrypted = JSON.stringify(userDecrypted)
    userEncrypted = btoa(userEncrypted)

    return userEncrypted.split('').reverse().join('').replace('==', '')
}

interface Props {
    children: React.ReactNode
}

const AuthProvider = ({ children }: Props) => {
    const [data, setData] = useState<AuthState>(() => {
        const userEncrypted = localStorage.getItem(`@${sessionName}:usuario`)
        const token = localStorage.getItem(`@${sessionName}:token`)
        const baseUrl = localStorage.getItem(`@${sessionName}:baseUrl`)

        if (userEncrypted && token && baseUrl) {
            try {
                const usuario = userDecrypt(userEncrypted)

                return {
                    usuario,
                    token,
                    baseUrl
                }
            } catch {
                localStorage.removeItem(`@${sessionName}:usuario`)
                localStorage.removeItem(`@${sessionName}:token`)

                const baseUrl = localStorage.getItem(`@${sessionName}:baseUrl`)

                return { baseUrl } as AuthState
            }
        }

        return {} as AuthState
    })
    const [appUpdated, setAppUpdated] = useState(true)

    const signIn = useCallback((userEncrypted: string, token: string, baseUrl: string) => {
        localStorage.setItem(`@${sessionName}:usuario`, userEncrypted)
        localStorage.setItem(`@${sessionName}:token`, token)

        setData({
            usuario: userDecrypt(userEncrypted),
            token,
            baseUrl
        })
    }, [])

    const signOut = useCallback(() => {
        localStorage.removeItem(`@${sessionName}:usuario`)
        localStorage.removeItem(`@${sessionName}:token`)

        const baseUrl = localStorage.getItem(`@${sessionName}:baseUrl`)

        setData({ baseUrl } as AuthState)
    }, [])

    const validateToken = useCallback(async () => {
        if (!!data.baseUrl && !!data.token) {
            await axios
                .post(`${data.baseUrl}/validarToken `, {
                    token: data.token,
                    versao: appVersion
                })
                .then(response => {
                    const { valido, android, ios } = response.data

                    if (!!appVersion) {
                        const platform = isPlatform('ios')
                        if (platform) {
                            setAppUpdated(
                                parseInt(ios.replace('.', '')) <=
                                parseInt(appVersion.replace('.', ''))
                            )
                        } else {
                            setAppUpdated(
                                parseInt(android.replace('.', '')) <=
                                parseInt(appVersion.replace('.', ''))
                            )
                        }
                    } else {
                        setAppUpdated(true)
                    }

                    if (valido !== "S") {
                        signOut()
                    }
                })
        } else {
            signOut()
        }
    }, [data.baseUrl, data.token, signOut])

    const alterarSenha = (senha_temp: "S" | "N") => {
        setData(state => {
            let newState = { ...state };

            newState.usuario = {
                ...state.usuario,
                senha_temp
            };

            localStorage.setItem(
                `@${sessionName}:usuario`,
                userEncrypt({ ...state.usuario, senha_temp })
            );

            return newState;
        })
    }

    const atualizarAssinatura = (assinatura: Usuario["assinatura"]) => {
        setData(state => {
            let newState = { ...state };

            newState.usuario = {
                ...state.usuario,
                assinatura
            };

            localStorage.setItem(
                `@${sessionName}:usuario`,
                userEncrypt({ ...state.usuario, assinatura })
            );

            return newState;
        })
    }

    return (
        <AuthContext.Provider
            value={{
                usuario: data.usuario,
                appUpdated,
                signIn,
                signOut,
                validateToken,
                alterarSenha,
                atualizarAssinatura
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

function useAuth(): AuthContextData {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }

    return context;
}

export { AuthProvider, useAuth };
