import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonItemDivider,
  IonLabel,
  IonRow,
  IonText,
  useIonViewDidEnter,
  useIonViewDidLeave
} from "@ionic/react";
import { banSharp, personAdd } from "ionicons/icons";

import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { useLoading } from "../../../hooks/loading";
import { useValidation } from "../../../hooks/validation";
import { useToast } from "../../../hooks/toast";

import api from "../../../services/api";
import formValidate from "./schema";

import Main from "../../../components/Main"
import Alert from "../../../components/Alert";
import Card from "../../../components/Card";
import Label from "../../../components/Label"
import Input from "../../../components/Input"
import Select, { Option } from "../../../components/Select"
import ReactSelect from "../../../components/ReactSelect"
import ReactInputMask from "../../../components/ReactInputMask"
import Textarea from "../../../components/Textarea"

import tipoProcedimento from "../../../helpers/tipoProcedimento";
import procHomeCare from "../../../helpers/procHomeCare";

interface FormValues {
  id_paciente: number;
  data: string;
  pediatrico: "N" | "S";
  id_hospital: number;
  id_convenio: number;
  excepcional: "N" | "S";
  convenio_excepcional: string;
  localizacao: string;
  leito: string;
  proc: string;
  tempo: string;
  acesso: "CDL" | "CDL-T" | "CTL" | "FAV" | "PERMCATH" | "TENCKHOFF";
  sitio: "MSD" | "MSE" | "MID" | "MIE" | "VJID" | "VJIE" | "VSCD" | "VSCE" | "VFD" | "VFE";
  cateter?: "N" | "Y";
  cateter_tipo: "N" | "C";
  tenckhoff: "N" | "T";
  cateter_motivo: "IN" | "OB" | "SA" | "BF" | "OM";
  precaucao: "N" | "S";
  indice_captacao: "IDC" | "PDC" | "JPDC" | "PC";
  tempo_cateter: number;
  sessoes_dialise: number;
  diagnostico: "IRA" | "DRC 5D" | "DRC (A)";
  diagnostico_causa: string;
  doencas_pre_existentes: string;

  descricao_banho1: string;
  descricao_banho2: string;
  descricao_banho3: string;

  aminas_vasoativas: "N" | "S";
}

interface Atendimento {
  id: number;
  situacao: string;
  data: string;
  paciente: string;
  proc: string;
  tempo: string;
  precaucao: string;
  hospital: string
  localizacao: string
  leito: string
  acesso: string
  sitio: string
}

const initialData: FormValues = {
  pediatrico: "N",
  tempo_cateter: 0,
  sessoes_dialise: 0,
  aminas_vasoativas: "N"
} as FormValues;

const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const MedicoPrescricao = () => {
  const { showLoading, hideLoading, showSaveMessage, hideSaveMessage } =
    useLoading();
  const { getValidationErrors } = useValidation();
  const { successToast } = useToast();
  const query = useQuery();

  const [page, setPage] = useState(1);
  const [scroll, setScroll] = useState(new Date());
  const [button, setButton] = useState({
    label: "Próximo"
  })

  const formRef = useRef<FormHandles>(null)
  const [formValues, setFormValues] = useState<FormValues>(initialData)
  const [id, setId] = useState(0);
  const [nomePaciente, setNomePaciente] = useState("");
  const [buscarPaciente, setBuscarPaciente] = useState(true);
  const [pediatrico, setPediatrico] = useState("N")
  const [convenio, setConvenio] = useState<null | number>(0)
  const [excepcional, setExcepcional] = useState<FormValues["excepcional"]>("N")
  const [nomeProc, setNomeProc] = useState("");
  const [acesso, setAcesso] = useState("")
  const [cateter, setCateter] = useState("N")
  const [cateterTipo, setCateterTipo] = useState("")
  const [sessoesDialise, setSessoesDialises] = useState(0)
  const [diagnostico, setDiagnostico] = useState("")
  const [doencasPreExistentes, setDoencasPreExistentes] = useState<string[]>([])

  const [aminasVasoativas, setAminasVasoativas] = useState("N")
  const [peso, setPeso] = useState(0)
  const [fluxoDialisato, setFluxoDialisato] = useState(0)
  const [fluxoReposicao, setFluxoReposicao] = useState(0)
  const [tipoHeparina, setTipoHeparina] = useState("")

  const [alertDialiseAgendada, setAlertDialiseAgendada] = useState(false)
  const [alertExcepcional, setAlertExcepcional] = useState(false)

  useEffect(() => {
    if (page === 1) {
      setButton({ label: "Próximo" })
    } else if (page === 2) {
      setButton({ label: "Anterior" })
    }
  }, [page])

  const nextPage = async () => {
    await formValidate
      .validate(formRef?.current?.getData())
      .then(async () => {
        setPage(2);
        setScroll(new Date());
      })
      .catch(error => {
        getValidationErrors(error);
      })
  }

  const previousPage = () => {
    setPage(1);
    setScroll(new Date());
  }

  const [atendimentos, setAtendimentos] = useState<Atendimento[]>([])

  const getList = async () => {
    showLoading();
    await api.get("/medico/prescricao").then(response => {
      if (response?.data) setAtendimentos(response.data);
    })
    hideLoading();
  }

  useIonViewDidEnter(() => {
    getList()
    setPage(1)
    handleClear()
  }, [])

  useIonViewDidLeave(() => {
    setPage(2)
    handleClear()
  }, [])

  const getForm = useCallback(async (id: number, repetir?: boolean) => {
    if (id > 0) {
      handleClear(true)
      showLoading()

      setBuscarPaciente(false);

      await api.get(`/medico/prescricao/${id}`, { params: { repetir: repetir ? "S" : "N" } })
        .then(response => {
          if (response?.data) {
            const {
              id_paciente,
              pediatrico,
              id_convenio,
              proc,
              acesso,
              cateter,
              diagnostico,
              doencas_pre_existentes,
              sessoes_dialise,
              tipo_heparina
            } = response?.data

            setNomePaciente(id_paciente?.label);
            setPediatrico(pediatrico);
            setConvenio(id_convenio?.value);
            setNomeProc(proc);
            setAcesso(acesso);
            setCateter(cateter);
            setDiagnostico(diagnostico);
            setDoencasPreExistentes(doencas_pre_existentes);
            setSessoesDialises(sessoes_dialise);
            setTipoHeparina(tipo_heparina);

            setPage(1);
            formRef?.current?.setData(response.data);

            setScroll(new Date());
          }
        })

      hideLoading();
    }
  }, [hideLoading, showLoading])

  useEffect(() => {
    getForm(id)
  }, [getForm, id])

  useIonViewDidEnter(() => {
    const id_paciente = query.get("id_paciente")
    const nome_paciente = query.get("nome_paciente")
    const id_atendimento = query.get("id_atendimento")
    const repetir = query.get("repetir")

    if (!!id_paciente && !!nome_paciente) {
      setBuscarPaciente(true);
      formRef?.current?.setFieldValue("id_paciente", {
        value: id_paciente,
        label: nome_paciente
      })
    } else if (!!id_atendimento) {
      if (repetir === "S") {
        getForm(parseInt(id_atendimento), true)
      } else {
        setId(parseInt(id_atendimento))
      }
    }
  }, [query])

  const changeProc = (value: string) => {
    setNomeProc(value)
  }

  useEffect(() => {
    if (nomeProc === "CVVHDF") {
      if (parseFloat(`${peso}`) > 0) {
        const dose_dialise_prescrita = (
          (parseFloat(`${fluxoDialisato}`) + parseFloat(`${fluxoReposicao}`)) /
          parseFloat(`${peso}`)
        ).toFixed(2)
        formRef?.current?.setFieldValue(
          "dose_dialise_prescrita",
          dose_dialise_prescrita
        )
      } else {
        formRef?.current?.setFieldValue("dose_dialise_prescrita", 0)
      }
    }
  }, [fluxoDialisato, nomeProc, fluxoReposicao, peso])


  useEffect(() => {
    if (tipoProcedimento(nomeProc) === "DPA" || tipoProcedimento(nomeProc) === "CAPD") {
      formRef?.current?.setFieldValue("acesso", "TENCKHOFF")
      setAcesso("TENCKHOFF")
    } else {
      if (acesso === "TENCKHOFF") {
        formRef?.current?.clearField("acesso");
        setAcesso("")
      }
    }

    if (tipoProcedimento(nomeProc) === "DPA") {
      formRef?.current?.setFieldValue("tipo_heparina", "LV");
      setTipoHeparina("LV");
    }

    if (tipoProcedimento(nomeProc) === "C") {
      formRef?.current?.setFieldValue("tempo", "24:00")
    }
  }, [acesso, nomeProc])

  const getPaciente = async (paciente: Option) => {
    if (paciente?.value > 0 && buscarPaciente === true) {
      handleClear()
      showLoading()

      await api
        .get(`/medico/paciente/${paciente?.value}`)
        .then(response => {
          if (!!response?.data) {
            const {
              pediatrico,
              id_convenio,
              proc,
              acesso,
              cateter,
              diagnostico,
              doencas_pre_existentes,
              sessoes_dialise,
              tipo_heparina
            } = response?.data;

            setNomePaciente(paciente?.label);
            setPediatrico(pediatrico);
            setConvenio(id_convenio?.value);
            setNomeProc(proc);
            setAcesso(acesso);
            setCateter(cateter);
            setDiagnostico(diagnostico);
            setDoencasPreExistentes(doencas_pre_existentes);
            setSessoesDialises(sessoes_dialise);
            setTipoHeparina(tipo_heparina);

            formRef?.current?.setData(response.data);

            setScroll(new Date());
          }
        })

      hideLoading();
    }

    setBuscarPaciente(true);
  }

  const createOrUpdate = async (values?: FormValues) => {
    showSaveMessage()
    if (id > 0) {
      await api
        .put(`medico/prescricao/${id}`, !!values ? values : formValues)
        .then(_response => {
          submitSuccess()
        })
        .catch(error => {
          getValidationErrors(error.response)
        })
    } else {
      await api
        .post("medico/prescricao", !!values ? values : formValues)
        .then(_response => {
          submitSuccess()
        })
        .catch(error => {
          getValidationErrors(error.response)
        })
    }
    hideSaveMessage()
  }

  const submitSuccess = () => {
    successToast()
    handleClear()
    setPage(1)
    getList()
  }

  const handleSubmit: SubmitHandler = async (values: FormValues) => {
    if (parseInt(`${values?.id_convenio}`) === 0 && excepcional !== "S") {
      setAlertExcepcional(true);
    } else {
      if (id > 0) {
        createOrUpdate({ ...values, excepcional })
      } else {
        const { id_paciente, data } = values;

        await api
          .post("medico/prescricao/dialise-agendada", {
            id_paciente,
            data
          })
          .then(response => {
            if (response.data === "S") {
              setFormValues({ ...values, excepcional });
              setAlertDialiseAgendada(true)
            } else {
              createOrUpdate({ ...values, excepcional })
            }
          })
      }
    }
  }

  const handleExcepcional = () => {
    setExcepcional("S");
    formRef?.current?.submitForm();
  }

  const handleClear = (rememberId?: boolean) => {
    formRef?.current?.reset(initialData);
    setNomePaciente("");
    setPediatrico("N");
    setConvenio(null);
    setExcepcional("N");
    setNomeProc("");
    setAcesso("");
    setCateter("N");
    setCateterTipo("");
    setDiagnostico("");
    setDoencasPreExistentes([]);
    setSessoesDialises(0);
    setAminasVasoativas("N");
    setPeso(0);
    setFluxoDialisato(0);
    setFluxoReposicao(0);

    if (!rememberId) setId(0);
  }

  return (
    <Main title="Prescrição" scroll={scroll}>
      <Card>
        <IonCardHeader>
          <IonGrid className="ion-align-items-center">
            <IonRow className="ion-align-items-end">
              <IonCol size="6">
                {nomeProc !== "VISITA HOSPITALAR" && nomeProc !== "PARECER" && (
                  <IonButton
                    type="button"
                    color="dark"
                    expand="block"
                    onClick={() => (page === 1 ? nextPage() : previousPage())}
                  >
                    {button.label}
                  </IonButton>
                )}
              </IonCol>
              <IonCol size="6">
                <Link to="/medico/paciente" style={{ textDecoration: "none" }}>
                  <IonButton type="button" color="warning" expand="block">
                    <IonIcon slot="start" icon={personAdd} />
                    Paciente
                  </IonButton>
                </Link>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardHeader>
        <IonCardContent>
          <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
            <IonGrid>
              <IonRow className="ion-align-items-end">
                <ReactSelect
                  size={`${page === 1 ? "10" : "12"}`}
                  label="Paciente"
                  name="id_paciente"
                  url="/medico/select/paciente"
                  params={{
                    obito: "N",
                    data_nascimento: "S",
                    matricula: "S"
                  }}
                  onChange={(option: Option, actionMeta) => {
                    const { action } = actionMeta;
                    const label = option?.label

                    if (action !== "clear" && nomePaciente !== label) {
                      getPaciente(option)
                    }
                  }}
                  required
                  simple
                  disabled={page !== 1 || id > 0}
                />
                {page === 1 && (
                  <IonCol size="2">
                    <IonButton
                      color="danger"
                      type="button"
                      onClick={() => handleClear()}
                    >
                      <IonIcon icon={banSharp} />
                    </IonButton>
                  </IonCol>
                )}
                <Input
                  size={`${page === 1 ? "7" : "12"}`}
                  label="Data"
                  name="data"
                  type="date"
                  required
                  disabled={page !== 1}
                />
                <Select
                  size="5"
                  label="Pediátrico?"
                  name="pediatrico"
                  values={[
                    { value: "N", label: "NÃO" },
                    { value: "S", label: "SIM" }
                  ]}
                  onChange={(value: string) => setPediatrico(value)}
                  required
                  hidden={page !== 1}
                />
                <ReactSelect
                  label="Hospital"
                  name="id_hospital"
                  url="/medico/select/hospital"
                  params={{ status: "A" }}
                  required
                  simple
                  hidden={page !== 1}
                />
                <ReactSelect
                  label="Convênio"
                  name="id_convenio"
                  url="/medico/select/convenio"
                  params={{ status: "A" }}
                  onChange={(option: Option) =>
                    setConvenio(parseInt(`${option?.value}`))
                  }
                  required
                  simple
                  hidden={page !== 1}
                />
                <Input
                  label="Nome do Convênio"
                  name="convenio_excepcional"
                  type="text"
                  required
                  hidden={page !== 1 || convenio !== 0}
                />
                <Select
                  label="Localização"
                  name="localizacao"
                  values={[
                    { value: "CTI", label: "CTI" },
                    { value: "ENFERMARIA", label: "ENFERMARIA" },
                    { value: "QUARTO", label: "QUARTO" },
                    { value: "POI", label: "POI" },
                    { value: "PÓS", label: "PÓS" },
                    { value: "UCO", label: "UCO" },
                    { value: "UCI", label: "UCI" },
                    { value: "UCIC", label: "UCIC" },
                    { value: "OUTROS", label: "OUTROS" }
                  ]}
                  required
                  hidden={page !== 1}
                />
                <Input
                  label="Leito"
                  name="leito"
                  type="text"
                  required
                  hidden={page !== 1}
                />
                <Select
                  size="7"
                  label="Procedimento"
                  name="proc"
                  onChange={(value: string) => changeProc(value)}
                  values={[
                    { value: "DPAP (PEDIATRIA)", label: "DPAP (PEDIATRIA)", disabled: pediatrico !== "S" },
                    { value: "HDCP (PEDIATRIA)", label: "HDCP (PEDIATRIA)", disabled: pediatrico !== "S" },
                    { value: "HDIP (PEDIATRIA)", label: "HDIP (PEDIATRIA)", disabled: pediatrico !== "S" },
                    { value: "HDPP (PEDIATRIA)", label: "HDPP (PEDIATRIA)", disabled: pediatrico !== "S" },
                    { value: "BURETA", label: "BURETA", disabled: pediatrico === "S" },
                    { value: "CAPD", label: "CAPD", disabled: pediatrico === "S" },
                    { value: "CVVHD", label: "CVVHD", disabled: pediatrico === "S" },
                    { value: "CVVHDF", label: "CVVHDF", disabled: pediatrico === "S" },
                    { value: "DIÁLISE PERITONIAL DOMICILIAR", label: "DIÁLISE PERITONIAL DOMICILIAR", disabled: pediatrico === "S" },
                    { value: "DPA", label: "DPA", disabled: pediatrico === "S" },
                    { value: "HDC", label: "HDC", disabled: pediatrico === "S" },
                    { value: "HDF", label: "HDF", disabled: pediatrico === "S" },
                    { value: "HDI", label: "HDI", disabled: pediatrico === "S" },
                    { value: "HDP", label: "HDP", disabled: pediatrico === "S" },
                    { value: "HEMODIÁLISE DOMICILIAR", label: "HEMODIÁLISE DOMICILIAR", disabled: pediatrico === "S" },
                    { value: "PARECER", label: "PARECER", disabled: pediatrico === "S" },
                    { value: "PLASMAFERESE", label: "PLASMAFERESE", disabled: pediatrico === "S" },
                    { value: "VISITA HOSPITALAR", label: "VISITA HOSPITALAR", disabled: pediatrico === "S" },
                  ]}
                  required
                  disabled={page !== 1}
                />
                <ReactInputMask
                  size="5"
                  label="Tempo (horas)"
                  name="tempo"
                  mask="99:99"
                  required
                  disabled={page !== 1}
                />
                <Select
                  label="Acesso"
                  name="acesso"
                  onChange={(value: string) => setAcesso(value)}
                  values={
                    [
                      { value: "CDL", label: "CDL" },
                      { value: "CDL-T (TROCA)", label: "CDL-T (TROCA)" },
                      { value: "CTL", label: "CTL" },
                      { value: "FAV", label: "FAV" },
                      { value: "PERMCATH", label: "PERMCATH" },
                      { value: "TENCKHOFF", label: "TENCKHOFF", disabled: true },
                      { value: "A DEFINIR", label: "A DEFINIR" },
                    ]}
                  required
                  disabled={acesso === "TENCKHOFF"}
                  hidden={page !== 1}
                />
                <Input
                  label="Data de Confecção/Implante do Acesso Venosso"
                  name="acesso_data"
                  type="date"
                  required
                  hidden={page !== 1 || acesso === "A DEFINIR"}
                />
                <Select
                  label="Sitio"
                  name="sitio"
                  values={[
                    { value: "MSD (MEMBRO SUPERIOR DIREITO)", label: "MSD (MEMBRO SUPERIOR DIREITO)", disabled: acesso !== "FAV" },
                    { value: "MSE (MEMBRO SUPERIOR ESQUERDO)", label: "MSE (MEMBRO SUPERIOR ESQUERDO)", disabled: acesso !== "FAV" },
                    { value: "MID (MEMBRO INFERIOR DIREITO)", label: "MID (MEMBRO INFERIOR DIREITO)", disabled: acesso !== "FAV" },
                    { value: "MIE (MEMBRO INFERIOR ESQUERDO)", label: "MIE (MEMBRO INFERIOR ESQUERDO)", disabled: acesso !== "FAV" },
                    { value: "VJID (VEIA JUGULAR INTERNA DIREITA)", label: "VJID (VEIA JUGULAR INTERNA DIREITA)", disabled: acesso === "FAV" },
                    { value: "VJIE (VEIA JUGULAR INTERNA ESQUERDA)", label: "VJIE (VEIA JUGULAR INTERNA ESQUERDA)", disabled: acesso === "FAV" },
                    { value: "VSCD (VEIA SUBCLAVIA CENTRAL DIREITA)", label: "VSCD (VEIA SUBCLAVIA CENTRAL DIREITA)", disabled: acesso === "FAV" },
                    { value: "VSCE (VEIA SUBCLAVIA CENTRAL ESQUERDA)", label: "VSCE (VEIA SUBCLAVIA CENTRAL ESQUERDA)", disabled: acesso === "FAV" },
                    { value: "VFD (VEIA FEMURAL DIREITA)", label: "VFD (VEIA FEMURAL DIREITA)", disabled: acesso === "FAV" },
                    { value: "VFE (VEIA FEMURAL ESQUERDA)", label: "VFE (VEIA FEMURAL ESQUERDA)", disabled: acesso === "FAV" }
                  ]}
                  required
                  hidden={page !== 1 || acesso === "" || acesso === "TENCKHOFF"}
                />
                <Select
                  size="5"
                  label="Implantou Cateter?"
                  name="cateter"
                  onChange={(value: string) => setCateter(value)}
                  values={[
                    { value: "N", label: "NÃO" },
                    { value: "S", label: "SIM" }
                  ]}
                  required
                  hidden={page !== 1}
                />
                <Select
                  size="7"
                  label="Cateter Tipo"
                  name="cateter_tipo"
                  onChange={(value: string) => setCateterTipo(value)}
                  values={[
                    { value: "N", label: "NOVO" },
                    { value: "T", label: "TROCA" },
                  ]}
                  required
                  hidden={page !== 1 || cateter !== "S"}
                />
                <Select
                  size="6"
                  label="Tenckhoff"
                  name="tenckhoff"
                  values={[
                    { value: "N", label: "NORMAL" },
                    { value: "T", label: "TENCKHOFF" },
                  ]}
                  required
                  hidden={page !== 1 || cateter !== "S" || tipoProcedimento(nomeProc) !== "DPA"}
                />
                <Select
                  size="12"
                  label="Motivo da Troca"
                  name="cateter_motivo"
                  values={[
                    { value: "IN", label: "SINAIS E/OU SINTOMAS DE INFECÇÃO" },
                    { value: "OB", label: "OBSTRUÇÃO" },
                    { value: "SA", label: "SANGRAMENTO" },
                    { value: "BA", label: "BAIXO FLUXO" },
                    { value: "RO", label: "ORDEM MÉDICA" },
                  ]}
                  required
                  hidden={page !== 1 || cateter !== "S" || cateterTipo !== "T"}
                />
                <Select
                  size={`${cateter === "Y" ? "6" : "7"}`}
                  label="Precaução"
                  name="precaucao"
                  values={[
                    { value: "N", label: "NÃO" },
                    { value: "Y", label: "SIM" }
                  ]}
                  required
                  hidden={page !== 1}
                />
                <Select
                  label="Índice de Captação"
                  name="indice_captacao"
                  values={[
                    { value: "I", label: "IMPROVÁVEL PARA DIÁLISE CRÔNICA" },
                    { value: "P", label: "PROVÁVEL PARA DIÁLISE CRÔNICA" },
                    { value: "M", label: "JÁ EM PROGRAMA DE DIÁLISE CRÔNICA" },
                    { value: "PP", label: "PROVÁVEL PARA CONSULTÓRIO" },
                  ]}
                  required
                  hidden={page !== 1}
                />
                <Input
                  size="6"
                  label="Tempo de Permanência de Cateter"
                  name="tempo_cateter"
                  type="number"
                  disabled
                  hidden={page !== 1}
                />
                <Input
                  size="6"
                  label="Nº de Sessões de Diálise"
                  name="sessoes_dialise"
                  type="number"
                  disabled
                  hidden={page !== 1}
                />
                <Select
                  size="12"
                  label="Diagnóstico"
                  name="diagnostico"
                  onChange={(value: string) => setDiagnostico(value)}
                  values={[
                    { value: "IRA", label: "IRA" },
                    { value: "DRC 5D", label: "DRC 5D" },
                    { value: "DRC (A)", label: "DRC (A)" },
                  ]}
                  required
                  hidden={page !== 1}
                />
                <Select
                  label="Causa"
                  name="diagnostico_causa"
                  values={[
                    { value: "SEPSIS", label: "SEPSIS" },
                    { value: "PACIENTE CRÍTICO", label: "PACIENTE CRÍTICO" },
                    { value: "CHOQUE CIRCULATÓRIO", label: "CHOQUE CIRCULATÓRIO" },
                    { value: "GRANDE QUEIMADO", label: "GRANDE QUEIMADO" },
                    { value: "CIRURGIA CARDÍACA", label: "CIRURGIA CARDÍACA" },
                    { value: "CIRURGIA NÃO-CARDÍACA DE GRANDE PORTE", label: "CIRURGIA NÃO-CARDÍACA DE GRANDE PORTE" },
                    { value: "NEFROTOXICIDADE", label: "NEFROTOXICIDADE" },
                    { value: "AGENTES DE RADIOCONTRASTE", label: "AGENTES DE RADIOCONTRASTE" },
                    { value: "PLANTAS E ANIMAIS VENENOSOS", label: "PLANTAS E ANIMAIS VENENOSOS" },
                    { value: "CAUSAS OBSTÉTRICAS", label: "CAUSAS OBSTÉTRICAS" },
                    { value: "OBSTRUTIVA", label: "OBSTRUTIVA" },
                  ]}
                  multiple
                  required
                  hidden={page !== 1 || (diagnostico !== "IRA" && diagnostico !== "DRC (A)")}
                />
                <Select
                  label="Doenças Pré Existentes"
                  name="doencas_pre_existentes"
                  onChange={(values: string[]) => {
                    !!values &&
                      setDoencasPreExistentes(() =>
                        values.map((value: string) => {
                          return value;
                        })
                      );
                  }
                  }
                  values={[
                    { value: "DIABETES", label: "DIABETES" },
                    { value: "HIPERTENSÃO ARTERIAL", label: "HIPERTENSÃO ARTERIAL" },
                    { value: "DOENÇA IMUNOLÓGICA", label: "DOENÇA IMUNOLÓGICA" },
                    { value: "DRC NÃO DIALÍTICA", label: "DRC NÃO DIALÍTICA" },
                    { value: "DESIDRATAÇÃO", label: "DESIDRATAÇÃO" },
                    { value: "DOENÇA CRÔNICA CARDÍACA", label: "DOENÇA CRÔNICA CARDÍACA" },
                    { value: "DOENÇA CRÔNICA PULMONAR", label: "DOENÇA CRÔNICA PULMONAR" },
                    { value: "DOENÇA CRÔNICA HEPÁTICA", label: "DOENÇA CRÔNICA HEPÁTICA" },
                    { value: "CÂNCER", label: "CÂNCER" },
                    { value: "OUTROS", label: "OUTROS" },
                  ]}
                  multiple
                  required
                  hidden={page !== 1 || !diagnostico}
                />
                <Input
                  size="12"
                  label="Qual outra Doença Pré Existente?"
                  name="outra_doenca_pre_existente"
                  type="text"
                  hidden={page !== 1 || !diagnostico || !doencasPreExistentes || !doencasPreExistentes.includes("OUTROS")}
                />
                <Select
                  size="6"
                  label="Classificação"
                  name="classificacao"
                  values={[
                    { value: 0, label: "NORMAL" },
                    { value: 1, label: "PRIORIDADE" },
                    { value: 2, label: "URGÊNCIA" },
                    { value: 3, label: "EMERGÊNCIA" }
                  ]}
                  required
                  hidden={page !== 1}
                />
                <Select
                  size="6"
                  label="Conservador"
                  name="conservador"
                  values={[
                    { value: "N", label: "NÃO" },
                    { value: "Y", label: "SIM" }
                  ]}
                  required
                  hidden={page !== 1 || nomeProc !== "VISITA HOSPITALAR"}
                />
                <Textarea
                  label="Relatório Médico"
                  name="relatorio_medico"
                  rows={5}
                  required
                  hidden={page !== 1 || !(nomeProc === "VISITA HOSPITALAR" || nomeProc === "PARECER" || sessoesDialise === 0 || (sessoesDialise + 1) % 5 === 0 || tipoProcedimento(nomeProc) === "C")}
                />
                <Input
                  size="6"
                  label="Priming"
                  name="priming"
                  type="text"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) === "DPA" || tipoProcedimento(nomeProc) === "CAPD"}
                />
                <Select
                  size="6"
                  label="Tipo de Capilar"
                  name="tipo_capilar"
                  values={[
                    { value: "CAPILAR HI PS 15 USO ÚNICO", label: "CAPILAR HI PS 15 USO ÚNICO" },
                    { value: "CAPILAR ALTO FLUXO FX 60 CLASSIX USO ÚNICO", label: "CAPILAR ALTO FLUXO FX 60 CLASSIX USO ÚNICO" },
                    { value: "CAPILAR ALTO FLUXO FX 80 CLASSIX USO ÚNICO", label: "CAPILAR ALTO FLUXO FX 80 CLASSIX USO ÚNICO" },
                    { value: "CAPILAR DIACAP HI PS 18 - REUSO / ALTO FLUXO", label: "CAPILAR DIACAP HI PS 18 - REUSO / ALTO FLUXO" },
                    { value: "CAPILAR HELIXONE FX PAED USO ÚNICO", label: "CAPILAR HELIXONE FX PAED USO ÚNICO" },
                    { value: "CAPILAR HELIXONE FX 100 USO ÚNICO", label: "CAPILAR HELIXONE FX 100 USO ÚNICO" },
                    { value: "CAPILAR HEMOFLOW F04 HPS REUSO", label: "CAPILAR HEMOFLOW F04 HPS REUSO" },
                    { value: "DIALISADOR FIBRA OCA DORA(BAIN) B-18HF (USO ÚNICO)", label: "DIALISADOR FIBRA OCA DORA(BAIN) B-18HF (USO ÚNICO)" },
                    { value: "DIALISADOR FIBRA OCA DORA(BAIN) B-20HF (USO ÚNICO)", label: "DIALISADOR FIBRA OCA DORA(BAIN) B-20HF (USO ÚNICO)" },
                  ]}
                  hidden={page !== 2 || tipoProcedimento(nomeProc) === "DPA" || tipoProcedimento(nomeProc) === "CAPD"}
                />
                <Select
                  size="6"
                  label="Tipo de Linha"
                  name="tipo_linha"
                  values={[
                    { value: "KIT LINHAS ARTERIAL/VENOSA - USO", label: "KIT LINHAS ARTERIAL/VENOSA - USO" },
                    { value: "LINHA ARTERIAL NEONATAL", label: "LINHA ARTERIAL NEONATAL" },
                    { value: "LINHA ARTERIAL PEDIÁTRICA", label: "LINHA ARTERIAL PEDIÁTRICA" },
                    { value: "LINHA DE CÁLCIO (PRISMAFLEX)", label: "LINHA DE CÁLCIO (PRISMAFLEX)" },
                    { value: "LINHA VENOSA COM CATABOLHA E COM EXTENSOR", label: "LINHA VENOSA COM CATABOLHA E COM EXTENSOR" },
                    { value: "LINHA VENOSA NEONATAL", label: "LINHA VENOSA NEONATAL" },
                    { value: "LINHA VENOSA PEDIÁTRICA", label: "LINHA VENOSA PEDIÁTRICA" },
                  ]}
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "C"}
                />
                <Select
                  size="6"
                  label="Kit"
                  name="kit"
                  values={[
                    { value: "M100", label: "M100" },
                    { value: "OXIRIS", label: "OXIRIS" },
                    { value: "OMNI 1.6", label: "OMNI 1.6" },
                    { value: "DIAPACT", label: "DIAPACT" },

                  ]}
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "C"}
                />
                <Input
                  size="6"
                  label="Ultrafiltração (ml/hora)"
                  name="ultrafiltracao"
                  type="number"
                  max="999999.99"
                  step="0.01"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "H"}
                />
                <Input
                  size="6"
                  label="UF (mL por sessão)"
                  name="uf"
                  type="number"
                  max="999999.99"
                  step="0.01"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) === "DPA" || tipoProcedimento(nomeProc) === "CAPD"}
                />
                <Input
                  size="6"
                  label="Fluxo de Sangue (ml/min)"
                  name="fluxo_sangue"
                  type="number"
                  max="999999.99"
                  step="0.01"
                  hidden={page !== 2 || (tipoProcedimento(nomeProc) !== "H" && tipoProcedimento(nomeProc) !== "C")}
                />
                <Input
                  size="6"
                  label={tipoProcedimento(nomeProc) === "C" ? "Fluxo Dialisato (ml/h)" : "Fluxo Dialisato (ml/min)"}
                  name="fluxo_dialisato"
                  type="number"
                  onChange={value => setFluxoDialisato(value)}
                  max="999999.99"
                  step="0.01"
                  hidden={page !== 2 || (tipoProcedimento(nomeProc) !== "H" && tipoProcedimento(nomeProc) !== "C")}
                />
                <Select
                  size="6"
                  label="Solução Básica"
                  name="solucao_basica"
                  values={[
                    { value: "S", label: "COM GLICOSE" },
                    { value: "N", label: "SEM GLICOSE" }
                  ]}
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "H"}
                />
                <Input
                  size="6"
                  label="Volume Total (ml)"
                  name="volume_total"
                  type="number"
                  max="999999.99"
                  step="0.01"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "DPA"}
                />
                <Input
                  size="6"
                  label="Volume de Infusão (ml)"
                  name="volume_infusao"
                  type="number"
                  max="999999.99"
                  step="0.01"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "DPA"}
                />
                <Input
                  size="6"
                  label="Volume da Última Infusão (ml)"
                  name="volume_ultima_infusao"
                  type="number"
                  max="999999.99"
                  step="0.01"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "DPA"}
                />
                <Input
                  size="6"
                  label="Tempo Total"
                  name="tempo_total"
                  type="number"
                  max="999999"
                  hidden={page !== 2 || (tipoProcedimento(nomeProc) !== "DPA" && tipoProcedimento(nomeProc) !== "CAPD")}
                />
                <Input
                  size="6"
                  label="Número de Trocas"
                  name="numero_trocas"
                  type="number"
                  max="999999"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "CAPD"}
                />
                <Input
                  size="6"
                  label="Tempo de Permanência"
                  name="tempo_permanencia"
                  type="number"
                  max="999999"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "DPA"}
                />
                <Input
                  size="6"
                  label="Número de Ciclos"
                  name="numero_ciclos"
                  type="number"
                  max="999999"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "DPA"}
                />
                <Input
                  size="6"
                  label="Volume de Infusão por Bolsa"
                  name="volume_infusao_bolsa"
                  type="number"
                  max="999999.99"
                  step="0.01"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "CAPD"}
                />
                <Input
                  size="6"
                  label="Quantidade de Bolsas"
                  name="qtde_bolsas"
                  type="number"
                  max="999999"
                  hidden={page !== 2 || (tipoProcedimento(nomeProc) !== "DPA" && tipoProcedimento(nomeProc) !== "CAPD")}
                />
                <Select
                  size="6"
                  label="Descrição do Banho"
                  name="descricao_banho1"
                  values={[
                    { value: "CONCENTRADO FRAÇÃO ÁCIDA F2 - CÁLCIO 2,5 K 1,5 C/ GLICOSE - GALÃO 5 LITROS", label: "CONCENTRADO FRAÇÃO ÁCIDA F2 - CÁLCIO 2,5 K 1,5 C/ GLICOSE - GALÃO 5 LITROS", disabled: tipoProcedimento(nomeProc) !== "H" },
                    { value: "CONCENTRADO FRACAO ACIDA F2 - CALCIO 3,5 K 1,5 C/ GLICOSE - GALAO 5 LITROS", label: "CONCENTRADO FRACAO ACIDA F2 - CALCIO 3,5 K 1,5 C/ GLICOSE - GALAO 5 LITROS", disabled: tipoProcedimento(nomeProc) !== "H" },
                    { value: "CONCENTRADO FRAÇÃO ÁCIDA F3 - CÁLCIO 3,0  - GALÃO 5 LITROS", label: "CONCENTRADO FRAÇÃO ÁCIDA F3 - CÁLCIO 3,0  - GALÃO 5 LITROS", disabled: tipoProcedimento(nomeProc) !== "H" },
                    { value: "CPHD GENIUS DS 138/35-90 - 686 G - PÓ", label: "CPHD GENIUS DS 138/35-90 - 686 G - PÓ", disabled: tipoProcedimento(nomeProc) !== "H" },
                    { value: "CPHD GENIUS HC 22-90 - 960ML", label: "CPHD GENIUS HC 22-90 - 960ML", disabled: tipoProcedimento(nomeProc) !== "H" },
                    { value: "CPHD GENIUS HC 24-90 - 960ML", label: "CPHD GENIUS HC 24-90 - 960ML", disabled: tipoProcedimento(nomeProc) !== "H" },
                    { value: "CPHD GENIUS HC 32-90 - 960ML", label: "CPHD GENIUS HC 32-90 - 960ML", disabled: tipoProcedimento(nomeProc) !== "H" },
                    { value: "BOLSA DPA 6L 4,25% - BAIXO-CALCIO", label: "BOLSA DPA 6L 4,25% - BAIXO-CALCIO", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "BOLSA DPA-DPI 6 L - 1,5%", label: "BOLSA DPA-DPI 6 L - 1,5%", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "BOLSA DPA-DPI 6 L -1,5% - BAIXO-CALCIO", label: "BOLSA DPA-DPI 6 L -1,5% - BAIXO-CALCIO", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "BOLSA DPA-DPI 6 L 2,5%", label: "BOLSA DPA-DPI 6 L 2,5%", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "BOLSA DPA-DPI 6 L - 2,5% /BAIXO CALCIO", label: "BOLSA DPA-DPI 6 L - 2,5% /BAIXO CALCIO", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "BOLSA DPA-DPI 6 L - 4,25%", label: "BOLSA DPA-DPI 6 L - 4,25%", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "BOLSA ULTRABAG 2 L - 1,5 %", label: "BOLSA ULTRABAG 2 L - 1,5 %", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "BOLSA ULTRABAG 2 L - 2,5%", label: "BOLSA ULTRABAG 2 L - 2,5%", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "BOLSA ULTRABAG 2 L - 4,25 %", label: "BOLSA ULTRABAG 2 L - 4,25 %", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "BOLSA ULTRABAG 2L ICODEXTRINA 7,5%", label: "BOLSA ULTRABAG 2L ICODEXTRINA 7,5%", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "(PD -SENEFRO) BOLSA DPA 6L 4,25% - BAIXO-CALCIO", label: "(PD -SENEFRO) BOLSA DPA 6L 4,25% - BAIXO-CALCIO", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "(PD -SENEFRO) BOLSA DPA-DPI 6 L - 1,5%", label: "(PD -SENEFRO) BOLSA DPA-DPI 6 L - 1,5%", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "(PD -SENEFRO) BOLSA DPA-DPI 6 L -1,5% - BAIXO-CALCIO", label: "(PD -SENEFRO) BOLSA DPA-DPI 6 L -1,5% - BAIXO-CALCIO", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "(PD -SENEFRO) BOLSA DPA-DPI 6 L 2,5%", label: "(PD -SENEFRO) BOLSA DPA-DPI 6 L 2,5%", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "(PD -SENEFRO) BOLSA DPA-DPI 6 L - 2,5% /BAIXO CALCIO", label: "(PD -SENEFRO) BOLSA DPA-DPI 6 L - 2,5% /BAIXO CALCIO", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "(PD -SENEFRO) BOLSA DPA-DPI 6 L - 4,25%", label: "(PD -SENEFRO) BOLSA DPA-DPI 6 L - 4,25%", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 1,5 %", label: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 1,5 %", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 2,5%", label: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 2,5%", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 4,25 %", label: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 4,25 %", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "(PD -SENEFRO) BOLSA DPA ICODEXTRINA 7,5% 2L", label: "(PD -SENEFRO) BOLSA DPA ICODEXTRINA 7,5% 2L", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2,5 L 4,25% - BAIXO CALCIO", label: "(PD -SENEFRO) BOLSA ULTRABAG 2,5 L 4,25% - BAIXO CALCIO", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2 L-1,5% /BAICO-CALCIO", label: "(PD -SENEFRO) BOLSA ULTRABAG 2 L-1,5% /BAICO-CALCIO", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 2,5% /BAIXO-CALCIO", label: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 2,5% /BAIXO-CALCIO", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2L ICODEXTRINA 7,5%", label: "(PD -SENEFRO) BOLSA ULTRABAG 2L ICODEXTRINA 7,5%", disabled: tipoProcedimento(nomeProc) === "H" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 4,25 % /BAIXO CALCIO", label: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 4,25 % /BAIXO CALCIO", disabled: tipoProcedimento(nomeProc) === "H" },
                  ]}
                  hidden={page !== 2}
                />
                <Select
                  size="6"
                  label="Descrição do Banho"
                  name="descricao_banho2"
                  values={[
                    { value: "BOLSA DPA 6L 4,25% - BAIXO-CALCIO", label: "BOLSA DPA 6L 4,25% - BAIXO-CALCIO" },
                    { value: "BOLSA DPA-DPI 6 L - 1,5%", label: "BOLSA DPA-DPI 6 L - 1,5%" },
                    { value: "BOLSA DPA-DPI 6 L -1,5% - BAIXO-CALCIO", label: "BOLSA DPA-DPI 6 L -1,5% - BAIXO-CALCIO" },
                    { value: "BOLSA DPA-DPI 6 L 2,5%", label: "BOLSA DPA-DPI 6 L 2,5%" },
                    { value: "BOLSA DPA-DPI 6 L - 2,5% /BAIXO CALCIO", label: "BOLSA DPA-DPI 6 L - 2,5% /BAIXO CALCIO" },
                    { value: "BOLSA DPA-DPI 6 L - 4,25%", label: "BOLSA DPA-DPI 6 L - 4,25%" },
                    { value: "BOLSA ULTRABAG 2 L - 1,5 %", label: "BOLSA ULTRABAG 2 L - 1,5 %" },
                    { value: "BOLSA ULTRABAG 2 L - 2,5%", label: "BOLSA ULTRABAG 2 L - 2,5%" },
                    { value: "BOLSA ULTRABAG 2 L - 4,25 %", label: "BOLSA ULTRABAG 2 L - 4,25 %" },
                    { value: "BOLSA ULTRABAG 2L ICODEXTRINA 7,5%", label: "BOLSA ULTRABAG 2L ICODEXTRINA 7,5%" },
                    { value: "(PD -SENEFRO) BOLSA DPA 6L 4,25% - BAIXO-CALCIO", label: "(PD -SENEFRO) BOLSA DPA 6L 4,25% - BAIXO-CALCIO" },
                    { value: "(PD -SENEFRO) BOLSA DPA-DPI 6 L - 1,5%", label: "(PD -SENEFRO) BOLSA DPA-DPI 6 L - 1,5%" },
                    { value: "(PD -SENEFRO) BOLSA DPA-DPI 6 L -1,5% - BAIXO-CALCIO", label: "(PD -SENEFRO) BOLSA DPA-DPI 6 L -1,5% - BAIXO-CALCIO" },
                    { value: "(PD -SENEFRO) BOLSA DPA-DPI 6 L 2,5%", label: "(PD -SENEFRO) BOLSA DPA-DPI 6 L 2,5%" },
                    { value: "(PD -SENEFRO) BOLSA DPA-DPI 6 L - 2,5% /BAIXO CALCIO", label: "(PD -SENEFRO) BOLSA DPA-DPI 6 L - 2,5% /BAIXO CALCIO" },
                    { value: "(PD -SENEFRO) BOLSA DPA-DPI 6 L - 4,25%", label: "(PD -SENEFRO) BOLSA DPA-DPI 6 L - 4,25%" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 1,5 %", label: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 1,5 %" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 2,5%", label: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 2,5%" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 4,25 %", label: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 4,25 %" },
                    { value: "(PD -SENEFRO) BOLSA DPA ICODEXTRINA 7,5% 2L", label: "(PD -SENEFRO) BOLSA DPA ICODEXTRINA 7,5% 2L" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2,5 L 4,25% - BAIXO CALCIO", label: "(PD -SENEFRO) BOLSA ULTRABAG 2,5 L 4,25% - BAIXO CALCIO" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2 L-1,5% /BAICO-CALCIO", label: "(PD -SENEFRO) BOLSA ULTRABAG 2 L-1,5% /BAICO-CALCIO" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 2,5% /BAIXO-CALCIO", label: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 2,5% /BAIXO-CALCIO" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2L ICODEXTRINA 7,5%", label: "(PD -SENEFRO) BOLSA ULTRABAG 2L ICODEXTRINA 7,5%" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 4,25 % /BAIXO CALCIO", label: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 4,25 % /BAIXO CALCIO" },
                  ]}
                  hidden={page !== 2 || tipoProcedimento(nomeProc) === "H"}
                />
                <Select
                  size="6"
                  label="Descrição do Banho"
                  name="descricao_banho3"
                  values={[
                    { value: "BOLSA DPA 6L 4,25% - BAIXO-CALCIO", label: "BOLSA DPA 6L 4,25% - BAIXO-CALCIO" },
                    { value: "BOLSA DPA-DPI 6 L - 1,5%", label: "BOLSA DPA-DPI 6 L - 1,5%" },
                    { value: "BOLSA DPA-DPI 6 L -1,5% - BAIXO-CALCIO", label: "BOLSA DPA-DPI 6 L -1,5% - BAIXO-CALCIO" },
                    { value: "BOLSA DPA-DPI 6 L 2,5%", label: "BOLSA DPA-DPI 6 L 2,5%" },
                    { value: "BOLSA DPA-DPI 6 L - 2,5% /BAIXO CALCIO", label: "BOLSA DPA-DPI 6 L - 2,5% /BAIXO CALCIO" },
                    { value: "BOLSA DPA-DPI 6 L - 4,25%", label: "BOLSA DPA-DPI 6 L - 4,25%" },
                    { value: "BOLSA ULTRABAG 2 L - 1,5 %", label: "BOLSA ULTRABAG 2 L - 1,5 %" },
                    { value: "BOLSA ULTRABAG 2 L - 2,5%", label: "BOLSA ULTRABAG 2 L - 2,5%" },
                    { value: "BOLSA ULTRABAG 2 L - 4,25 %", label: "BOLSA ULTRABAG 2 L - 4,25 %" },
                    { value: "BOLSA ULTRABAG 2L ICODEXTRINA 7,5%", label: "BOLSA ULTRABAG 2L ICODEXTRINA 7,5%" },
                    { value: "(PD -SENEFRO) BOLSA DPA 6L 4,25% - BAIXO-CALCIO", label: "(PD -SENEFRO) BOLSA DPA 6L 4,25% - BAIXO-CALCIO" },
                    { value: "(PD -SENEFRO) BOLSA DPA-DPI 6 L - 1,5%", label: "(PD -SENEFRO) BOLSA DPA-DPI 6 L - 1,5%" },
                    { value: "(PD -SENEFRO) BOLSA DPA-DPI 6 L -1,5% - BAIXO-CALCIO", label: "(PD -SENEFRO) BOLSA DPA-DPI 6 L -1,5% - BAIXO-CALCIO" },
                    { value: "(PD -SENEFRO) BOLSA DPA-DPI 6 L 2,5%", label: "(PD -SENEFRO) BOLSA DPA-DPI 6 L 2,5%" },
                    { value: "(PD -SENEFRO) BOLSA DPA-DPI 6 L - 2,5% /BAIXO CALCIO", label: "(PD -SENEFRO) BOLSA DPA-DPI 6 L - 2,5% /BAIXO CALCIO" },
                    { value: "(PD -SENEFRO) BOLSA DPA-DPI 6 L - 4,25%", label: "(PD -SENEFRO) BOLSA DPA-DPI 6 L - 4,25%" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 1,5 %", label: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 1,5 %" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 2,5%", label: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 2,5%" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 4,25 %", label: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 4,25 %" },
                    { value: "(PD -SENEFRO) BOLSA DPA ICODEXTRINA 7,5% 2L", label: "(PD -SENEFRO) BOLSA DPA ICODEXTRINA 7,5% 2L" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2,5 L 4,25% - BAIXO CALCIO", label: "(PD -SENEFRO) BOLSA ULTRABAG 2,5 L 4,25% - BAIXO CALCIO" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2 L-1,5% /BAICO-CALCIO", label: "(PD -SENEFRO) BOLSA ULTRABAG 2 L-1,5% /BAICO-CALCIO" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 2,5% /BAIXO-CALCIO", label: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 2,5% /BAIXO-CALCIO" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2L ICODEXTRINA 7,5%", label: "(PD -SENEFRO) BOLSA ULTRABAG 2L ICODEXTRINA 7,5%" },
                    { value: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 4,25 % /BAIXO CALCIO", label: "(PD -SENEFRO) BOLSA ULTRABAG 2 L - 4,25 % /BAIXO CALCIO" },
                  ]}
                  hidden={page !== 2 || tipoProcedimento(nomeProc) === "H"}
                />
                {page === 2 && tipoProcedimento(nomeProc) === "H" &&
                  <IonItemDivider
                    className="ion-margin-top"
                    color="primary"
                  >
                    <IonLabel>Anticoagulação</IonLabel>
                  </IonItemDivider>
                }
                <IonCol size="12" style={page === 2 && tipoProcedimento(nomeProc) === "H" ? {} : { display: "none" }}>
                  {page === 2 && tipoProcedimento(nomeProc) === "H" &&
                    <IonRow>
                      <IonCol size="12">
                        <Label>Soro Fisiológico (ml/min)</Label>
                      </IonCol>
                    </IonRow>
                  }
                  <IonRow className="ion-align-items-end">
                    <Input
                      size="5"
                      label="mililitros (ml)"
                      name="soro_fisiologico_ml"
                      type="number"
                      max="999999.99"
                      step="0.01"
                      hidden={page !== 2 || tipoProcedimento(nomeProc) !== "H"}
                    />
                    <Input
                      size="4"
                      label="a cada"
                      name="soro_fisiologico_min"
                      type="number"
                      hidden={page !== 2 || tipoProcedimento(nomeProc) !== "H"}
                    />
                    {page === 2 && tipoProcedimento(nomeProc) === "H" &&
                      <IonCol size="3">
                        <Label>minutos</Label>
                      </IonCol>
                    }
                  </IonRow>
                </IonCol>
                <IonCol size="12" style={page === 2 && (tipoProcedimento(nomeProc) === "H" || tipoProcedimento(nomeProc) === "DPA") ? {} : { display: "none" }}>
                  {page === 2 && (tipoProcedimento(nomeProc) === "H" || tipoProcedimento(nomeProc) === "DPA") &&
                    <IonRow>
                      <IonCol size="12">
                        <Label>Heparina</Label>
                      </IonCol>
                    </IonRow>
                  }
                  <IonRow className="ion-align-items-end">
                    <Select
                      size="12"
                      name="tipo_heparina"
                      onChange={(value: string) => setTipoHeparina(value)}
                      values={[
                        { value: "BL", label: "BOLUS" },
                        { value: "FR", label: "FRACIONADA" },
                        { value: "LV", label: "LIVRE DIGITAÇÃO" }
                      ]}
                      hidden={page !== 2 || (tipoProcedimento(nomeProc) !== "H" && tipoProcedimento(nomeProc) !== "DPA")}
                      disabled={tipoProcedimento(nomeProc) === "DPA"}
                    />
                    <Input
                      size={tipoHeparina === "LV" ? "12" : tipoHeparina === "FR" ? "5" : "6"}
                      label={tipoHeparina === "FR" ? "mL" : ""}
                      name="heparina"
                      type={tipoHeparina === "LV" ? "text" : "number"}
                      max="999999.99"
                      step="0.01"
                      hidden={page !== 2 || (tipoProcedimento(nomeProc) !== "H" && tipoProcedimento(nomeProc) !== "DPA")}
                    />
                    {page === 2 && (tipoProcedimento(nomeProc) === "H" || tipoProcedimento(nomeProc) === "DPA") && tipoHeparina === "BL" &&
                      <IonCol size="4">
                        <Label>mL (bolus)</Label>
                      </IonCol>
                    }
                    <Input
                      size="4"
                      label="a cada"
                      name="heparina_min"
                      type="number"
                      hidden={page !== 2 || (tipoProcedimento(nomeProc) !== "H" && tipoProcedimento(nomeProc) !== "DPA") || tipoHeparina !== "FR"}
                    />
                    {page === 2 && (tipoProcedimento(nomeProc) === "H" || tipoProcedimento(nomeProc) === "DPA") && tipoHeparina === "FR" &&
                      <IonCol size="3">
                        <Label>min</Label>
                      </IonCol>
                    }
                  </IonRow>
                </IonCol>
                <Select
                  label="Tipo Selante do Cateter"
                  name="tipo_selante_cateter"
                  values={[
                    { value: "CITRATO 34% (CITRALOCK)", label: "CITRATO 34% (CITRALOCK)" },
                    { value: "CITRATO 4% + HEPARINA (TAUROLOCK EPI 500)", label: "CITRATO 4% + HEPARINA (TAUROLOCK EPI 500)" },
                    { value: "EDTA + MINOCICLINA 9MG (CATHSAFE)", label: "EDTA + MINOCICLINA 9MG (CATHSAFE)" },
                    { value: "CITRATO 4% (TAUROLOCK TM)", label: "CITRATO 4% (TAUROLOCK TM)" },
                    { value: "CONECTOR VALVULADO (TEGO)", label: "CONECTOR VALVULADO (TEGO)" },
                    { value: "SOLUÇÃO HEPARINA", label: "SOLUÇÃO HEPARINA" },
                  ]}
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "H"}
                />
                {page === 2 && tipoProcedimento(nomeProc) === "H" &&
                  <IonItemDivider
                    className="ion-margin-top"
                    color="primary"
                  >
                    <IonLabel>Reposição de Eletrólitos</IonLabel>
                  </IonItemDivider>
                }
                <Input
                  size="6"
                  label="KCL (Ampola)"
                  name="kcl"
                  type="number"
                  max="999999.99"
                  step="0.01"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "H"}
                />
                <Input
                  size="6"
                  label="Fosfato de Sódio (SACHE)"
                  name="fosfato_sodio"
                  type="number"
                  max="999999.99"
                  step="0.01"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "H"}
                />
                {page === 2 &&
                  <IonItemDivider
                    className="ion-margin-top"
                    color="primary"
                  >
                    <IonLabel>Parâmetros da Máquina</IonLabel>
                  </IonItemDivider>
                }
                <Input
                  size="6"
                  label="Bicarbonato"
                  name="anticoagulacao_bicarbonato"
                  type="number"
                  max="999999.99"
                  step="0.01"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "H"}
                />
                <Input
                  size="6"
                  label="Sódio"
                  name="anticoagulacao_sodio"
                  type="number"
                  max="999999.99"
                  step="0.01"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "H"}
                />
                <Input
                  size="6"
                  label="Temperatura"
                  name="temperatura"
                  type="number"
                  max="999999.99"
                  step="0.01"
                  hidden={page !== 2 || (tipoProcedimento(nomeProc) !== "H" && tipoProcedimento(nomeProc) !== "C")}
                />
                <Select
                  size="6"
                  label="KDIGO"
                  name="kdigo"
                  values={[
                    { value: 1, label: "1" },
                    { value: 2, label: "2" },
                    { value: 3, label: "3" },
                  ]}
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "H" || procHomeCare(nomeProc) === "H" || diagnostico === "DRC 5D"}
                />
                <Input
                  size="6"
                  label="UF Total"
                  name="uf_total"
                  type="number"
                  max="999999.99"
                  step="0.01"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) === "DPA"}
                />
                <Input
                  size="6"
                  label="UF Efetiva"
                  name="uf_efetiva"
                  type="number"
                  max="999999.99"
                  step="0.01"
                  hidden={page !== 2}
                />
                <Input
                  size="6"
                  label="Fluxo de Reposição (ml/h)"
                  name="fluxo_reposicao"
                  type="number"
                  onChange={value => setFluxoReposicao(value)}
                  max="999999.99"
                  step="0.01"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "C"}
                />
                <Input
                  size="6"
                  label="Peso (Kg)"
                  name="paciente_peso"
                  type="number"
                  onChange={value => setPeso(value)}
                  max="999999.99"
                  step="0.01"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "C"}
                />
                <Input
                  size="6"
                  label="Peso a Perder"
                  name="paciente_peso_perder"
                  type="number"
                  max="999.99"
                  step="0.01"
                  hidden={page !== 2 || procHomeCare(nomeProc) === "H"}
                />
                <Input
                  size="6"
                  label="Dose de Diálise Prescrita"
                  name="dose_dialise_prescrita"
                  type="number"
                  max="999999.99"
                  step="0.01"
                  disabled
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "C" || nomeProc !== "CVVHDF"}
                />
                <Input
                  size="6"
                  label="Dose de citrato (3mmol/L)"
                  name="dose_citrato"
                  type="number"
                  max="999999.99"
                  step="0.01"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "C" || nomeProc !== "CVVHDF"}
                />
                <Select
                  name="anticoalugacao"
                  label="Anticoagulação"
                  values={[
                    { value: "CITRATO", label: "CITRATO" },
                    { value: "HEPARINA (UTILIZAR DUOSOL)", label: "HEPARINA (UTILIZAR DUOSOL)" },
                    { value: "NENHUMA (UTILIZAR DUOSOL)", label: "NENHUMA (UTILIZAR DUOSOL)" },
                  ]}
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "C"}
                />
                <Input
                  size="6"
                  label="Dose Inicial Citrato"
                  name="citrato_inicial"
                  type="text"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "C"}
                />
                <Input
                  size="6"
                  label="Dose Inicial Cálcio"
                  name="calcio_inicial"
                  type="text"
                  hidden={page !== 2 || tipoProcedimento(nomeProc) !== "C"}
                />
                <IonCol size="12">
                  <IonRow className="ion-align-items-end">
                    <Select
                      size="6"
                      name="aminas_vasoativas"
                      label="Uso de Aminas Vasoativas"
                      onChange={(value: string) => setAminasVasoativas(value)}
                      values={[
                        { value: "Y", label: "SIM" },
                        { value: "N", label: "NÃO" },
                      ]}
                      hidden={page !== 2}
                    />
                    <Input
                      size="6"
                      label="Quantidade (ml/hora)"
                      name="aminas_vasoativas_qtde"
                      type="number"
                      max="50.00"
                      step="0.01"
                      disabled={aminasVasoativas !== "Y"}
                      hidden={page !== 2}
                    />
                  </IonRow>
                </IonCol>
                <Select
                  name="quadro_clinico"
                  label="Quadro Clínico"
                  values={[
                    { value: "ACORDADO", label: "ACORDADO" },
                    { value: "COMA", label: "COMA" },
                    { value: "SEDADO", label: "SEDADO" },
                  ]}
                  hidden={page !== 2 || procHomeCare(nomeProc) === "HC"}
                />
                <Select
                  size="5"
                  label="Ventilação Mecânica"
                  name="ventilacao_mecanica"
                  values={[
                    { value: "Y", label: "SIM" },
                    { value: "N", label: "NÃO" },
                  ]}
                  hidden={page !== 2 || procHomeCare(nomeProc) === "HC"}
                />
                <Select
                  size="7"
                  name="hemodinamicamente"
                  label="Hemodinamicamente"
                  values={[
                    { value: "INSTÁVEL", label: "INSTÁVEL" },
                    { value: "ESTÁVEL", label: "ESTÁVEL" },
                  ]}
                  hidden={page !== 2 || procHomeCare(nomeProc) === "HC"}
                />
                {page === 2 &&
                  <IonItemDivider className="ion-margin-top" color="primary">
                    <IonLabel>Exames</IonLabel>
                  </IonItemDivider>
                }
                <IonCol size="12">
                  <IonRow className="ion-align-items-end">
                    <Select
                      size="6"
                      label="BH (ml/24)"
                      name="bh_sinal"
                      values={[
                        { value: "P", label: "POSITIVO" },
                        { value: "N", label: "NEGATIVO" },
                      ]}
                      hidden={page !== 2}
                    />
                    <Input
                      size="6"
                      name="bh"
                      type="number"
                      max="999999.99"
                      step="0.01"
                      hidden={page !== 2}
                    />
                  </IonRow>
                  <IonRow className="ion-align-items-end">
                    <Input
                      size="6"
                      label="Diurese (ml/24)"
                      name="diurese"
                      type="number"
                      max="999999.99"
                      step="0.01"
                      hidden={page !== 2}
                    />
                    <Input
                      size="6"
                      label="Creatinina (mg/dl)"
                      name="creatinina"
                      type="number"
                      max="999999.99"
                      step="0.01"
                      hidden={page !== 2}
                    />
                    <Input
                      size="6"
                      label="Ureia (mg/dl)"
                      name="ureia"
                      type="number"
                      max="999999.99"
                      step="0.01"
                      hidden={page !== 2}
                    />
                    <Input
                      size="6"
                      label="Potássio (mEq/l)"
                      name="potassio"
                      type="number"
                      max="999999.99"
                      step="0.01"
                      hidden={page !== 2}
                    />
                    <Input
                      size="6"
                      label="Fósforo (mg/dl)"
                      name="fosforo"
                      type="number"
                      max="999999.99"
                      step="0.01"
                      hidden={page !== 2}
                    />
                    <Input
                      size="6"
                      label="Cálcio (mg/dl)"
                      name="calcio"
                      type="number"
                      max="999999.99"
                      step="0.01"
                      hidden={page !== 2}
                    />
                    <Input
                      size="6"
                      label="PCR (Unidade)"
                      name="pcr"
                      type="number"
                      max="999999.99"
                      step="0.01"
                      hidden={page !== 2}
                    />
                    <Input
                      size="6"
                      label="Sódio (mEq/l)"
                      name="sodio"
                      type="number"
                      max="999999.99"
                      step="0.01"
                      hidden={page !== 2}
                    />
                    <Input
                      size="6"
                      label="Cloro (mEq/l)"
                      name="cloro"
                      type="number"
                      max="999999.99"
                      step="0.01"
                      hidden={page !== 2}
                    />
                    <Input
                      size="6"
                      label="Ph"
                      name="ph"
                      type="number"
                      max="999999.99"
                      step="0.01"
                      hidden={page !== 2}
                    />
                    <Input
                      size="6"
                      label="Bicarbonato (mEq/l)"
                      name="bicarbonato"
                      type="number"
                      max="999999.99"
                      step="0.01"
                      hidden={page !== 2}
                    />
                    <Input
                      size="6"
                      label="BE (Unidade)"
                      name="be"
                      type="number"
                      max="999999.99"
                      step="0.01"
                      hidden={page !== 2}
                    />
                    <Input
                      size="6"
                      label="Hemoglobina (G/dl)"
                      name="hemoglobina"
                      type="number"
                      max="999999.99"
                      step="0.01"
                      hidden={page !== 2}
                    />
                    <Input
                      size="6"
                      label="HT (%)"
                      name="ht"
                      type="number"
                      max="999999.99"
                      step="0.01"
                      hidden={page !== 2}
                    />
                    <Select
                      size="6"
                      label="Covid"
                      name="covid"
                      values={[
                        { value: "N", label: "NÃO" },
                        { value: "Y", label: "SIM" },
                      ]}
                      required
                      hidden={page !== 2 || procHomeCare(nomeProc) === "HC"}
                    />
                  </IonRow>
                  <IonRow className="ion-align-items-end">
                    <Textarea name="observacao" label="Observação" rows={5} hidden={page !== 2} />
                  </IonRow>
                </IonCol>
              </IonRow>
              <IonRow
                className="ion-align-items-end"
                style={{ marginTop: "15px" }}
              >
                <IonCol size="6">
                  {nomeProc !== "VISITA HOSPITALAR" && nomeProc !== "PARECER" && (
                    <IonButton
                      type="button"
                      color="dark"
                      expand="block"
                      onClick={() => (page === 1 ? nextPage() : previousPage())}
                    >
                      {button.label}
                    </IonButton>
                  )}
                </IonCol>
                <IonCol size="6">
                  {(page === 2 ||
                    nomeProc === "VISITA HOSPITALAR" ||
                    nomeProc === "PARECER") && (
                      <IonButton type="submit" color="primary" expand="block">
                        Salvar
                      </IonButton>
                    )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </Form>
        </IonCardContent>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <IonText>
                  <strong>{nomePaciente}</strong>
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
        <Alert
          show={alertDialiseAgendada}
          setShow={setAlertDialiseAgendada}
          message="<p><strong>Já tem uma diálise para este paciente agendada, deseja continuar?</strong></p>"
          onAccept={createOrUpdate}
        />
        <Alert
          show={alertExcepcional}
          setShow={setAlertExcepcional}
          message="<p><strong>Confirma o lançamento desta prescrição como convenio excepcional?</strong></p> <p>Lembre-se que você deve ter autorização do seu líder operacional para este lançamento.</p>"
          onAccept={handleExcepcional}
        />
      </Card>
      {
        atendimentos.map(atendimento => (
          <Card
            key={atendimento.id}
            className={`card-${atendimento.situacao?.toLowerCase()}`}
          >
            <IonCardHeader>
              <IonCardTitle>DATA: {atendimento.data}</IonCardTitle>
              <IonCardTitle>SITUAÇÃO: {atendimento.situacao}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonRow>
                <IonCol size="12" className="ion-no-padding">
                  <IonLabel>PACIENTE: </IonLabel>
                  <IonText>{atendimento.paciente}</IonText>
                </IonCol>
                <IonCol size="12" className="ion-no-padding">
                  <IonLabel>PROCEDIMENTO: </IonLabel>
                  <IonText>{atendimento.proc}</IonText>
                </IonCol>
                <IonCol size="12" className="ion-no-padding">
                  <IonLabel>TEMPO: </IonLabel>
                  <IonText>{atendimento.tempo}</IonText>
                </IonCol>
                {procHomeCare(atendimento.proc) === "H" && (
                  <>
                    <IonCol size="12" className="ion-no-padding">
                      <IonLabel>PRECAUÇÃO: </IonLabel>
                      <IonText>{atendimento.precaucao}</IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                      <IonLabel>HOSPITAL: </IonLabel>
                      <IonText>{atendimento.hospital}</IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                      <IonLabel>LOCALIZAÇÃO: </IonLabel>
                      <IonText>{atendimento.localizacao}</IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                      <IonLabel>Leito: </IonLabel>
                      <IonText>{atendimento.leito}</IonText>
                    </IonCol>
                  </>
                )}
                <IonCol size="12" className="ion-no-padding">
                  <IonLabel>ACESSO: </IonLabel>
                  <IonText>{atendimento.acesso}</IonText>
                </IonCol>
                {atendimento.acesso !== "TENCKHOFF" && (
                  <IonCol size="12" className="ion-no-padding">
                    <IonLabel>SITIO: </IonLabel>
                    <IonText>{atendimento.sitio}</IonText>
                  </IonCol>
                )}
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol size="12">
                  <IonButton
                    type="button"
                    color="primary"
                    expand="block"
                    onClick={() => setId(atendimento.id)}
                  >
                    Editar
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCardContent>
          </Card>
        ))
      }
    </Main >
  )
}

export default MedicoPrescricao;
