import React, { useEffect, useRef, useState } from "react"
import { useField } from "@unform/core"
import { IonCol, IonButton } from "@ionic/react"

import Label from "../Label"

interface Props {
    size?: string;
    label?: string;
    name: string;
    accept?: string;
    required?: boolean;
    disabled?: boolean;
}

const FilePicker = ({
    size,
    label,
    name,
    accept,
    required,
    disabled,
}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [fileName, setFileName] = useState("");
    const { fieldName, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: ref => {
                return ref.current.files[0]
            },
            clearValue: ref => {
                ref.current.value = ""
                setFileName("")
            }
        })
    }, [fieldName, registerField])

    const selectFile = () => {
        inputRef.current?.click()
    }

    const onFileChange = () => {
        if (!!inputRef.current?.files) {
            setFileName(inputRef.current?.files[0]?.name)
        }
    }

    return (
        <IonCol size={size || "12"} className="ion-margin-top ion-margin-bottom">
            <Label>
                {label} {required ? "(*)" : ""}: {fileName ? fileName : "Nenhum arquivo selecionado"}
            </Label>
            <br />
            <input
                ref={inputRef}
                onChange={onFileChange}
                type="file"
                multiple={false}
                accept={accept}
                hidden={true}
            />
            <IonButton strong disabled={disabled} onClick={selectFile}>
                Selecionar Arquivo
            </IonButton>
        </IonCol>
    )
}

export default FilePicker
