import { IonCard } from "./styles";

interface Props {
    className?: string;
    children: React.ReactNode;
}

const Card = ({ className, children }: Props) => {
    return (
        <IonCard
            className={!!className ? className : ""}
        >
            {children}
        </IonCard>
    );
};

export default Card;
