import { IonLabel } from "@ionic/react";

interface Props {
    color?: string
    required?: boolean
    className?: string
    children: React.ReactNode
}

const Label = ({
    color,
    required,
    className,
    children
}: Props) => {
    return (
        <IonLabel
            position="stacked"
            className={className}
            style={{
                color: `${color ? color : '#000'}`,
                fontWeight: 600,
                fontSize: '16px',
                overflow: 'visible'
            }}
        >
            {children} <small>{required ? '(*)' : ''}</small>
        </IonLabel>
    )
}

export default Label
