import styled from "styled-components";
import { IonInput as Input } from "@ionic/react";

interface Props {
  disabled?: boolean
}

export const IonInput = styled(Input)`
  background-color:  ${(props: Props) => props.disabled ? "hsl(0, 0%, 95%)" : "#fff"};
  background-image: none;
  border: 2px solid #b0b0b0;
  border-radius: 4px;
  margin-top: 5px;
  height: 42px;
  padding: 0px 6px !important;
  color: #000;
  opacity: 1;
`
