import { IonSelectOption } from '@ionic/react'

interface ProceduresType {
  value: string;
  label: string;
}

export const getProcedures = (unit_id: number) => {
  let procedures: ProceduresType[] = []

  if ([1, 2, 5, 7, 8, 12, 13, 14, 15, 16, 17, 19, 20].includes(unit_id)) {
    procedures = [
      { value: 'BURETA', label: 'BURETA' },
      { value: 'CVVHD', label: 'CVVHD' },
      { value: 'CVVHDF', label: 'CVVHDF' },
      { value: 'DPA', label: 'DPA' },
      { value: 'DPAC', label: 'DPAC' },
      { value: 'DPAP (PEDIATRIA)', label: 'DPAP (PEDIATRIA)' },
      { value: 'DPI', label: 'DPI' },
      { value: 'HDC', label: 'HDC' },
      { value: 'HDCP (PEDIATRIA)', label: 'HDCP (PEDIATRIA)' },
      { value: 'HDI', label: 'HDI' },
      { value: 'HDIP (PEDIATRIA)', label: 'HDIP (PEDIATRIA)' },
      { value: 'HDP', label: 'HDP' },
      { value: 'HDPP (PEDIATRIA)', label: 'HDPP (PEDIATRIA)' },
      {
        value: 'PACOTE DIÁLISE PERITONIAL (1a SESSÃO)',
        label: 'PACOTE D. PERITONIAL (1a SESSÃO)'
      },
      {
        value: 'PACOTE DIÁLISE PERITONIAL (SESSÃO SUBSEQUENTE)',
        label: 'PACOTE D. PERITONIAL (SESSÃO SUBSEQUENTE)'
      },
      {
        value: 'PACOTE HEMODIÁLISE CONTÍNUA (1a SESSÃO)',
        label: 'PACOTE H. CONTÍNUA (1a SESSÃO)'
      },
      {
        value: 'PACOTE HEMODIÁLISE CONTÍNUA (SESSÃO SUBSEQUENTE)',
        label: 'PACOTE H. CONTÍNUA (SESSÃO SUBSEQUENTE)'
      },
      {
        value: 'PACOTE HEMODIÁLISE CONTÍNUA 24 HORAS',
        label: 'PACOTE H. CONTÍNUA 24 HORAS'
      },
      {
        value: 'PACOTE HEMODIÁLISE CONTÍNUA ATE 13 HORAS',
        label: 'PACOTE H. CONTÍNUA ATE 13 HORAS'
      },
      {
        value: 'PACOTE HEMODIÁLISE CONTÍNUA POR HORA',
        label: 'PACOTE H. CONTÍNUA POR HORA'
      },
      {
        value: 'PACOTE HEMODIÁLISE CONVENCIONAL (1a SESSÃO)',
        label: 'PACOTE H. CONVENCIONAL (1a SESSÃO)'
      },
      {
        value: 'PACOTE HEMODIÁLISE CONVENCIONAL (SESSÃO SUBSEQUENTE)',
        label: 'PACOTE H. CONVENCIONAL (SESSÃO SUBSEQUENTE)'
      },
      { value: 'PACOTE HEMODIÁLISE PEDIATRICO', label: 'PACOTE H. PEDIATRICO' },
      { value: 'PARECER', label: 'PARECER' },
      { value: 'PLASM', label: 'PLASM' },
      { value: 'VISITA HOSPITALAR', label: 'VISITA HOSPITALAR' }
    ]
  } else if ([3].includes(unit_id)) {
    procedures = [
      { value: 'BURETA', label: 'BURETA' },
      { value: 'CVVHD', label: 'CVVHD' },
      { value: 'CVVHDF', label: 'CVVHDF' },
      { value: 'DPA', label: 'DPA' },
      { value: 'DPAC', label: 'DPAC' },
      { value: 'DPAP (PEDIATRIA)', label: 'DPAP (PEDIATRIA)' },
      { value: 'DPI', label: 'DPI' },
      {
        value: 'PACOTE DIÁLISE PERITONIAL (1a SESSÃO)',
        label: 'PACOTE D. PERITONIAL (1a SESSÃO)'
      },
      {
        value: 'PACOTE DIÁLISE PERITONIAL (SESSÃO SUBSEQUENTE)',
        label: 'PACOTE D. PERITONIAL (SESSÃO SUBSEQUENTE)'
      },
      {
        value: 'PACOTE HEMODIÁLISE CONTÍNUA (1a SESSÃO)',
        label: 'PACOTE H. CONTÍNUA (1a SESSÃO)'
      },
      {
        value: 'PACOTE HEMODIÁLISE CONTÍNUA (SESSÃO SUBSEQUENTE)',
        label: 'PACOTE H. CONTÍNUA (SESSÃO SUBSEQUENTE)'
      },
      {
        value: 'PACOTE HEMODIÁLISE CONTÍNUA 24 HORAS',
        label: 'PACOTE H. CONTÍNUA 24 HORAS'
      },
      {
        value: 'PACOTE HEMODIÁLISE CONTÍNUA ATE 13 HORAS',
        label: 'PACOTE H. CONTÍNUA ATE 13 HORAS'
      },
      {
        value: 'PACOTE HEMODIÁLISE CONTÍNUA POR HORA',
        label: 'PACOTE H. CONTÍNUA POR HORA'
      },
      {
        value: 'PACOTE HEMODIÁLISE CONVENCIONAL (1a SESSÃO)',
        label: 'PACOTE H. CONVENCIONAL (1a SESSÃO)'
      },
      {
        value: 'PACOTE HEMODIÁLISE CONVENCIONAL (SESSÃO SUBSEQUENTE)',
        label: 'PACOTE H. CONVENCIONAL (SESSÃO SUBSEQUENTE)'
      },
      { value: 'PACOTE HEMODIÁLISE PEDIATRICO', label: 'PACOTE H. PEDIATRICO' },
      { value: 'PARECER', label: 'PARECER' },
      { value: 'PLASM', label: 'PLASM' },
      { value: 'VISITA HOSPITALAR', label: 'VISITA HOSPITALAR' }
    ]
  } else if ([4, 6, 18].includes(unit_id)) {
    procedures = [
      { value: 'BURETA', label: 'BURETA' },
      { value: 'CVVHD', label: 'CVVHD' },
      { value: 'CVVHDF', label: 'CVVHDF' },
      { value: 'DPA', label: 'DPA' },
      { value: 'DPAC', label: 'DPAC' },
      { value: 'DPAP (PEDIATRIA)', label: 'DPAP (PEDIATRIA)' },
      { value: 'DPI', label: 'DPI' },
      { value: 'HDC', label: 'HDC' },
      { value: 'HDCP (PEDIATRIA)', label: 'HDCP (PEDIATRIA)' },
      { value: 'HDI', label: 'HDI' },
      { value: 'HDIP (PEDIATRIA)', label: 'HDIP (PEDIATRIA)' },
      { value: 'HDP', label: 'HDP' },
      { value: 'HDPP (PEDIATRIA)', label: 'HDPP (PEDIATRIA)' },
      { value: 'PARECER', label: 'PARECER' },
      { value: 'VISITA HOSPITALAR', label: 'VISITA HOSPITALAR' }
    ]
  } else if ([9].includes(unit_id)) {
    procedures = [
      {
        value: 'DIÁLISE PERITONIAL DOMICILIAR',
        label: 'DIÁLISE PERITONIAL DOMICILIAR'
      },
      { value: 'HEMODIÁLISE DOMICILIAR', label: 'HEMODIÁLISE DOMICILIAR' }
    ]
  } else if ([10, 11].includes(unit_id)) {
    procedures = [
      { value: 'BURETA', label: 'BURETA' },
      { value: 'CVVHD', label: 'CVVHD' },
      { value: 'CVVHD FLEX', label: 'CVVHD FLEX' },
      { value: 'CVVHD MF', label: 'CVVHD MF' },
      { value: 'CVVHDF', label: 'CVVHDF' },
      { value: 'DPA', label: 'DPA' },
      { value: 'DPAC', label: 'DPAC' },
      { value: 'DPAP (PEDIATRIA)', label: 'DPAP (PEDIATRIA)' },
      { value: 'DPI', label: 'DPI' },
      { value: 'HDCP (PEDIATRIA)', label: 'HDCP (PEDIATRIA)' },
      { value: 'HDF', label: 'HDF' },
      { value: 'HDI', label: 'HDI' },
      { value: 'HDIP (PEDIATRIA)', label: 'HDIP (PEDIATRIA)' },
      { value: 'HDP', label: 'HDP' },
      { value: 'HDPP (PEDIATRIA)', label: 'HDPP (PEDIATRIA)' },
      { value: 'PARECER', label: 'PARECER' },
      { value: 'PLASM', label: 'PLASM' },
      { value: 'VISITA HOSPITALAR', label: 'VISITA HOSPITALAR' }
    ]
  }

  return procedures.map(procedure => (
    <IonSelectOption key={procedure.value} value={procedure.value}>
      {procedure.label}
    </IonSelectOption>
  ))
}


export const getType = (proc: string) => {
  if (
    [
      'CVVHD',
      'CVVHD FLEX',
      'CVVHD MF',
      'CVVHDF',
      'PACOTE HEMODIÁLISE CONTÍNUA (1a SESSÃO)',
      'PACOTE HEMODIÁLISE CONTÍNUA (SESSÃO SUBSEQUENTE)',
      'PACOTE HEMODIÁLISE CONTÍNUA 24 HORAS',
      'PACOTE HEMODIÁLISE CONTÍNUA ATE 13 HORAS',
      'PACOTE HEMODIÁLISE CONTÍNUA POR HORA'
    ].includes(proc)
  ) {
    return 'C'
  } else if (
    [
      'DPA',
      'DPAC',
      'DPAP (PEDIATRIA)',
      'DPI',
      'DIÁLISE PERITONIAL DOMICILIAR'
    ].includes(proc)
  ) {
    return 'B'
  } else {
    return 'A'
  }
}
