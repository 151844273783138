import { useEffect, useState } from "react";
import {
    IonAlert,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonRow,
    IonSelectOption,
    IonText,
    useIonViewDidEnter
} from "@ionic/react";
import { refresh } from "ionicons/icons";
import { format, add, differenceInSeconds } from "date-fns";

import { useAuth } from "../../../hooks/auth";
import { useNotifications } from "../../../hooks/notifications";
import { useLoading } from "../../../hooks/loading";
import { useToast } from "../../../hooks/toast";
import { useValidation } from "../../../hooks/validation";
import api from "../../../services/api";

import Main from "../../../components/Main";
import { IonInput } from "../../../components/Input/styles";
import { IonSelect } from "../../../components/Select/styles";


import SearchTechnician from "../../../legacy/components/Technician"
import { interrupted_dialysis } from "../../../legacy/shared/InterruptedDialysis";
import { getType } from "../../../legacy/shared/Procedures";


interface AtendimentoEmAndamento {
    id: number
    situation: string
    patient_name: string
    hospital_name: string
    location: string
    bed: string
    time: string
    date: string
    estimated_start: string
    start: string
    start_date: string
    end: string
    doctor_name: string
    machine: number
    reverse_osmosis: number
    home_choice: number
    technician1_id: number
    technician2_id: number
    technician3_id: number
    technician4_id: number
    technician5_id: number
    technician6_id: number
    technician7_id: number
    technician8_id: number
    technician1_name: string
    technician2_name: string
    technician3_name: string
    technician4_name: string
    technician5_name: string
    technician6_name: string
    technician7_name: string
    technician8_name: string
    tec1start: string
    tec2start: string
    tec3start: string
    tec4start: string
    tec5start: string
    tec6start: string
    tec7start: string
    tec8start: string
    tec1end: string
    tec2end: string
    tec3end: string
    tec4end: string
    tec5end: string
    tec6end: string
    tec7end: string
    tec8end: string
    proc: string
    tec1open: 0 | 1
    tec2open: 0 | 1
    tec3open: 0 | 1
    tec4open: 0 | 1
    tec5open: 0 | 1
    tec6open: 0 | 1
    tec7open: 0 | 1
    tec8open: 0 | 1
    tec1close: 0 | 1
    tec2close: 0 | 1
    tec3close: 0 | 1
    tec4close: 0 | 1
    tec5close: 0 | 1
    tec6close: 0 | 1
    tec7close: 0 | 1
    tec8close: 0 | 1
    intercurrence: 0 | 1
    interrupted?: string
    note?: string
}

interface AtendimentoProps {
    row: AtendimentoEmAndamento;
    getList(): void;
}

const Atendimento = ({ row, getList }: AtendimentoProps) => {
    const { usuario } = useAuth()
    const { addToast, successToast } = useToast()
    const { getValidationErrors } = useValidation()
    const { getContadorAtendimentos } = useNotifications()

    const [timeOut, setTimeOut] = useState("00:00:00")
    const [finalized, setFinalized] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [intercurrence, setIntercurrence] = useState<0 | 1>(
        row.intercurrence === 1 ? 1 : 0
    )

    const [wasInterrupted, setWasInterrupted] = useState<"Y" | "N">("N")
    const [interrupted, setInterrupted] = useState<string | null>(null)

    const [tec1end, setTec1End] = useState(row.tec1end || "00:00:00")
    const [tec2end, setTec2End] = useState(row.tec2end || "00:00:00")
    const [tec3end, setTec3End] = useState(row.tec3end || "00:00:00")
    const [tec4end, setTec4End] = useState(row.tec4end || "00:00:00")
    const [tec5end, setTec5End] = useState(row.tec5end || "00:00:00")
    const [tec6end, setTec6End] = useState(row.tec6end || "00:00:00")
    const [tec7end, setTec7End] = useState(row.tec7end || "00:00:00")
    const [tec8end, setTec8End] = useState(row.tec8end || "00:00:00")

    const [technician2, setTechnician2] = useState<any>(
        !!row.technician2_id && !!row.technician2_name
            ? { value: String(row.technician2_id), label: row.technician2_name }
            : { value: 0, label: "" }
    )
    const [technician3, setTechnician3] = useState<any>(
        !!row.technician3_id && !!row.technician3_name
            ? { value: String(row.technician3_id), label: row.technician3_name }
            : { value: 0, label: "" }
    )
    const [technician4, setTechnician4] = useState<any>(
        !!row.technician4_id && !!row.technician4_name
            ? { value: String(row.technician4_id), label: row.technician4_name }
            : { value: 0, label: "" }
    )
    const [technician5, setTechnician5] = useState<any>(
        !!row.technician5_id && !!row.technician5_name
            ? { value: String(row.technician5_id), label: row.technician5_name }
            : { value: 0, label: "" }
    )
    const [technician6, setTechnician6] = useState<any>(
        !!row.technician6_id && !!row.technician6_name
            ? { value: String(row.technician6_id), label: row.technician6_name }
            : { value: 0, label: "" }
    )
    const [technician7, setTechnician7] = useState<any>(
        !!row.technician7_id && !!row.technician7_name
            ? { value: String(row.technician7_id), label: row.technician7_name }
            : { value: 0, label: "" }
    )
    const [technician8, setTechnician8] = useState<any>(
        !!row.technician8_id && !!row.technician8_name
            ? { value: String(row.technician8_id), label: row.technician8_name }
            : { value: 0, label: "" }
    )

    useEffect(() => {
        setFinalized(false)
    }, [row])

    useEffect(() => {
        const updateTimeOut = () => {
            const start = row.start
            let date = row.start_date
            if (!date) date = row.date

            date = `${date}T${start}`

            let time = row.time
            let hours = 0
            let minutes = 0
            if (time.split(":").length > 0) {
                const timeArray = time.split(":")
                hours = parseInt(timeArray[0])
                minutes = parseInt(timeArray[1])
            } else {
                hours = parseInt(time)
            }

            const endDateTime = add(new Date(`${row.date}T${row.start}`), {
                hours,
                minutes
            })

            const nowDateTime = new Date()

            const timeDiff = differenceInSeconds(endDateTime, nowDateTime)

            const pad = (num: number, size: number) => {
                return ("000" + num).slice(size * -1)
            }

            if (timeDiff > 0) {
                const hours = Math.floor(timeDiff / 60 / 60)
                const minutes = Math.floor(timeDiff / 60) % 60
                const seconds = Math.floor(timeDiff - minutes * 60)

                setTimeOut(`${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`)
            } else {
                setTimeOut("00:00:00")
            }
        }
        updateTimeOut()
        const interval = setInterval(updateTimeOut, 5000)

        return () => {
            clearInterval(interval)
        }
    }, [row.date, row.start, row.start_date, row.time])

    const handleSubmit = async () => {
        if (finalized) {
            if (confirm) {
                let data = {} as AtendimentoEmAndamento
                let valid = true

                data.intercurrence = intercurrence

                if (
                    row.technician1_id === usuario.id_usuario &&
                    row.tec1open === 1 &&
                    row.tec1close === 0
                ) {
                    data.technician1_id = row.technician1_id
                    data.tec1end = tec1end
                    if (!!technician2.value) {
                        data.technician2_id = technician2.value
                    }
                } else if (
                    row.technician2_id === usuario.id_usuario &&
                    row.tec2open === 1 &&
                    row.tec2close === 0
                ) {
                    data.technician2_id = row.technician2_id
                    data.tec2end = tec2end
                    if (!!technician3.value) {
                        data.technician3_id = technician3.value
                    }
                } else if (
                    row.technician3_id === usuario.id_usuario &&
                    row.tec3open === 1 &&
                    row.tec3close === 0
                ) {
                    data.technician3_id = row.technician3_id
                    data.tec3end = tec3end
                    if (!!technician4.value) {
                        data.technician4_id = technician4.value
                    }
                } else if (
                    row.technician4_id === usuario.id_usuario &&
                    row.tec4open === 1 &&
                    row.tec4close === 0
                ) {
                    data.technician4_id = row.technician4_id
                    data.tec4end = tec4end
                    if (!!technician5.value) {
                        data.technician5_id = technician5.value
                    }
                } else if (
                    row.technician5_id === usuario.id_usuario &&
                    row.tec5open === 1 &&
                    row.tec5close === 0
                ) {
                    data.technician5_id = row.technician5_id
                    data.tec5end = tec5end
                    if (!!technician6.value) {
                        data.technician6_id = technician6.value
                    }
                } else if (
                    row.technician6_id === usuario.id_usuario &&
                    row.tec6open === 1 &&
                    row.tec6close === 0
                ) {
                    data.technician6_id = row.technician6_id
                    data.tec6end = tec6end
                    if (!!technician7.value) {
                        data.technician7_id = technician7.value
                    }
                } else if (
                    row.technician7_id === usuario.id_usuario &&
                    row.tec7open === 1 &&
                    row.tec7close === 0
                ) {
                    data.technician7_id = row.technician7_id
                    data.tec7end = tec7end
                    if (!!technician8.value) {
                        data.technician8_id = technician8.value
                    }
                } else if (
                    row.technician8_id === usuario.id_usuario &&
                    row.tec8open === 1 &&
                    row.tec8close === 0
                ) {
                    data.technician8_id = row.technician8_id
                    data.tec8end = tec8end
                }

                if (wasInterrupted === "Y") {
                    if (!interrupted) {
                        addToast({
                            message: "O campo motivo é obrigatório",
                            color: "warning"
                        })
                        valid = false
                    } else {
                        data.interrupted = interrupted
                    }
                }

                if (valid) {
                    await api
                        .put(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/em-andamento/${row.id}`, data)
                        .then(_response => {
                            successToast()
                            getList()
                            getContadorAtendimentos()
                        })
                        .catch(error => {
                            getValidationErrors(error.response)
                        })
                }
            } else {
                setConfirm(true)
            }
        } else {
            setFinalized(true)
        }
    }

    return (
        <IonCard className="card-andamento">
            <IonCardHeader>
                <IonCardTitle>
                    TEMPO RESTANTE: <strong>{timeOut}</strong>
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonRow className="ion-align-items-end">
                    <IonCol size="12" className="ion-no-padding">
                        <IonLabel>FOLHA: </IonLabel>
                        <IonText>{row.id}</IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                        <IonLabel>PACIENTE: </IonLabel>
                        <IonText>{row.patient_name}</IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                        <IonLabel>HOSPITAL: </IonLabel>
                        <IonText>{row.hospital_name}</IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                        <IonLabel>LOCALIZAÇÃO: </IonLabel>
                        <IonText>{row.location}</IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                        <IonLabel>LEITO: </IonLabel>
                        <IonText>{row.bed}</IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                        <IonLabel>DATA PRESCRITA: </IonLabel>
                        <IonText>
                            {!!row.date &&
                                format(new Date(row.date + "T00:00:00"), "dd/MM/yyyy")}
                        </IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                        <IonLabel>HORÁRIO ESTIMADO: </IonLabel>
                        <IonText>
                            <IonText>{row.estimated_start}</IonText>
                        </IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                        <IonLabel>TEMPO PRESCRITO: </IonLabel>
                        <IonText>{row.time}</IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                        <IonLabel>DATA DE INICIO: </IonLabel>
                        <IonText>
                            {!!row.start_date &&
                                format(new Date(row.start_date + "T00:00:00"), "dd/MM/yyyy")}
                        </IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                        <IonLabel>HORA DE INÍCIO: </IonLabel>
                        <IonText>
                            {!!row.start && row.start.substr(0, 5) + " "}
                            <strong>
                                {row.technician2_id === usuario.id_usuario &&
                                    !!row.tec2start &&
                                    !row.tec2close
                                    ? `(${row.tec2start.substr(0, 5)})`
                                    : row.technician3_id === usuario.id_usuario &&
                                        !!row.tec3start &&
                                        !row.tec3close
                                        ? `(${row.tec3start.substr(0, 5)})`
                                        : row.technician4_id === usuario.id_usuario &&
                                            !!row.tec4start &&
                                            !row.tec4close
                                            ? `(${row.tec4start.substr(0, 5)})`
                                            : row.technician5_id === usuario.id_usuario &&
                                                !!row.tec5start &&
                                                !row.tec5close
                                                ? `(${row.tec5start.substr(0, 5)})`
                                                : row.technician6_id === usuario.id_usuario &&
                                                    !!row.tec6start &&
                                                    !row.tec6close
                                                    ? `(${row.tec6start.substr(0, 5)})`
                                                    : row.technician7_id === usuario.id_usuario &&
                                                        !!row.tec7start &&
                                                        !row.tec7close
                                                        ? `(${row.tec7start.substr(0, 5)})`
                                                        : row.technician8_id === usuario.id_usuario &&
                                                            !!row.tec8start &&
                                                            !row.tec8close
                                                            ? `(${row.tec8start.substr(0, 5)})`
                                                            : ""}
                            </strong>
                        </IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                        <IonLabel>HORA DE TÉRMINO: </IonLabel>
                        <IonText>
                            {!!row.end && row.end.substr(0, 5) + " "}
                            <strong>
                                {row.technician2_id === usuario.id_usuario &&
                                    !!row.tec2end &&
                                    !row.tec2close
                                    ? `(${row.tec2end.substr(0, 5)})`
                                    : row.technician3_id === usuario.id_usuario &&
                                        !!row.tec3end &&
                                        !row.tec3close
                                        ? `(${row.tec3end.substr(0, 5)})`
                                        : row.technician4_id === usuario.id_usuario &&
                                            !!row.tec4end &&
                                            !row.tec4close
                                            ? `(${row.tec4end.substr(0, 5)})`
                                            : row.technician5_id === usuario.id_usuario &&
                                                !!row.tec5end &&
                                                !row.tec5close
                                                ? `(${row.tec5end.substr(0, 5)})`
                                                : row.technician6_id === usuario.id_usuario &&
                                                    !!row.tec6end &&
                                                    !row.tec6close
                                                    ? `(${row.tec6end.substr(0, 5)})`
                                                    : row.technician7_id === usuario.id_usuario &&
                                                        !!row.tec7end &&
                                                        !row.tec7close
                                                        ? `(${row.tec7end.substr(0, 5)})`
                                                        : row.technician8_id === usuario.id_usuario &&
                                                            !!row.tec8end &&
                                                            !row.tec8close
                                                            ? `(${row.tec8end.substr(0, 5)})`
                                                            : ""}
                            </strong>
                        </IonText>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                        <IonLabel>MÉDICO: </IonLabel>
                        <IonText>{row.doctor_name}</IonText>
                    </IonCol>
                    {getType(row.proc) === "B" ? (
                        <IonCol size="12" className="ion-no-padding">
                            <IonLabel>HOME CHOICE: </IonLabel>
                            <IonText>{row.home_choice}</IonText>
                        </IonCol>
                    ) : (
                        <>
                            <IonCol size="12" className="ion-no-padding">
                                <IonLabel>MÁQUINA: </IonLabel>
                                <IonText>{row.machine}</IonText>
                            </IonCol>
                            <IonCol size="12" className="ion-no-padding">
                                <IonLabel>OSMOSE: </IonLabel>
                                <IonText>{row.reverse_osmosis}</IonText>
                            </IonCol>
                        </>
                    )}
                </IonRow>
                {!!row.note && (
                    <IonRow>
                        <IonCol size="12" className="ion-no-padding">
                            <IonLabel>OBSERVAÇÃO: </IonLabel>
                            <IonText>{row.note}</IonText>
                        </IonCol>
                    </IonRow>
                )}

                <IonRow className="ion-align-items-end ion-margin-top">
                    <IonCol size="6">
                        <IonButton
                            type="button"
                            routerLink={`/prescricao?id_atendimento=${row.id}`}
                            color="primary"
                            expand="block"
                        >
                            Prescrição
                        </IonButton>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-align-items-end ion-margin-top">
                    <IonCol size="6">
                        <IonButton
                            type="button"
                            routerLink={`/tecnico/anotacao/${row.id}/${row.patient_name}/${row.proc}`}
                            color="secondary"
                            expand="block"
                        >
                            Anotações
                        </IonButton>
                    </IonCol>
                    <IonCol size="6">
                        <IonButton
                            type="button"
                            routerLink={`/tecnico/evolucao/${row.id}/${row.patient_name}`}
                            color="medium"
                            expand="block"
                        >
                            Evolução
                        </IonButton>
                    </IonCol>
                    <IonCol size="6">
                        <IonButton
                            type="button"
                            routerLink={`/tecnico/estoque/${row.id}/${row.patient_name}`}
                            color="warning"
                            expand="block"
                        >
                            Estoque
                        </IonButton>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-align-items-end ion-margin-top">
                    {finalized && (
                        <>
                            <IonCol size="6">
                                <IonLabel position="stacked">Hora de Término:</IonLabel>
                                {row.technician1_id === usuario.id_usuario &&
                                    row.tec1open === 1 &&
                                    row.tec1close === 0 ? (
                                    <IonInput
                                        value={tec1end}
                                        type="time"
                                        onIonChange={event => setTec1End(event.detail.value || "")}
                                    />
                                ) : row.technician2_id === usuario.id_usuario &&
                                    row.tec2open === 1 &&
                                    row.tec2close === 0 ? (
                                    <IonInput
                                        value={tec2end}
                                        type="time"
                                        onIonChange={event => setTec2End(event.detail.value || "")}
                                    />
                                ) : row.technician3_id === usuario.id_usuario &&
                                    row.tec3open === 1 &&
                                    row.tec3close === 0 ? (
                                    <IonInput
                                        value={tec3end}
                                        type="time"
                                        onIonChange={event => setTec3End(event.detail.value || "")}
                                    />
                                ) : row.technician4_id === usuario.id_usuario &&
                                    row.tec4open === 1 &&
                                    row.tec4close === 0 ? (
                                    <IonInput
                                        value={tec4end}
                                        type="time"
                                        onIonChange={event => setTec4End(event.detail.value || "")}
                                    />
                                ) : row.technician5_id === usuario.id_usuario &&
                                    row.tec5open === 1 &&
                                    row.tec5close === 0 ? (
                                    <IonInput
                                        value={tec5end}
                                        type="time"
                                        onIonChange={event => setTec5End(event.detail.value || "")}
                                    />
                                ) : row.technician6_id === usuario.id_usuario &&
                                    row.tec6open === 1 &&
                                    row.tec6close === 0 ? (
                                    <IonInput
                                        value={tec6end}
                                        type="time"
                                        onIonChange={event => setTec6End(event.detail.value || "")}
                                    />
                                ) : row.technician7_id === usuario.id_usuario &&
                                    row.tec7open === 1 &&
                                    row.tec7close === 0 ? (
                                    <IonInput
                                        value={tec7end}
                                        type="time"
                                        onIonChange={event => setTec7End(event.detail.value || "")}
                                    />
                                ) : row.technician8_id === usuario.id_usuario &&
                                    row.tec8open === 1 &&
                                    row.tec8close === 0 ? (
                                    <IonInput
                                        value={tec8end}
                                        type="time"
                                        onIonChange={event => setTec8End(event.detail.value || "")}
                                    />
                                ) : (
                                    <></>
                                )}
                            </IonCol>
                            <IonCol size="6">
                                <IonLabel position="stacked">Houve Intercorrência?</IonLabel>
                                <IonSelect
                                    value={intercurrence}
                                    onIonChange={event =>
                                        setIntercurrence(event.detail.value || 0)
                                    }
                                    cancelText="Cancelar"
                                    okText="Ok"
                                    interface="action-sheet"
                                >
                                    <IonSelectOption value={0}>Não</IonSelectOption>
                                    <IonSelectOption value={1}>Sim</IonSelectOption>
                                </IonSelect>
                            </IonCol>
                            <IonCol size="6">
                                <IonLabel position="stacked">Diálise Interrompida?</IonLabel>
                                <IonSelect
                                    value={wasInterrupted}
                                    onIonChange={event =>
                                        setWasInterrupted(event.detail.value || "N")
                                    }
                                    cancelText="Cancelar"
                                    okText="Ok"
                                    interface="action-sheet"
                                >
                                    <IonSelectOption value="N">Não</IonSelectOption>
                                    <IonSelectOption value="Y">Sim</IonSelectOption>
                                </IonSelect>
                            </IonCol>
                            {wasInterrupted === "Y" && (
                                <IonCol size="6">
                                    <IonLabel position="stacked">Motivo:</IonLabel>
                                    <IonSelect
                                        value={interrupted}
                                        onIonChange={event =>
                                            setInterrupted(event.detail.value || null)
                                        }
                                        cancelText="Cancelar"
                                        okText="Ok"
                                        interface="action-sheet"
                                    >
                                        {interrupted_dialysis.map((values, index) => (
                                            <IonSelectOption key={index} value={values.value}>
                                                {values.label}
                                            </IonSelectOption>
                                        ))}
                                    </IonSelect>
                                </IonCol>
                            )}
                            <IonCol size="12" className="ion-no-padding">
                                {row.technician1_id === usuario.id_usuario &&
                                    row.tec1open === 1 &&
                                    row.tec1close === 0 ? (
                                    <SearchTechnician
                                        size="12"
                                        label="Próximo Técnico"
                                        value={technician2}
                                        onChange={value => setTechnician2(value)}
                                    />
                                ) : row.technician2_id === usuario.id_usuario &&
                                    row.tec2open === 1 &&
                                    row.tec2close === 0 ? (
                                    <SearchTechnician
                                        size="12"
                                        label="Próximo Técnico"
                                        value={technician3}
                                        onChange={value => setTechnician3(value)}
                                    />
                                ) : row.technician3_id === usuario.id_usuario &&
                                    row.tec3open === 1 &&
                                    row.tec3close === 0 ? (
                                    <SearchTechnician
                                        size="12"
                                        label="Próximo Técnico"
                                        value={technician4}
                                        onChange={value => setTechnician4(value)}
                                    />
                                ) : row.technician4_id === usuario.id_usuario &&
                                    row.tec4open === 1 &&
                                    row.tec4close === 0 ? (
                                    <SearchTechnician
                                        size="12"
                                        label="Próximo Técnico"
                                        value={technician5}
                                        onChange={value => setTechnician5(value)}
                                    />
                                ) : row.technician5_id === usuario.id_usuario &&
                                    row.tec5open === 1 &&
                                    row.tec5close === 0 ? (
                                    <SearchTechnician
                                        size="12"
                                        label="Próximo Técnico"
                                        value={technician6}
                                        onChange={value => setTechnician6(value)}
                                    />
                                ) : row.technician6_id === usuario.id_usuario &&
                                    row.tec6open === 1 &&
                                    row.tec6close === 0 ? (
                                    <SearchTechnician
                                        size="12"
                                        label="Próximo Técnico"
                                        value={technician7}
                                        onChange={value => setTechnician7(value)}
                                    />
                                ) : row.technician7_id === usuario.id_usuario &&
                                    row.tec7open === 1 &&
                                    row.tec7close === 0 ? (
                                    <SearchTechnician
                                        size="12"
                                        label="Próximo Técnico"
                                        value={technician8}
                                        onChange={value => setTechnician8(value)}
                                    />
                                ) : (
                                    <></>
                                )}
                            </IonCol>
                        </>
                    )}
                    <IonCol size="12" className="ion-no-padding">
                        <IonButton
                            type="button"
                            color="danger"
                            expand="block"
                            onClick={() => handleSubmit()}
                        >
                            Finalizar
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonCardContent>
            <IonAlert
                isOpen={confirm}
                onDidDismiss={() => setConfirm(false)}
                message={`Deseja realmente finalizar a sessão do paciente ${row.patient_name}`}
                buttons={[
                    {
                        text: "Sim",
                        handler: () => handleSubmit()
                    },
                    {
                        text: "Não",
                        handler: () => setConfirm(false)
                    }
                ]}
            />
        </IonCard>
    )
}

const TecnicoEmAndamento = () => {
    const { usuario } = useAuth();
    const { showLoading, hideLoading } = useLoading();
    const { getContadorAtendimentos } = useNotifications();
    const { getValidationErrors } = useValidation();

    const [rows, setRows] = useState<AtendimentoEmAndamento[]>([]);

    const getList = async () => {
        showLoading()
        await api
            .get(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/em-andamento`)
            .then(response => {
                setRows(response.data)
            })
            .catch(error => {
                getValidationErrors(error.response)
            })

        hideLoading()
        getContadorAtendimentos()
    }

    useIonViewDidEnter(() => {
        getList()
    })

    const RefreshIcon = () => (
        <IonItem lines="none">
            <IonIcon
                icon={refresh}
                size="large"
                color="light"
                onClick={() => getList()}
            />
        </IonItem>
    )

    return (
        <Main title="Em Andamento" end={<RefreshIcon />}>
            {rows.map(row => (
                <Atendimento
                    key={row.id}
                    row={row}
                    getList={getList}
                />
            ))}
        </Main>
    )
}

export default TecnicoEmAndamento
