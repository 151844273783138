import { IonLoading } from "@ionic/react";
import React, { createContext, useContext, useCallback, useState } from "react";

interface LoadingContextData {
    loading: boolean;
    showLoading(): void
    hideLoading(): void
    showSaveMessage(): void
    hideSaveMessage(): void
}

const LoadingContext = createContext<LoadingContextData>(
    {} as LoadingContextData
)

interface Props {
    children: React.ReactNode
}

const LoadingProvider = ({ children }: Props) => {
    const [message, setMessage] = useState("Carregando...");
    const [loading, setLoading] = useState(false);

    const showLoading = useCallback(() => {
        setMessage("Carregando...");
        setLoading(true);
    }, [])

    const hideLoading = useCallback(() => {
        setLoading(false);
    }, [])

    const showSaveMessage = useCallback(() => {
        setMessage("Salvando...");
        setLoading(true);
    }, [])

    const hideSaveMessage = useCallback(() => {
        setLoading(false);
    }, [])

    return (
        <LoadingContext.Provider
            value={{
                loading,
                showLoading,
                hideLoading,
                showSaveMessage,
                hideSaveMessage
            }}
        >
            {children}
            <IonLoading
                mode="ios"
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={message}
                duration={5000}
            />
        </LoadingContext.Provider>
    )
}

function useLoading(): LoadingContextData {
    const context = useContext(LoadingContext);

    if (!context) {
        throw new Error("useLoading must be used within a LoadingProvider");
    }

    return context;
}

export { LoadingProvider, useLoading };
