import React, { useState, useEffect } from "react"
import { format, compareAsc } from "date-fns"
import {
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonLabel,
    IonList,
    IonItem,
    IonItemGroup,
    IonItemDivider
} from "@ionic/react"
import { Props as SelectProps } from "react-select"

import api from "../../../services/api"
import { useToast } from "../../../hooks/toast"
import { useValidation } from "../../../hooks/validation"

import Main from "../../../components/Main"
import { IonInput } from "../../../components/Input/styles";
import ReactSelect from "../../../components/ReactSelect/styles";

const sessionName = process.env.REACT_APP_SESSION_NAME

interface Indicators {
    public: {
        patients: number
        total: number
        media: number
        new_patient: number
        death: number
        discharge: number
        discharge_to_program: number
        transfer: number
        continuity: number
        performed: number
        machine_free: number
        without_rh: number
        scheduled: number
        suspended: number
        canceled: number
        hdp: number
        hdi: number
        hdc: number
    }
    private: {
        patients: number
        total: number
        media: number
        new_patient: number
        death: number
        discharge: number
        discharge_to_program: number
        transfer: number
        continuity: number
        performed: number
        machine_free: number
        without_rh: number
        scheduled: number
        suspended: number
        canceled: number
        hdp: number
        hdi: number
        hdc: number
    }
}

const IndicaoresDialise: React.FC = () => {
    const { addToast } = useToast()
    const { getValidationErrors } = useValidation()
    const [hospitals, setHospitals] = useState([])
    const [from, setFrom] = useState<string | null | undefined>(
        localStorage.getItem(`@${sessionName}:from`) || null
    )
    const [to, setTo] = useState<string | null | undefined>(
        localStorage.getItem(`@${sessionName}:to`) || null
    )
    const [hospital, setHospital] = useState<SelectProps>(
        JSON.parse(
            `${localStorage.getItem(`@${sessionName}:hospital`)}`
        ) as SelectProps
    )

    const [values, setValues] = useState<Indicators | null>(null)

    useEffect(() => {
        searchHospitals()
    }, [])

    function searchHospitals(value?: string | null) {
        api.get("administrativo/select/hospital", { params: { search: value } }).then(resp => {
            setHospitals(resp.data)
        })
    }

    const getList = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (from && to && hospital) {
            localStorage.setItem(`@${sessionName}:from`, from)
            localStorage.setItem(`@${sessionName}:to`, to)
            localStorage.setItem(`@${sessionName}:hospital`, JSON.stringify(hospital))

            if (
                compareAsc(new Date(from.substr(0, 10)), new Date(to.substr(0, 10))) <=
                0
            ) {
                await api
                    .get("administrativo/indicadores-dialise", {
                        params: { from, to, hospital: hospital.value }
                    })
                    .then(response => {
                        setValues(response.data)
                    })
                    .catch(error => {
                        getValidationErrors(error.response)
                    })
            } else {
                addToast({
                    message: "A primeira data deve ser maior que a segunda",
                    color: "warning"
                })
            }
        }
    }

    return (
        <Main title="Indicadores Diálise">
            <IonCard>
                <IonCardContent>
                    <form onSubmit={getList}>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12">
                                    <IonLabel position="stacked">De</IonLabel>
                                    <IonInput
                                        value={from}
                                        type="date"
                                        onIonChange={e => setFrom(e.detail.value)}
                                        max={format(new Date(), "yyyy-MM-dd")}
                                    />
                                </IonCol>
                                <IonCol size="12">
                                    <IonLabel position="stacked">Até</IonLabel>
                                    <IonInput
                                        value={to}
                                        type="date"
                                        onIonChange={e => setTo(e.detail.value)}
                                        max={format(new Date(), "yyyy-MM-dd")}
                                    />
                                </IonCol>
                                <IonCol size="12">
                                    <IonLabel position="stacked">Hospital</IonLabel>
                                    <ReactSelect
                                        placeholder=""
                                        classNamePrefix="select"
                                        value={hospital}
                                        onChange={value => !!value && setHospital(value)}
                                        onInputChange={value => searchHospitals(value)}
                                        noOptionsMessage={() => "Nenhuma opção"}
                                        options={hospitals}
                                        styles={{
                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                    />
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="6">
                                    <IonButton type="submit" color="primary" expand="block">
                                        Consultar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </form>
                </IonCardContent>
            </IonCard>
            {!!values && (
                <IonCard>
                    <IonCardContent>
                        <IonList>
                            <IonItemGroup>
                                <IonItemDivider mode="ios">
                                    <IonLabel mode="ios">Quantidade de Pacientes</IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonLabel>Público: {values?.public.patients}</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Particular: {values?.private.patients}</IonLabel>
                                </IonItem>
                            </IonItemGroup>
                            <IonItemGroup>
                                <IonItemDivider mode="ios">
                                    <IonLabel mode="ios">Média de Diálise por Paciente</IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonLabel>Público: {values?.public.media}</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>
                                        Particular:
                                        {values?.private.media}
                                    </IonLabel>
                                </IonItem>
                            </IonItemGroup>
                            <IonItemGroup>
                                <IonItemDivider mode="ios">
                                    <IonLabel>Altas</IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonLabel>Público: {values?.public.discharge}</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>
                                        Particular:
                                        {values?.private.discharge}
                                    </IonLabel>
                                </IonItem>
                            </IonItemGroup>
                            <IonItemGroup>
                                <IonItemDivider mode="ios">
                                    <IonLabel>Alta encaminhado para o programa</IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonLabel>
                                        Público: {values?.public.discharge_to_program}
                                    </IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>
                                        Particular:
                                        {values?.private.discharge_to_program}
                                    </IonLabel>
                                </IonItem>
                            </IonItemGroup>
                            <IonItemGroup>
                                <IonItemDivider mode="ios">
                                    <IonLabel>Óbito</IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonLabel>Público: {values?.public.death}</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>
                                        Particular:
                                        {values?.private.death}
                                    </IonLabel>
                                </IonItem>
                            </IonItemGroup>
                            <IonItemGroup>
                                <IonItemDivider mode="ios">
                                    <IonLabel>Transferência</IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonLabel>Público: {values?.public.transfer}</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>
                                        Particular:
                                        {values?.private.transfer}
                                    </IonLabel>
                                </IonItem>
                            </IonItemGroup>
                            <IonItemGroup>
                                <IonItemDivider mode="ios">
                                    <IonLabel>Paciente novo</IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonLabel>Público: {values?.public.new_patient}</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>
                                        Particular:
                                        {values?.private.new_patient}
                                    </IonLabel>
                                </IonItem>
                            </IonItemGroup>
                            <IonItemGroup>
                                <IonItemDivider mode="ios">
                                    <IonLabel>Paciente continuidade de tratamento</IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonLabel>Público: {values?.public.continuity}</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>
                                        Particular:
                                        {values?.private.continuity}
                                    </IonLabel>
                                </IonItem>
                            </IonItemGroup>
                            <IonItemGroup>
                                <IonItemDivider mode="ios">
                                    <IonLabel>Total procedimentos</IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonLabel>Público: {values?.public.total}</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>
                                        Particular:
                                        {values?.private.total}
                                    </IonLabel>
                                </IonItem>
                            </IonItemGroup>
                            <IonItemGroup>
                                <IonItemDivider mode="ios">
                                    <IonLabel>REALIZADO</IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonLabel>Público: {values?.public.performed}</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>
                                        Particular:
                                        {values?.private.performed}
                                    </IonLabel>
                                </IonItem>
                            </IonItemGroup>
                            <IonItemGroup>
                                <IonItemDivider mode="ios">
                                    <IonLabel>S_MAQUINA</IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonLabel>Público: {values?.public.machine_free}</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>
                                        Particular:
                                        {values?.private.machine_free}
                                    </IonLabel>
                                </IonItem>
                            </IonItemGroup>
                            <IonItemGroup>
                                <IonItemDivider mode="ios">
                                    <IonLabel>SEMRH</IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonLabel>Público: {values?.public.without_rh}</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>
                                        Particular:
                                        {values?.private.without_rh}
                                    </IonLabel>
                                </IonItem>
                            </IonItemGroup>
                            <IonItemGroup>
                                <IonItemDivider mode="ios">
                                    <IonLabel>AGENDADO</IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonLabel>Público: {values?.public.scheduled}</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>
                                        Particular:
                                        {values?.private.scheduled}
                                    </IonLabel>
                                </IonItem>
                            </IonItemGroup>
                            <IonItemGroup>
                                <IonItemDivider mode="ios">
                                    <IonLabel>SUSPENSO</IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonLabel>Público: {values?.public.suspended}</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>
                                        Particular:
                                        {values?.private.suspended}
                                    </IonLabel>
                                </IonItem>
                            </IonItemGroup>
                            <IonItemGroup>
                                <IonItemDivider mode="ios">
                                    <IonLabel>CANCELADO</IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonLabel>Público: {values?.public.canceled}</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>
                                        Particular:
                                        {values?.private.canceled}
                                    </IonLabel>
                                </IonItem>
                            </IonItemGroup>
                            <IonItemGroup>
                                <IonItemDivider mode="ios">
                                    <IonLabel>HDP</IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonLabel>Público: {values?.public.hdp}</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>
                                        Particular:
                                        {values?.private.hdp}
                                    </IonLabel>
                                </IonItem>
                            </IonItemGroup>
                            <IonItemGroup>
                                <IonItemDivider mode="ios">
                                    <IonLabel>HDI</IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonLabel>Público: {values?.public.hdi}</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>
                                        Particular:
                                        {values?.private.hdi}
                                    </IonLabel>
                                </IonItem>
                            </IonItemGroup>
                            <IonItemGroup>
                                <IonItemDivider mode="ios">
                                    <IonLabel mode="ios">HDC</IonLabel>
                                </IonItemDivider>
                                <IonItem>
                                    <IonLabel>Público: {values?.public.hdc}</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Particular: {values?.private.hdc}</IonLabel>
                                </IonItem>
                            </IonItemGroup>
                        </IonList>
                    </IonCardContent>
                </IonCard>
            )}
        </Main>
    )
}

export default IndicaoresDialise;
