import { ToastProvider } from "./toast";
import { AuthProvider } from "./auth";
import { NotificationsProvider } from "./notifications";
import { ValidationErrorsProvider } from "./validation";

import { LoadingProvider } from "./loading";

interface Props {
    children: React.ReactNode
}

const AppProvider = ({ children }: Props) => {
    return (
        <ToastProvider>
            <AuthProvider>
                <NotificationsProvider>
                    <ValidationErrorsProvider>
                        <LoadingProvider>
                            {children}
                        </LoadingProvider>
                    </ValidationErrorsProvider>
                </NotificationsProvider>
            </AuthProvider>
        </ToastProvider >
    )
}

export default AppProvider;
