import { useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCol,
    IonGrid,
    IonItemDivider,
    IonLabel,
    IonRow,
    IonText,
    useIonViewDidEnter,
    useIonViewWillLeave
} from "@ionic/react";
import { format } from "date-fns";

import { useAuth } from "../../../hooks/auth";
import { useToast } from "../../../hooks/toast";
import { useValidation } from "../../../hooks/validation";

import api from "../../../services/api";

import Main from "../../../components/Main";
import Card from "../../../components/Card";
import Input from "../../../components/Input";
import ReactInputMask from "../../../components/ReactInputMask";

import tipoProcedimento from "../../../helpers/tipoProcedimento";

interface ParamTypes {
    assistanceId: string
    patientName: string
    procName: string
}

interface AssistsAnnotation {
    id: number
    assistance_id: number
    time: string
    pa: string
    fc: number
    tax: number
    heparin: number
    arterial_pressure: number
    venous_pressure: number
    serum: number
    blood_flow: number
    dialysate_flow: number
    sf: number
    citrate: number
    calcium: number
    pbe: number
    pv: number
    tf: number
    tmp: number
    partial_balance: number
}

const TecnocoAnotacao = () => {
    const { usuario } = useAuth();
    const formRef = useRef<FormHandles>(null);
    const { successToast } = useToast();
    const { getValidationErrors } = useValidation();
    const { assistanceId, patientName, procName } = useParams<ParamTypes>();
    const history = useHistory();

    const [scroll, setScroll] = useState(new Date());
    const [id, setId] = useState<number>(0);
    const [data, setData] = useState<AssistsAnnotation[]>([]);

    const getList = (assistanceId: string) => {
        api.get(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/anotacao/${assistanceId}`).then(response => {
            setData(response.data);
        })
    }

    useIonViewDidEnter(() => {
        formRef?.current?.reset();
        setData([]);
        timeToNow();
        setId(0);
        setScroll(new Date());
        getList(assistanceId);
    }, [assistanceId])

    useIonViewWillLeave(() => {
        formRef?.current?.reset();
        setData([]);
        setId(0);
    }, [assistanceId])

    const timeToNow = () => {
        let currentTime = format(new Date(), "HH:mm");
        formRef?.current?.setFieldValue("time", currentTime);
    }

    useIonViewDidEnter(() => {
        timeToNow();
    })

    const handleSubmit: SubmitHandler = async data => {
        if (id > 0) {
            update(data);
        } else {
            create(data);
        }
    }

    const create = async (data: AssistsAnnotation) => {
        await api
            .post(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/anotacao/${assistanceId}`, data)
            .then(_response => {
                successToast();
                handleReset();
                getList(assistanceId);
            })
            .catch(error => {
                getValidationErrors(error.response);
            })
    }

    const update = async (data: AssistsAnnotation) => {
        await api
            .put(`${usuario.tipo_perfil === "ENF" ? "enfermagem" : "tecnico"}/anotacao/${assistanceId}/${id}`, data)
            .then(_response => {
                successToast();
                handleReset();
                getList(assistanceId);
            })
            .catch(error => {
                getValidationErrors(error.response);
            })
    }

    const handleReset = () => {
        formRef?.current?.reset();
        timeToNow();
        setId(0);
        setScroll(new Date());
    }

    const setFormValues = (values: AssistsAnnotation) => {
        if (!!values.id) {
            setId(values.id);
            formRef?.current?.setData({ ...values });
            setScroll(new Date());
        }
    }

    return (
        <Main title="Anotações" scroll={scroll} back>
            <Card>
                <IonCardHeader>
                    <IonRow>
                        <IonCol size="6">
                            <IonButton
                                type="button"
                                color="success"
                                expand="block"
                                onClick={() => handleReset()}
                            >
                                Novo
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonCardHeader>
                <IonCardContent>
                    <IonGrid>
                        <Form ref={formRef} onSubmit={handleSubmit}>
                            <IonRow className="ion-align-items-end">
                                <Input size="6" name="time" type="time" label="Hora" />
                                {tipoProcedimento(procName) === "C" && (
                                    <Input
                                        size="6"
                                        name="partial_balance"
                                        label="Balanço Parcial"
                                        type="number"
                                        inputMode="decimal"
                                        max="999999.99"
                                        step="0.01"
                                    />
                                )}
                            </IonRow>
                            {tipoProcedimento(procName) === "H" &&
                                <IonRow className="ion-align-items-end">
                                    <ReactInputMask
                                        size="6"
                                        name="pa"
                                        label="PA(mmHg)"
                                        mask="999X999"
                                    />
                                    <Input
                                        size="6"
                                        name="fc"
                                        label="FC(bpm)"
                                        type="number"
                                        inputMode="decimal"
                                        max="999999.99"
                                        step="0.01"
                                    />
                                    <Input
                                        size="6"
                                        name="tax"
                                        label="Tax(ºC)"
                                        type="number"
                                        inputMode="decimal"
                                        max="999999.99"
                                        step="0.01"
                                    />
                                    <Input
                                        size="6"
                                        name="heparin"
                                        label="Solução de Heparina"
                                        type="text"
                                        inputMode="text"
                                    />
                                    <Input
                                        size="6"
                                        name="arterial_pressure"
                                        label="P.Art(mmHg)"
                                        type="number"
                                        inputMode="decimal"
                                        max="999999.99"
                                        step="0.01"
                                    />
                                    <Input
                                        size="6"
                                        name="venous_pressure"
                                        label="P.Ven(mmHg)"
                                        type="number"
                                        inputMode="decimal"
                                        max="999999.99"
                                        step="0.01"
                                    />
                                    <Input
                                        size="6"
                                        name="serum"
                                        label="Soro(ml)"
                                        type="number"
                                        inputMode="decimal"
                                        max="999999.99"
                                        step="0.01"
                                    />
                                    <Input
                                        size="6"
                                        name="blood_flow"
                                        label="Fluxo Sanguíneo(ml/min)"
                                        type="number"
                                        inputMode="decimal"
                                        max="999999.99"
                                        step="0.01"
                                    />
                                    <Input
                                        size="6"
                                        name="dialysate_flow"
                                        label="Fluxo Dialisato(ml/min)"
                                        type="number"
                                        inputMode="decimal"
                                        max="999999.99"
                                        step="0.01"
                                    />
                                </IonRow>
                            }

                            {tipoProcedimento(procName) === "C" &&
                                <IonRow className="ion-align-items-end">
                                    <IonCol size="12">
                                        <IonItemDivider color="light">
                                            <IonLabel
                                                color="dark"
                                                style={{
                                                    whiteSpace: "pre-line",
                                                    paddingRight: 5
                                                }}
                                            >
                                                Anticoagulação
                                            </IonLabel>
                                        </IonItemDivider>
                                    </IonCol>
                                    <Input
                                        size="6"
                                        name="sf"
                                        label="SF 0,9%"
                                        type="number"
                                        inputMode="decimal"
                                        max="999999.99"
                                        step="0.01"
                                    />
                                    <Input
                                        size="6"
                                        name="heparin"
                                        label="Heparina"
                                        type="text"
                                        inputMode="text"
                                    />
                                    <Input
                                        size="6"
                                        name="citrate"
                                        label="Citrato"
                                        type="number"
                                        inputMode="decimal"
                                        max="999999.99"
                                        step="0.01"
                                    />
                                    <Input
                                        size="6"
                                        name="calcium"
                                        label="Cálcio"
                                        type="number"
                                        inputMode="decimal"
                                        max="999999.99"
                                        step="0.01"
                                    />
                                    <IonCol size="12">
                                        <IonItemDivider color="light">
                                            <IonLabel
                                                color="dark"
                                                style={{
                                                    whiteSpace: "pre-line",
                                                    paddingRight: 5
                                                }}
                                            >
                                                Pressões
                                            </IonLabel>
                                        </IonItemDivider>
                                    </IonCol>
                                    <ReactInputMask
                                        size="6"
                                        name="pa"
                                        label="PA(mmHg)"
                                        mask="999X999"
                                    // maskPlaceholder="-"
                                    />
                                    <Input
                                        size="6"
                                        name="pbe"
                                        label="PBE"
                                        type="number"
                                        inputMode="decimal"
                                        max="999999.99"
                                        step="0.01"
                                    />
                                    <Input
                                        size="6"
                                        name="pv"
                                        label="PV"
                                        type="number"
                                        inputMode="decimal"
                                        max="999999.99"
                                        step="0.01"
                                    />
                                    <Input
                                        size="6"
                                        name="tf"
                                        label="TF"
                                        type="number"
                                        inputMode="decimal"
                                        max="999999.99"
                                        step="0.01"
                                    />
                                    <Input
                                        size="6"
                                        name="tmp"
                                        label="TMP"
                                        type="number"
                                        inputMode="decimal"
                                        max="999999.99"
                                        step="0.01"
                                    />
                                </IonRow>
                            }

                            {(tipoProcedimento(procName) === "DPA" || tipoProcedimento(procName) === "CAPD") &&
                                <IonRow className="ion-align-items-end">
                                    <ReactInputMask
                                        size="6"
                                        name="pa"
                                        label="PA(mmHg)"
                                        mask="999X999"
                                    />
                                    <Input
                                        size="6"
                                        name="fc"
                                        label="FC(bpm)"
                                        type="number"
                                        inputMode="decimal"
                                        max="999999.99"
                                        step="0.01"
                                    />
                                </IonRow>
                            }

                            <IonRow className="ion-margin-top">
                                <IonCol size="12">
                                    <IonLabel>Paciente: </IonLabel>
                                    <IonText>{patientName}</IonText>
                                </IonCol>
                                <IonCol size="6" className="ion-margin-top">
                                    <IonButton type="submit" color="primary" expand="block">
                                        SALVAR
                                    </IonButton>
                                </IonCol>
                                <IonCol size="6" className="ion-margin-top">
                                    <IonButton
                                        type="button"
                                        onClick={() => history.goBack()}
                                        color="medium"
                                        expand="block"
                                    >
                                        Voltar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </Form>
                    </IonGrid>
                </IonCardContent>
            </Card>
            {data.map(values => (
                <IonCard key={values.id}>
                    <IonCardContent>
                        <IonRow className="ion-align-items-end">
                            <IonCol size="6">
                                <IonLabel>Hora: </IonLabel>
                                <br />
                                <IonText>{values.time?.substr(0, 5)}</IonText>
                            </IonCol>
                            {tipoProcedimento(procName) === "C" && (
                                <IonCol size="6">
                                    <IonLabel>Balanço Parcial: </IonLabel>
                                    <br />
                                    <IonText>{values.partial_balance}</IonText>
                                </IonCol>
                            )}
                        </IonRow>
                        {tipoProcedimento(procName) === "H" &&
                            <IonRow className="ion-align-items-end">
                                <IonCol size="6">
                                    <IonLabel>PA(mmHg): </IonLabel>
                                    <br />
                                    <IonText>{!!values.pa && values.pa.replace("_", "")}</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonLabel>FC(bpm): </IonLabel>
                                    <br />
                                    <IonText>{values.fc}</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonLabel>Tax(ºC): </IonLabel>
                                    <br />
                                    <IonText>{values.tax}</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonLabel>Solução de Heparina: </IonLabel>
                                    <br />
                                    <IonText>{values.heparin}</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonLabel>P.Art(mmHg): </IonLabel>
                                    <br />
                                    <IonText>{values.arterial_pressure}</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonLabel>P.Ven(mmHg): </IonLabel>
                                    <br />
                                    <IonText>{values.venous_pressure}</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonLabel>Soro(ml): </IonLabel>
                                    <br />
                                    <IonText>{values.serum}</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonLabel>Fluxo Sanguíneo(ml/min): </IonLabel>
                                    <br />
                                    <IonText>{values.blood_flow}</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonLabel>Fluxo Dialisato(ml/min): </IonLabel>
                                    <br />
                                    <IonText>{values.dialysate_flow}</IonText>
                                </IonCol>
                            </IonRow>
                        }

                        {tipoProcedimento(procName) === "C" &&
                            <IonRow className="ion-align-items-end">
                                <IonCol size="12">
                                    <IonItemDivider color="light">
                                        <IonLabel
                                            color="dark"
                                            style={{
                                                whiteSpace: "pre-line",
                                                paddingRight: 5
                                            }}
                                        >
                                            Anticoagulação
                                        </IonLabel>
                                    </IonItemDivider>
                                </IonCol>
                                <IonCol size="6">
                                    <IonLabel>SF 0,9%: </IonLabel>
                                    <br />
                                    <IonText>{values.sf}</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonLabel>Heparina: </IonLabel>
                                    <br />
                                    <IonText>{values.heparin}</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonLabel>Citrato: </IonLabel>
                                    <br />
                                    <IonText>{values.citrate}</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonLabel>Cálcio: </IonLabel>
                                    <br />
                                    <IonText>{values.calcium}</IonText>
                                </IonCol>
                                <IonCol size="12">
                                    <IonItemDivider color="light">
                                        <IonLabel
                                            color="dark"
                                            style={{
                                                whiteSpace: "pre-line",
                                                paddingRight: 5
                                            }}
                                        >
                                            Pressões
                                        </IonLabel>
                                    </IonItemDivider>
                                </IonCol>
                                <IonCol size="6">
                                    <IonLabel>PA(mmHg): </IonLabel>
                                    <br />
                                    <IonText>{!!values.pa && values.pa.replace("_", "")}</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonLabel>PBE: </IonLabel>
                                    <br />
                                    <IonText>{values.pbe}</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonLabel>PV: </IonLabel>
                                    <br />
                                    <IonText>{values.pv}</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonLabel>TF: </IonLabel>
                                    <br />
                                    <IonText>{values.tf}</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonLabel>TMP: </IonLabel>
                                    <br />
                                    <IonText>{values.tmp}</IonText>
                                </IonCol>
                            </IonRow>
                        }

                        {(tipoProcedimento(procName) === "DPA" || tipoProcedimento(procName) === "CAPD") &&
                            <IonRow className="ion-align-items-end">
                                <IonCol size="6">
                                    <IonLabel>PA(mmHg): </IonLabel>
                                    <br />
                                    <IonText>{!!values.pa && values.pa.replace("_", "")}</IonText>
                                </IonCol>
                                <IonCol size="6">
                                    <IonLabel>FC(bpm): </IonLabel>
                                    <br />
                                    <IonText>{values.fc}</IonText>
                                </IonCol>
                            </IonRow>
                        }

                        <IonRow className="ion-margin-top">
                            <IonCol size="6">
                                <IonButton
                                    type="button"
                                    color="primary"
                                    expand="block"
                                    onClick={() => setFormValues(values)}
                                >
                                    Editar
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                </IonCard>
            ))}
        </Main>
    )
}

export default TecnocoAnotacao;
