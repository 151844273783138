const tipoProcedimento = (proc: string) => {
    if ([
        "DPA",
        "DPAC",
        "DPAP (PEDIATRIA)",
        "DIÁLISE PERITONIAL DOMICILIAR"
    ].includes(proc)) {
        return "DPA"
    } else if ([
        "CVVHD",
        "CVVHDF"
    ].includes(proc)) {
        return "C"
    } else if ([
        "CAPD"
    ].includes(proc)) {
        return "CAPD"
    } else {
        return "H"
    }
}

export default tipoProcedimento;