import { useRef, useState } from "react";
import { IonButton, IonCardContent, IonCol, IonGrid, IonIcon, IonImg, IonItem, IonRow, isPlatform, useIonViewDidEnter } from "@ionic/react";
import { refresh } from "ionicons/icons";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import api from "../../services/api";

import { useAuth } from "../../hooks/auth";
import { useLoading } from "../../hooks/loading";
import { useToast } from "../../hooks/toast";
import { useValidation } from "../../hooks/validation";

import Main from "../../components/Main";
import Card from "../../components/Card";
import Input from "../../components/Input";
import ReactInputMask from "../../components/ReactInputMask";
import Label from "../../components/Label";

interface FormValues {
    nome_completo: string
    cpf: string
    cnpj: string
    razao_social: string
    crm: string
}

const Usuario = () => {
    const { usuario, atualizarAssinatura } = useAuth();
    const { showLoading, hideLoading } = useLoading();
    const { successToast } = useToast();
    const { getValidationErrors } = useValidation();

    const formRef = useRef<FormHandles>(null);
    const [carregado, setCarregado] = useState(false);
    const [assinatura, setAssinatura] = useState("");

    const platform = isPlatform("mobileweb") || isPlatform("desktop");

    const getUsuario = async () => {
        showLoading();
        await api.get("/usuario").then(response => {
            if (!!response.data) {
                const { assinatura } = response.data;
                formRef.current?.setData(response.data);
                setAssinatura(assinatura);
                setCarregado(true);
            }
        })
            .catch(error => {
                getValidationErrors(error.response);
            })
        hideLoading();
    };

    useIonViewDidEnter(async () => {
        getUsuario();
    });

    const handleSubmit: SubmitHandler = async (values: FormValues) => {
        await api
            .put("usuario", values)
            .then(response => {
                if (!!response.data) {
                    successToast();
                    formRef?.current?.reset();
                    getUsuario();
                }
            })
            .catch(error => {
                getValidationErrors(error.response);
            })
    }

    const RefreshIcon = () => (
        <IonItem lines="none">
            <IonIcon
                icon={refresh}
                size="large"
                color="light"
                onClick={() => getUsuario()}
            />
        </IonItem>
    )

    return (
        <Main title="Dados do Médico" end={<RefreshIcon />}>
            <Card>
                <IonCardContent>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <IonGrid>
                            <IonRow className="ion-align-items-end">
                                <Input
                                    label="Nome"
                                    name="nome_completo"
                                    type="text"
                                    autoCapitalize="on"
                                    autoComplete="off"
                                    required
                                    disabled={!carregado}
                                />
                                <ReactInputMask
                                    name="cpf"
                                    mask="999.999.999-99"
                                    label="CPF"
                                    disabled={!carregado}
                                />
                                {usuario.tipo_perfil === "MED" && (
                                    <>
                                        <ReactInputMask
                                            name="cnpj"
                                            mask="99.999.999/9999-99"
                                            label="CNPJ"
                                            disabled={!carregado}
                                        />
                                        <Input
                                            label="Empresa"
                                            name="razao_social"
                                            type="text"
                                            autoCapitalize="on"
                                            autoComplete="off"
                                            disabled={!carregado}
                                        />
                                        <Input
                                            label="CRM"
                                            name="crm"
                                            type="text"
                                            autoCapitalize="on"
                                            autoComplete="off"
                                            disabled={!carregado}
                                        />
                                    </>
                                )}
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol size="6">
                                    <IonButton type="submit" color="primary" expand="block">
                                        Salvar
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </Form>
                </IonCardContent>
            </Card>
            <Card>
                <IonCardContent>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <Label>Assinatura</Label>
                                {assinatura !== "" && (
                                    <IonImg
                                        src={assinatura}
                                        alt="Assinatura"
                                        style={{ width: 'auto', height: '100px' }}
                                    />
                                )}
                            </IonCol>
                            {!platform && (
                                <IonCol size="12">
                                    <IonButton
                                        type="button"
                                        color="primary"
                                        expand="block"
                                        onClick={() => atualizarAssinatura("N")}
                                    >
                                        Alterar Assinatura
                                    </IonButton>
                                </IonCol>
                            )}
                        </IonRow>
                    </IonGrid>
                </IonCardContent>
            </Card>
        </Main >
    )
}

export default Usuario;