import React, { useEffect, useRef, useState } from 'react'
import { useField } from '@unform/core'
import { IonCol, IonLabel } from '@ionic/react'
import ReactSelect from 'react-select'

import api from '../../services/api';

interface Props {
  name: string
  label?: string
  size?: string
  readOnly?: boolean
  isClearable?: boolean
  onChange?: (value: string) => void
}

const SearchMachines: React.FC<Props> = ({
  name,
  label,
  size,
  readOnly,
  isClearable,
  onChange,
  ...rest
}) => {
  const inputRef = useRef(null)
  const { fieldName, defaultValue, registerField } = useField(name)
  const [machines, setMachines] = useState([])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: ref => {
        const values = ref.getValue();
        if (!!values) {
          return values[0].value;
        }
      },
      setValue: (ref, value) => {
        return (ref.state.value = value)
      },
      clearValue: ref => {
        ref.state.value = ''
      }
    })
  }, [fieldName, name, registerField])

  const searchMachines = (value?: string) => {
    api.get('select/maquina', { params: { search: value } }).then(resp => {
      setMachines(resp.data)
    })
  }

  useEffect(() => {
    searchMachines()
  }, [])

  return (
    <IonCol size={size || '12'}>
      <IonLabel position="stacked">{label}</IonLabel>
      <ReactSelect
        ref={inputRef}
        placeholder=""
        classNamePrefix="select"
        defaultValue={defaultValue}
        onInputChange={value => searchMachines(value)}
        onChange={(e: any) => !!onChange && onChange(e.value)}
        noOptionsMessage={() => 'Nenhuma opção'}
        isDisabled={readOnly}
        isClearable={isClearable}
        options={machines}
        styles={{
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
        components={{
          IndicatorSeparator: () => null
        }}
        {...rest}
      />
    </IonCol>
  )
}

export default SearchMachines
